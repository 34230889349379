@media only screen and (max-width: 991px) {
  .min-played-logo-mobile {
    img {
      // width: 100% !important;
      padding-top: 12px !important;
    }
  }
  .season-stat-box-content {
    .stat-icon {
      height: 70px;
      margin-bottom: 20px;
      img {
        height: 50px;
      }
    }
  }
}

.current-season-container-all {
  .season-stats-title {
    padding-bottom: 30px;
    font-size: 20px;
    font-weight: 700;
  }
}
.current-season-container {
  .season-stat-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 10px;

    .empty-box-bg {
      background-color: white !important;
    }

    .season-stat-box {
      height: 250px;
      width: 33%;
      align-items: center;
      font-size: 15px;
      background-color: #fafafa;
      position: relative;

      .season-stat-box-content {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        .yellow-card-color {
          color: #eedb00 !important;
        }

        .red-card-color {
          color: #d91200 !important;
        }

        .stat-icon {
          height: 70px;
          margin-bottom: 20px;
        }
        .stat-name {
          font-weight: 700;
          color: #b2b2b2;
          font-size: 15px;
        }

        .stat-number {
          font-weight: 700;
          color: var(--primaryColor);
          font-size: 30px;
        }
      }
    }
  }
}
