.competition-page-ranking-container {
  padding: 45px;

  .competition-page-ranking-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    .competition-page-ranking-title {
      font-size: 20px;
      font-weight: 700;
    }

    .add-team-button {
      background-color: var(--primaryColor) !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 12px !important;
      color: white !important;
      display: flex;
      align-items: center;
    }
    .white-icon {
      color: white;
      padding-left: 20px;
      opacity: 1 !important;
    }
  }
}

.competition-page-matches-container {
  padding: 45px;
  .competition-page-matches-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    .competition-page-matches-title {
      font-size: 20px;
      font-weight: 700;
    }

    .add-match-button {
      background-color: var(--primaryColor) !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 12px !important;
      color: white !important;
      display: flex;
      align-items: center;
    }
    .white-icon {
      color: white;
      padding-left: 20px;
      opacity: 1 !important;
    }
  }
}
.competition-ranking-list-container {
  .ranking-row-table-header {
    display: flex;
    align-items: center;
    padding-bottom: 13px;
    font-size: 13px;
    color: #b2b2b2;
    font-weight: 700;
  }
  .ranking-row {
    height: 55px;
    display: flex;
    align-items: center;
    color: #666666;
    font-size: 14px;
    font-weight: 700;
  }

  .ranking-row-outlined {
    background-color: #00d948 !important;
    color: white !important;
  }
  .ranking-position {
    width: 10%;
    text-align: center;
  }
  .ranking-team {
    width: 32%;
    padding-left: 10px;
  }
  .ranking-stat {
    width: 10%;
    text-align: center;
  }
  .ranking-points {
    width: 10%;
    text-align: center;
  }

  .ranking-options {
    width: 8%;
    text-align: center;
  }
}
.group-container {
  margin-bottom: 40px;
  .group-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 13px;
  }
  .competition-ranking-list-container {
    .ranking-row-table-header {
      display: flex;
      align-items: center;
      padding-bottom: 13px;
      font-size: 13px;
      color: #b2b2b2;
      font-weight: 700;
    }
    .ranking-row {
      height: 55px;
      display: flex;
      align-items: center;
      color: #666666;
      font-size: 14px;
      font-weight: 700;
    }

    .ranking-row-outlined {
      background-color: #00d948 !important;
      color: white !important;
    }
    .ranking-position {
      width: 10%;
      text-align: center;
    }
    .ranking-team {
      width: 32%;
      padding-left: 10px;
    }
    .ranking-stat {
      width: 10%;
      text-align: center;
    }
    .ranking-points {
      width: 10%;
      text-align: center;
    }

    .ranking-options {
      width: 8%;
      text-align: center;
    }
  }
}
//==========================================================

.add-team-manual-wrapper {
  // width: 400px;
  width: 100%;
}

.competition-matches-container {
  .round-container {
    margin-bottom: 45px;
    .round-title {
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      margin-bottom: 35px;
    }

    .round-matches-list {
      .round-match-row {
        height: 50px;
        display: flex;
        align-items: center;
        font-weight: 700;
        color: #666666;
        font-size: 14px;
        margin-bottom: 5px;

        .match-date {
          width: 20%;
          padding-left: 25px;
          color: #b2b2b2;
        }

        .match-options {
          width: 20%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 25px;

          img {
            width: 12px;
            cursor: pointer;
          }
          img[alt="edit"] {
            margin-right: 20px;
          }

          .live-match-container {
            margin-left: 20px;

            .live-match-icon {
              width: 15px;
              height: 15px;
              border-radius: 15px;
              transition: 0.2s all;
              cursor: pointer;
            }

            .live-match-icon:focus {
              transform: scale(0.8);
              /* Scaling button to 0.98 to its original size */
              box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
              /* Lowering the shadow */
            }
          }
        }

        .team-home {
          width: 20%;
          text-align: end;
        }
        .score {
          width: 20%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
        }
        .team-away {
          width: 20%;
        }
      }
    }
  }
}

.edit-score-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .edit-score-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 25px;
  }

  .edit-score-content {
    display: flex;
    height: 50px;
    font-weight: 700;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;

    input {
      width: 50px;
      height: 50px;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      background-color: #fafafa;
      color: #b2b2b2;
    }

    .vs {
      font-size: 16px;
    }

    .team-name {
      width: 25%;
      text-align: center;
    }
  }

  .edit-score-buttons {
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-top: 25px;

    .save-score-button {
      background-color: #00d948 !important;
      height: 50px;
      width: 47%;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: white !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
    }

    .cancel-button {
      background-color: #666666 !important;
      height: 50px;
      width: 47%;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: white !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
    }

    .white-icon {
      color: white;
      padding-left: 20px;
      opacity: 1 !important;
    }
  }
}

.edit-score-wrapper {
  min-width: 500px;
}

.edit-team-popup-container {
  // width: 25vw;
  .edit-team-popup-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 25px;
  }

  .edit-team-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    height: 50px;
  }
}

.edit-team-button {
  height: 50px;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  color: white !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 47%;
}

.delete-round-button {
  height: 50px;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  color: #666666 !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px !important;
  padding-left: 25px !important;
  background-color: #fafafa !important;
}

.edit-team-input {
  width: 100%;
  height: 50px;
  border: 0;
  font-size: 14px;
  font-weight: 700;
  padding-left: 25px;
  background-color: #fafafa;
  color: #666666;
  margin-bottom: 25px;
}

.edit-round-name-popup {
  width: 20vw;

  .edit-round-name-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 25px;
  }

  .edit-round-buttons {
    display: flex;
    justify-content: space-between;
    height: 50px;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 660px) {
  .competition-page-matches-container,
  .competition-page-ranking-container {
    padding: 20px;
    .competition-page-matches-header,
    .competition-page-ranking-header {
      margin-bottom: 20px;
      .add-match-button,
      .add-team-button {
        height: 35px;
      }
    }
    .competition-matches-container {
      .round-container {
        margin-bottom: 10px;
        .round-title {
          margin-bottom: 20px;
        }
        .round-matches-list {
          .round-match-row {
            height: 65px;
            font-size: 12px;
            .match-date {
              padding-left: 5px;
            }
            .score {
              font-size: 18px;
              width: 15%;
            }
            .match-options {
              padding-right: 5px;
              img[alt="edit"] {
                margin-right: 15px;
              }
            }
            .team-home,
            .team-away {
              width: 22.5%;
            }
          }
        }
      }
    }
  }
  .container-fluid {
    .row {
      .col-lg-6 {
        // margin-bottom: 30px !important;
      }
    }
  }
  .competition-page-ranking-container {
    .group-container {
      margin-bottom: 20px;
      .group-title {
        font-size: 16px;
      }
      .competition-ranking-list-container {
        .ranking-row-table-header {
          font-size: 11px;
          padding-bottom: 10px;
        }
        .ranking-row {
          height: 50px;
          font-size: 12px;
        }
      }
    }
  }
  .edit-team-popup-container {
    width: 100%;
  }
  .MuiDialog-paper {
    margin: 20px !important;
  }
  .MuiDialogContent-root {
    // padding: 15px 10px !important;
  }
  .edit-score-container {
    .edit-score-title {
      margin-bottom: 15px;
    }

    .edit-score-content {
      font-size: 12px;

      input {
        width: 50px;
        height: 50px;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        background-color: #fafafa;
        color: #b2b2b2;
      }

      .vs {
        font-size: 16px;
      }

      .team-name {
        width: 25%;
        text-align: center;
      }
    }

    .edit-score-buttons {
      height: 35px;
      margin-bottom: 0px;
      margin-top: 15px;

      .save-score-button {
        height: 35px;
      }

      .cancel-button {
        height: 35px;
      }

      .white-icon {
        padding-left: 10px;
      }
    }
  }
  .edit-score-wrapper {
    min-width: 300px;
  }
  .edit-round-name-popup {
    width: 100%;
  }
  .add-team-manual-wrapper {
    width: 100%;
  }
}

.competition-teams-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
  .team-container {
    padding: 20px;
    width: 180px;
    background-color: #fafafa;
    .logo-container {
      display: flex;
      justify-content: center;
      img {
        height: 100px;
        width: auto;
      }
    }
    .team-name {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      margin: 8px 0;
      text-decoration: none;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .modify-buttons {
      display: flex;
      justify-content: center;
      gap: 20px;

      .button {
        cursor: pointer;
      }
      .btn-delete:hover {
        color: red;
      }
      .btn-edit:hover {
        color: var(--primaryColor);
      }
    }
  }
}

.delete-popup-message {
  font-weight: 600;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  // text-align: center;
  margin-bottom: 25px;
}
