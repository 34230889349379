.delete-request-popup-container {
  .delete-request-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .delete-request-title {
      font-size: 20px;
      font-weight: 700;
    }

    .close {
      cursor: pointer;
    }
  }

  .delete-request-text {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  .delete-request-buttons {
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 20px;
    gap: 20px;
    .cancel-button {
      color: #fafafa;
      height: 50px;
      font-family: "Poppins", sans-serif;
      font-size: 13px;
      background-color: #666666;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 47%;
    }
    .delete-comp-button {
      background-color: #db2828;
      height: 50px;
      font-family: "Poppins", sans-serif;
      font-size: 13px;
      color: #fafafa;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 47%;
    }
  }
}
