.goals-statistic-filter-header {
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 10px;

  .ui.dropdown > .dropdown.icon:before {
    position: absolute !important;
    right: 10px !important;
  }

  .goal-stats-date-pickers {
    display: flex;
    align-items: center;
    width: 55%;

    .select-date-wrapper {
      width: 100%;
      position: relative;

      .calendar-icon {
        position: absolute;
        right: 10px;
      }
    }

    .goals-stats-date-input {
      width: 100%;
    }
  }

  .show-summary {
    width: 15% !important;
    min-width: 166px;
  }

  .dropdown {
    width: 15% !important;
  }
}

.stats-row-charts {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;

  .charts-row {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .apexcharts-legend {
      justify-content: center !important;
      .apexcharts-legend-series {
        display: flex;
        align-items: center;
      }
    }
  }
}

@media only screen and (max-width: 1123px) {
  .goals-statistic-filter-header {
    display: flex;
    flex-direction: column;

    .goal-stats-date-pickers {
      display: flex;
      align-items: center;
      width: 100%;

      .goals-stats-date-input {
        width: 100%;
      }
    }

    .show-summary {
      width: 100% !important;
      min-width: 166px;
    }

    .dropdown {
      width: 100% !important;
    }
  }

  .stats-row-charts {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;

    .charts-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      foreignObject {
        overflow: visible !important;
      }

      .apexcharts-legend {
        justify-content: center !important;
        bottom: 10px !important;

        .apexcharts-legend-series {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 675px) {
  .goals-statistic-filter-header {
    display: flex;
    flex-direction: column;

    .goal-stats-date-pickers {
      display: flex;
      align-items: center;
      width: 100%;

      .goals-stats-date-input {
        width: 100%;
      }
    }

    .show-summary {
      width: 100% !important;
      min-width: 166px;
    }

    .dropdown {
      width: 100% !important;
    }
  }

  .stats-row-charts {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;

    .charts-row {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      gap: 50px;

      foreignObject {
        overflow: visible;
      }

      svg:not(:root) {
        overflow: visible !important;
      }

      .apexcharts-legend {
        justify-content: center !important;
        bottom: 10px !important;
        inset: auto -67px 10px !important;
        overflow: visible !important;

        .apexcharts-legend-series {
          display: flex;
          align-items: center;
          // width: 70%;
          overflow: visible !important;
        }
      }
    }
  }
}

.invalid-data-stats-call-up-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  .invalid-data-stats-up-message-text {
    color: red;
    text-align: center;
  }
}
