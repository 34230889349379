.selectLabel {
    color: #b2b2b2;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    text-wrap: nowrap;
}

.colorContainer {
    height: 53.43px;
    width: inherit;
    background-color: white;
    padding: 8px;
    border-radius: 3px;
    border: 2px solid #f2f2f2;
    cursor: pointer;

    .colorDiv {
        width: inherit;
        height: 34px;
        border-radius: 3px;
    }
}
