.delete-user-mobile-button {
  background-color: #db2828 !important;
  color: #fafafa !important;
  height: 50px;
  font-family: Poppins, sans-serif !important;
  font-size: 13px !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 47%;
}

.activate-user-mobile-button {
  background-color: var(--primaryColor) !important;
  color: #fafafa !important;
  height: 50px;
  font-family: Poppins, sans-serif !important;
  font-size: 13px !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 47%;
}

.desactivate-user-mobile-button {
  background-color: #db2828 !important;
  color: #fafafa !important;
  height: 50px;
  font-family: Poppins, sans-serif !important;
  font-size: 13px !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 47%;
}

.cancel-user-mobile-button {
  background-color: #666 !important;
  color: #fafafa !important;
  height: 50px;
  font-family: Poppins, sans-serif !important;
  font-size: 13px !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 47%;
}

.add-user-phone-number {
  width: 49%;
  .PhoneInputInput {
    background-color: #fafafa;
    border: 0;
    font-weight: 700;
    border-radius: 3px;
    height: 50px !important;
  }
}

.shortlists-page-container {
  .shortlists-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    margin-bottom: 45px;

    .shortlists-title {
      font-weight: 700;
      color: black;
      font-size: 20px;
    }

    .create-shortlist-button {
      height: 100%;
      background-color: var(--primaryColor) !important;
      color: white !important;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: 700;
      font-family: "Poppins", sans-serif !important;

      .shortlist-button-icon {
        color: white !important;
        opacity: 1 !important;
        margin-left: 20px !important;
      }
    }
  }

  .shortlists-list-header {
    display: flex;
    align-items: center;
    color: #b2b2b2;
    font-size: 13px;
    font-weight: 700;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .user-name-relationship {
    width: 20%;
    display: flex;
    flex-direction: column;

    .user-relationship {
      color: #b2b2b2;
    }
  }

  .user-name-header {
    width: 20%;
  }

  .user-col {
    width: 25%;
    text-align: center;
  }

  .user-date {
    display: flex;
    justify-content: center;
    width: 20%;
    text-align: center;
  }

  .user-list-mobile-buttons {
    width: 10%;
    display: flex;
    gap: 5px;
    justify-content: flex-end;

    .delete-user-button {
      background-color: #666666;
      width: 35px;
      height: 35px;
      padding: 7px;
      border-radius: 5px;
      cursor: pointer;
      // position: absolute;
      right: 10px;

      .trash-hover:hover {
        background-color: #d91200;
        cursor: pointer;
      }
    }

    .delete-user-button:hover {
      background-color: #d91200;
      cursor: pointer;
    }

    .edit-user-button {
      background-color: #666666;
      width: 35px;
      height: 35px;
      padding: 7px;
      border-radius: 5px;
      cursor: pointer;
      // position: absolute;
      right: 10px;
    }

    .edit-user-activate-button {
      background-color: #666666;
      width: 35px;
      height: 35px;
      padding: 7px;
      border-radius: 5px;
      cursor: pointer;
      position: relative;

      i {
        position: relative;
        left: 3px;
      }
    }
  }

  .shortlist-row {
    height: 55px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: #666666;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
  }
}

.options-popup-container {
  display: flex;
  padding: 50px;

  .formation-column {
    margin-right: 35px;

    .formation-title {
      font-size: 20px;
      font-weight: 700;
      color: black;
      margin-bottom: 35px;
    }
    .formations-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .formation-box {
        width: 100px;
        height: 105px;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 700;
        color: #666666;
        background-color: #fafafa;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 15px;
        cursor: pointer;
      }

      .formation-box-selected {
        width: 100px;
        height: 105px;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 700;
        color: white;
        background-color: #666666;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 15px;
        cursor: pointer;
      }

      .formation-preview {
        height: 35px;
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }
  }

  .pitch-column {
    margin-right: 50px;

    .pitch-title {
      font-size: 20px;
      font-weight: 700;
      color: black;
      margin-bottom: 35px;
    }

    .pitch-container-img {
      width: 440px;
      height: 690px;
      background-image: url("../../../assets/pitch.png");
      background-repeat: no-repeat;
      background-size: inherit;
    }

    .formation-row {
      height: 20%;
      display: flex;

      .row-box {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .shirt-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .shirt-img {
      cursor: pointer;
      z-index: 3333;
    }

    .selection-round {
      position: absolute;
      bottom: -15px;
    }
  }

  .filters-column {
    width: 440px;
    .filters-title {
      font-size: 20px;
      font-weight: 700;
      color: black;
      margin-bottom: 35px;
    }

    .filters-row {
      display: flex;
      margin-bottom: 20px;
      justify-content: space-between;

      .filter-group {
        width: 47%;

        .input-range > div > span > div {
          background-color: #666666 !important;
          border-radius: 3px !important;
          height: 15px;
          width: 15px;
        }

        .input-range > div > div {
          background-color: #666666 !important;
        }
      }
    }

    .filter-label {
      font-size: 13px;
      color: #b2b2b2;
      font-weight: 700;
      padding-bottom: 10px;
    }

    .player-profile-row {
      margin-bottom: 25px;
    }

    .sh-filter-dropdown {
      background-color: #666666 !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      font-family: "Poppins", sans-serif !important;
      font-weight: 700;
      height: 50px;
      width: 100% !important;
      padding-left: 20px;
      border-radius: 3px;

      .divider.default.text {
        color: white !important;
      }

      i {
        top: 5% !important;
        right: 20px;
        color: white;
      }

      .divider.text {
        color: white !important;
      }

      .menu {
        width: 100% !important;
      }
    }

    .generate-sh-button {
      height: 50px;
      width: 100%;
      background-color: var(--primaryColor) !important;
      color: white !important;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: 700;
      font-family: "Poppins", sans-serif !important;
      margin-top: 50px;

      .shortlist-button-icon {
        color: white !important;
        opacity: 1 !important;
        margin-left: 20px !important;
      }
    }
  }
}

.filter-slider-container {
  align-items: center;
  margin-bottom: 25px;

  .filter-slider-label {
    font-size: 13px;
    color: #b2b2b2;
    font-weight: 700;
    margin-bottom: 10px;
  }
}

.save-shortlist-button {
  width: 170px;
  height: 100%;
  background-color: #00d948 !important;
  color: white !important;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;

  .shortlist-button-icon {
    color: white !important;
    opacity: 1 !important;
    margin-left: 20px !important;
  }
}

.player-search-page-container {
  .player-search-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
    height: 50px;

    .ps-title {
      font-size: 25px;
      color: black;
      font-weight: 700;
    }

    .select-all-button {
      height: 100%;
      background-color: #666666 !important;
      color: white !important;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: 700;
      font-family: "Poppins", sans-serif !important;
      margin-right: 10px;
    }
  }

  .player-search-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));

    .player-box {
      height: 225px;
      width: 335px;
      margin-bottom: 25px;
      background-color: white;
      padding: 25px;

      .row-1 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        height: 46px;
      }

      .player-checkbox {
        height: 25px;
        width: 25px;
        background-color: #fafafa;

        label:before {
          height: 25px;
          width: 25px;
          background-color: #fafafa;
        }

        label:after {
          height: 25px;
          width: 25px;
          background-color: var(--primaryColor);
          color: white !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .row-3 {
        display: flex;
      }

      .player-avatar {
        height: 50px !important;
        width: 50px !important;
      }

      .player-name {
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        color: black;
        max-height: 46px;
        overflow: hidden;
        cursor: pointer;
      }
      .player-value {
        font-size: 50px;
        color: var(--primaryColor);
        font-weight: 700;
        line-height: 31.75px;
        padding-left: 15px;
      }

      .team-badge {
        padding-right: 15px;
        display: flex;
        align-items: center;

        img {
          height: 30px;
        }
      }

      .country-badge {
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        align-items: center;
        border-left: solid 1px #f5f5f5;

        img {
          width: 30px;
        }
      }

      .position-age {
        padding-left: 15px;
        padding-right: 20px;
        border-left: solid 1px #f5f5f5;

        .position-row {
          font-size: 13px;
          line-height: 19px;
          font-weight: 700;
          color: black;
        }
      }

      .stat-row {
        display: flex;
        font-size: 13px;
        line-height: 19px;
        font-weight: 700;

        .stat-name {
          color: #b2b2b2;
          margin-right: 5px;
        }
        .stat-value {
          color: black;
        }
      }

      .mins-height {
        padding-left: 15px;
        border-left: solid 1px #f5f5f5;
      }
    }
  }
}

.save-shortlist-popup-container {
  width: 25vw;
  .ss-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    .ss-title {
      font-size: 20px;
      font-weight: 700;
      color: black;
      line-height: 31.75px;
    }
  }

  .shortlist-name-input {
    background-color: #fafafa;
    height: 50px;
    border: 0;
    font-size: 14px !important;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding-left: 20px;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 25px;
  }
  .ss-button-row {
    display: flex;
    height: 50px;
    justify-content: space-between;
    margin-bottom: 40px;

    .ss-button {
      height: 100%;
      width: 47%;
      color: white !important;
      padding-left: 20px;
      padding-right: 20px;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-family: "Poppins", sans-serif !important;

      .ss-button-icon {
        color: white !important;
        opacity: 1 !important;
      }
    }
  }
}

.player-profile-scouting-container {
  width: calc(100vw - 250px);
  padding-top: 26px;
  padding-bottom: 46px;

  .pp-header {
    display: flex;
    justify-content: space-between;
    height: 50px;
    margin-bottom: 40px;
    align-items: center;

    .pl-name {
      font-size: 25px;
      color: black;
      font-weight: 700;
    }
  }

  .general-data-column {
    width: 25%;
    background-color: #fafafa;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 45px;

    .avatar-row {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 50px;

      .player-avatar {
        height: 240px;
        width: 240px;
      }
    }
    .player-name-profile {
      margin-bottom: 40px;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
    }
    .badges-row {
      display: flex;
      margin-bottom: 60px;

      .badge-container {
        img {
          height: 50px;
          margin-bottom: 15px;
        }

        width: 100px;

        .badge-label {
          font-size: 14px;
          line-height: 18px;
          color: #b2b2b2;
          font-weight: 700;
        }
        .badge-value {
          font-size: 14px;
          line-height: 18px;
          color: black;
          font-weight: 700;
        }
      }
    }

    .details-list {
      .detail-row {
        display: flex;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 700;

        .det-label {
          color: #b2b2b2;
        }
        .det-value {
          margin-left: 5px;
        }
      }
    }
  }

  .positions-pitch {
    width: 100%;
  }

  .central-column {
    width: 47%;
    background-color: #fafafa;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 45px;

    .cc-title {
      font-size: 20px;
      font-weight: 700;
      color: black;
    }
  }
}
