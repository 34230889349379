.news-functionalities-wrapper {
  .news-item-container {
    display: flex;
    flex-direction: column;
    padding: 25px;
    border: 2px solid #fafafa;

    .date {
      margin-bottom: 12.5px;
      color: #b2b2b2;
    }

    .title {
      line-height: unset;
    }

    .description {
      margin: 25px 0;
      color: #666666;
    }

    .tfb-button-custom {
      width: 30%;
      min-width: 177px;
    }
  }
}
