.container {
  width: "40%";
  max-width: "49.7%";
  height: 106px;
  display: flex;

  background-color: #f2f2f2;
  flex-grow: 4;
  .sectionImg {
    width: 150px;
    height: 100%;
    max-height: 142.5px;
    object-fit: contain;
  }
  .bigDiv {
    display: flex;
    flex-grow: 2;
    padding: 16px;
    gap: 10px;
    padding-bottom: 8px;
    flex-direction: column;
    justify-content: center;

    .note {
      display: flex;
      gap: 10px;

      .noteButtons {
        display: flex;
        gap: 10px;

        .noteButton {
          height: 14px;
          flex-shrink: 0;
        }
      }
    }
  }

  .contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    .sectionImgMobile {
      display: none;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 15px;
      // min-width: 179px;
    }
    .characteristics {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      max-width: 330px;
    }
    .characteristic,
    .privateChar,
    .publicChar {
      flex-shrink: 0;
      border-radius: 3px;
      background: #fff;
      padding: 6px;
      color: #666;
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 7px; /* 70% */
    }
    .publicChar {
      border: 1px solid var(--primaryColor);
    }
    .privateChar {
      border: 1px solid red;
    }
    .playersNoDiv {
      display: flex;
      margin-right: 6px;
    }
    .playersNo {
      flex-shrink: 0;

      display: inline;
      padding: 0px 6px 0px 0px;
      color: #666;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 15px;
    }
    .title {
      color: #666;
      max-width: 179px;

      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16.5px;
    }

    .viewButtonIcon {
      width: 14px;
      // height: 7.724px;
      flex-shrink: 0;
    }
    .editButtonIcon {
      height: 14px;
      flex-shrink: 0;
    }
    .deleteButtonIcon {
      height: 14px;
      flex-shrink: 0;
    }
    .removeButtonIcon {
      height: 12px;
      flex-shrink: 0;
    }

    .buttonsDiv {
      display: flex;
      gap: 10px;
      flex-direction: row;
    }
  }
}

@media (max-width: 1500px) {
  .container {
    width: auto;
    max-width: none;
    height: auto;
    .contents {
      display: grid;
      grid-template-areas:
        "title title title"
        "durationT durationA buttons";
      column-gap: 3px;
      .durationInput:first-of-type {
        grid-area: durationT;
      }
      .durationInput:last-of-type {
        grid-area: durationA;
      }
      .buttonsDiv {
        grid-area: buttons;
        justify-self: end;
      }
      .info {
        grid-area: title;
      }
      .title {
        color: #666;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 570px) {
  .container {
    width: auto;
    max-width: none;
    height: auto;

    .sectionImg {
      display: none;
    }

    .contents {
      display: grid;

      grid-template-areas:
        "title title title"
        "image image image"
        "durationT durationA buttons";

      .sectionImgMobile {
        display: initial;
        grid-area: image;
        // max-height: 100%;

        max-width: 100%;
      }
      .durationInput:first-of-type {
        grid-area: durationT;
      }
      .durationInput:last-of-type {
        grid-area: durationA;
      }
      .buttonsDiv {
        grid-area: buttons;
      }
      .info {
        grid-area: title;
      }
      .title {
        color: #666;
        max-width: 100%;
      }
    }
  }
}
