.tabsList {
    display: flex;
    .tab {
        cursor: pointer;
        color: #666666;
        font-family: "Poppins";
        font-weight: 700;
        font-size: 14px;
        min-height: 50px;
        padding: 14px 20px;
        background-color: white;
    }
    .activeTab {
        background-color: #fafafa;
        border-radius: 3px 3px 0 0;
    }
}

.tabContainer {
    padding: 50px;
    background-color: #fafafa;
}

@media only screen and (max-width: 768px) {
    .tabContainer {
        padding: 25px;
    }
}
