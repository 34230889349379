.matchesList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .matchData {
        // display: flex;
        justify-content: center;
        margin-bottom: 10px;
        .match-quantity {
            display: flex;
        }
        .match-vs {
            display: flex;
            margin: 0 5px;
            color: #b2b2b2;
        }

        .matchOpponentImg {
            height: 20px;
            width: auto;
            margin: 0 5px;
        }

        .match-date {
            color: #b2b2b2;
            margin-left: 5px;
        }

        .bonusGreyText {
            color: #b2b2b2;
        }
    }
}

.personList {
    display: flex;
    flex-direction: column;
    gap: 50px;
    .bonusReceiver {
        font-size: 12px;
        color: #00aaff;
    }
}

.unmappedPlayer {
    font-size: 12px;
    color: #ff9500;
}
