@media(max-width: 767px){
  .tax-redirectors-container {
    padding-left: 25px !important;
    padding-right: 25px !important;

    .tax-redirectors-table .tax-redirector-row{
      height: 100% !important;
      display: block !important;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 10px;
      border: 1px solid #ddd;
    }  

    .tax-redirectors-table .name-column{
      width: 100% !important;
    }

    .tax-redirectors-table .date-column{
      text-align:left !important;
    }

    .tax-redirectors-table .social-column{
      width: 100% !important;
      text-align: left !important;
    }

    .tax-redirectors-table .options-column{
      width: 100% !important;
      text-align: right !important;
        svg.mr-10{
          font-size: 25px;
          margin-right: 0px !important;
        }
    }

    .tax-redirectors-table .tax-redirectors-table-header{
      display: none !important;
    }

    .tax-redirectors-header {
      flex-direction: column !important;
        .tax-redirectors-title {
          font-size: 17px !important;
        }
    }
  } 
}



.tax-redirectors-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 30px;

  .tax-redirectors-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .tax-redirectors-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .tax-redirectors-table {
    .tax-redirectors-table-header {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
    }

    .name-column {
      width: 25%;
    }

    .social-column {
      width: 20%;
      text-align: center;
    }

    .date-column {
      width: 25%;
      text-align: center;
    }

    .options-column {
      width: 10%;
      text-align: center;
    }

    .tax-redirector-row {
      color: #666;
      height: 55px;
      display: flex;
      align-items: center;
      font-weight: 700;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .tax-redirectors-total-number {
    color: #666;
    height: 55px;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;

    .total-number {
      color: var(--primaryColor) !important;
      padding-left: 10px;
    }
  }
}
