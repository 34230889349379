.editTeamManualOptions {
  height: 50px;
  display: flex;

  .editTeamOption {
    width: 50%;
    justify-content: center;
    align-items: center;
    display: flex;

    div {
      font-size: 14px;
      font-weight: 700;
      color: #666;
      cursor: pointer;
    }
  }
}
.wrapper {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 20px;
}
