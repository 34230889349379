.phoneInputLabel {
  color: #b2b2b2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
}

.phoneInputGroup {
  width: 100%;

  .phoneInputWrapper {
    background-color: #fafafa;
    padding-left: 20px;
    input {
      height: 50px;
      background-color: #fafafa;
      border: none;
      outline: none;
    }
  }
}
