.team-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  height: 50px;

  .team-chart-title {
    font-size: 20px;
    font-weight: 700;
  }

  .team-chart-instat-dropdown {
    background-color: #666666 !important;
    display: flex !important;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    height: 50px;
    min-width: 200px !important;

    .divider.default.text {
      color: white;
    }

    i {
      top: 36% !important;
      color: white;
    }

    .divider.text {
      color: white;
    }
  }
}

.team-season-stats-container {
  padding-top: 65px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 60px;

  .team-season-stats-title {
    font-size: 20px;
    font-weight: 700;
    color: black;
    margin-bottom: 65px;
  }
  .team-season-stats-menu {
    display: flex;
    height: 50px;

    .active-menu-option {
      background-color: #fafafa !important;
      color: black;
    }

    .team-season-stats-menu-option {
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      cursor: pointer;
      background-color: white;
    }
  }

  .team-season-stats-list {
    padding: 50px;
    background-color: #fafafa;
    .team-stats-header {
      font-size: 13px !important;
      color: #b2b2b2 !important;
    }

    .team-stats-list {
      max-height: 220px;
      overflow-y: auto;
    }

    .team-season-stat-row {
      display: flex;
      height: 55px;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      color: #666666;
      padding-left: 20px;
      .team-season-stat-name {
        width: 50%;
      }
      .team-season-stat-total {
        width: 25%;
        text-align: center;
      }
      .team-season-stat-avg {
        width: 25%;
        text-align: center;
      }
    }
  }
}

.instat-last-matches-container {
  padding-top: 65px;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 65px;
  .instat-last-matches-title {
    font-size: 20px;
    font-weight: 700;
    color: black;
    padding-bottom: 65px;
  }

  .instat-last-matches-list {
    overflow-y: auto;
    max-height: 385px;
    .match-row {
      height: 55px;
      width: 100%;
      display: flex;
      align-items: center;
      color: #666666;
      font-weight: 700;
      font-size: 14px;
      position: relative;

      .team {
        width: 46%;
        text-transform: uppercase;
      }

      .team-home {
        text-align: end;
      }

      .team-away {
        text-align: start;
      }
      .score {
        display: flex;
        width: 8%;
        justify-content: center;
        color: #666666;
        font-size: 20px;
        margin-left: 40px;
        margin-right: 40px;

        .score-digit {
          padding-left: 7px;
          padding-right: 7px;
        }
      }
      .details-icon {
        width: 35px;
        height: 35px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #666666;
        position: absolute;
        right: 15px;
        padding: 7px;
        cursor: pointer;
      }
    }
  }
}

.instat-players-top-container {
  padding: 50px;

  .instat-players-top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin-bottom: 50px;
    .instat-players-top-title {
      font-size: 20px;
      font-weight: 700;
    }

    .team-top-instat-dropdown {
      background-color: #666666 !important;
      display: flex !important;
      align-items: center;
      font-family: "Poppins", sans-serif !important;
      font-weight: 700;
      height: 50px;
      min-width: 200px !important;

      .divider.default.text {
        color: white;
      }

      i {
        top: 36% !important;
        color: white;
      }

      .divider.text {
        color: white;
      }
    }
  }

  .instat-players-top-list-header {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    color: #b2b2b2;
    padding-bottom: 13px;
  }
  .instat-players-top-list {
    .player-row {
      display: flex;
      height: 80px;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: #666666;
    }
  }

  .place {
    text-align: center;
    width: 15%;
  }
  .player-name {
    width: 70%;
    display: flex;
    align-items: center;

    .player-avatar {
      margin-right: 15px;
    }
  }

  .value {
    width: 15%;
    display: flex;
    justify-content: center;
  }
}

.instat-match-stats {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 65px;
  padding-bottom: 65px;
  .match-stats-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 60px;
    color: black;
  }

  .match-stats-list {
    .match-stat-row {
      margin-bottom: 30px;
      .name {
        text-align: center;
        font-weight: 700;
        margin-bottom: 5px;
        font-size: 14px;
        color: #c1c1c1;
      }
      .bar {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        .filler {
          background: #19bd13;
          height: 100%;
          border-radius: inherit;
          transition: width 0.2s ease-in;
        }

        .progress-bar {
          position: relative;
          height: 15px;
          width: 80%;
          border-radius: 3px;
          background: #666666 !important;
        }

        .value {
          width: 10%;
          text-align: center;
          color: #666666;
        }
      }
    }
  }
}

.index-chart-instat-y-axis {
  font-weight: 700;
  font-size: 13px;
}

.indexes-evolution-title {
  font-size: 20px;
  font-weight: 700;
  color: black;
  margin-bottom: 30px;
}
