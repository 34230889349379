@media(max-width: 1520px){
  .row .col-lg-6{
    max-width: 100% !important;
    // margin-bottom: 20px;
    // padding-right: 0px;
    // padding-left: 0px;
  }

  .row{
    .cancelmargins, .mv-row{
      flex-direction: row;
    }

    // .mv-row{
    //   flex-direction: row;
    // }

    .col-lg-6{
      max-width: 100% !important;
      // margin-bottom: 20px;
    }
  }
}

@media(max-width: 780px){
  .card{
    .calendar-header{
      flex-direction: column;
    }
  }
}


.financial-chart-dashboard-container {
  padding: 50px;
  .financial-chart-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 31.75px;
    padding-bottom: 30px;
  }
}
