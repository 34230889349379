.quick-setup-wrapper {
  display: flex;
  margin-top: 50px;

  .main-step-group-container {
    display: flex;
    width: 40%;
    justify-content: space-between;

    .main-steps-list {
      display: flex;
      flex-direction: column;
      width: 100%;

      .main-steps {
        display: flex;
        flex-direction: column;
      }
    }

    .horizontal-bar {
      margin-top: 10px;
      height: 1px;
      width: 70%;
      background-color: #f2f2f2;
    }

    .vertical-bar {
      width: 1px;
      background-color: #f2f2f2;
      margin: 0 10px;
    }
  }

  .step-group-container {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-left: 50px;
    gap: 20px;

    .tfb-button-custom {
      div {
        justify-content: space-between;
      }
    }
  }
}
