.fileName {
    width: fit-content;
    cursor: pointer;
    display: flex;
    gap: 10px;

    a,
    a:hover {
        color: inherit;
    }

    .icon {
        height: 20px;
        width: 20px;
    }
}

.fileName:hover {
    text-decoration: underline;
}

.routePath {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .routeElem {
        display: flex;
        gap: 12px;
        align-items: center;
        cursor: pointer;

        .navIcon {
            height: 12px;
            width: 12px;
        }
        .routeName {
            font-family: "Poppins";
            font-weight: 400;
            font-size: 17px;
        }
        .selectedRoute {
            font-weight: 600;
        }
    }
    .routeElem:hover {
        text-decoration: underline;
    }
}

.addContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: fit-content;
}
