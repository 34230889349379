@media (max-width: 991px) {
  .evaluation-category-list-container {
    button.role-button {
      width: 100%;
      .add-white-icon {
        padding-right: 6px;
      }
    }

    .category-list-header {
      margin-bottom: 15px !important;
    }
  }
}

.evaluation-category-list-container {
  .category-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    .category-list-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .categories-list {
    max-height: 190px;
    margin-bottom: 15px;
    overflow-y: auto;

    .category-row {
      height: 50px;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #666666;

      .edit-categories-buttons {
        .delete-venue {
          margin-left: 9px;
        }
      }

      .category-name {
        font-weight: 700;
        font-size: 14px;
      }

      img {
        width: 12px;
        cursor: pointer;
      }
    }
  }

  .add-role-container-closed {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .add-role-container-opened {
    margin-top: 25px;
    margin-bottom: 25px;

    .add-role-input {
      color: #666666;
      font-weight: 700;
      font-size: 14px;
      font-family: "Poppins", sans-serif !important;
      width: 100%;
      padding: 15px;
      background-color: #fafafa !important;
      border: 0;
    }

    .add-role-buttons-container {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
    }
  }

  .role-button {
    background-color: var(--primaryColor) !important;
    color: white !important;
    height: 50px;
    width: 47%;
    font-size: 12px !important;
    font-weight: 700;
    font-family: "Poppins", sans-serif !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;

    .add-white-icon {
      color: white;
      padding-left: 20px;
      opacity: 1 !important;
    }
  }

  .role-button-dark {
    background-color: #666666 !important;
  }
}

.add-player-buttons {
  .custom-button-dual {
    color: white !important;
  }
  .grey-button {
    background-color: #666666 !important;
  }
}
