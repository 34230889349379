.edit-club-logo-image {
  .image-input-container {
    background-color: #fafafa;
    padding-right: 0px;
    width: 100%;
    margin-bottom: 20px;
    padding: 15px;

    span {
      font-size: 14px;
      font-weight: 700;
      color: #666666;
    }

    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
      overflow: visible;
      width: 2px;
    }

    label {
      font-weight: 700;
      font-size: 14px;
      color: #b2b2b2;
      padding-left: 0px;
      cursor: pointer;
      width: 100px;
      height: 100px;
      display: block;
      margin: 0 auto !important;
      .image-view {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 0.5px solid #d2d2d2;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 80%;
          height: auto;
          max-height: 80%;
          width: auto;
        }
        span {
          font-size: 12px;
          display: flex;
          width: 100%;
          align-items: center;
          height: 100%;
          text-align: center;
          justify-content: center;
          i {
            font-size: 20px !important;
          }
        }
      }
    }
  }
}
