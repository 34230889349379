.edit-news-container {
  padding: 50px;
  .edit-news-header {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    .edit-news-title {
      font-size: 25px;
      font-weight: 700;
    }
    .edit-news-buttons {
      display: flex;
      align-items: center;
    }
  }

  .element-to-add-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .edit-news-button {
    background-color: var(--primaryColor) !important;
    height: 50px;
    font-family: "Poppins", sans-serif !important;
    font-size: 12px !important;
    color: white !important;
    display: flex;
    align-items: center;
    margin-left: 15px;

    .edit-news-button-icon {
      color: white;
      padding-left: 20px;
      opacity: 1 !important;
    }
  }
  .dark-background {
    background-color: #666666 !important;
  }

  .edit-news-form-container {
    display: flex;
    justify-content: space-between;
    .edit-news-form {
      width: 40%;
      .edit-news-row {
        justify-content: space-between;

        .title-input {
          width: 66%;
        }

        .tag-dropdown {
          width: 32%;
        }
      }

      .edit-news-image-input {
        margin-bottom: 15px;
      }

      .edit-news-sections-title {
        font-weight: 700;
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 15px;
      }
      .edit-news-sections-list {
        margin-bottom: 30px;
        .edit-news-section {
          display: flex;
          align-items: center;
          height: 50px;
          font-weight: 700;
          font-size: 14px;
          justify-content: space-between;
          padding-left: 15px;
          padding-right: 15px;
          color: #666666;

          .edit-news-section-type {
            width: 20%;
          }
          .edit-news-section-preview {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 65%;
          }
          .edit-news-section-options {
            display: flex;
            justify-content: flex-end;
            width: 15%;

            .news-option-icon {
              color: #666666;
            }
          }
        }
      }
    }
  }
}

.news-article-preview-container {
  padding: 50px;
  height: fit-content;

  .preview-text-container {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .news-article-preview-header {
    font-size: 20px;
    color: black;
    font-weight: 700;
    height: 50px;
    margin-bottom: 50px;
  }

  .preview-main-image-container {
    margin-bottom: 55px;
    img {
      width: 100%;
    }
  }

  .preview-article-title {
    font-size: 38px;
    color: #000;
    font-weight: 700;
    padding-bottom: 35px;
    text-align: center;
  }

  .news-article-preview-content {
    font-family: "HelveticaNeue";
    padding: 30px;
    background-color: #fafafa;
    min-height: 339px;
  }

  .preview-paragraph-container {
    margin-bottom: 25px;
    color: grey;
    font-size: 10px;
    line-height: 1.75;
  }

  .preview-subtitle-container {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.143;
  }

  .preview-image-container {
    margin-bottom: 40px;
    img {
      width: 100%;
    }
  }
}

.edit-news-setion-container {
  width: 25vw;
  .edit-news-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .edit-news-popup-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .edit-section-popup-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .edit-section-button {
      height: 50px;
      width: 47%;
      font-family: Poppins, sans-serif !important;
      font-size: 13px !important;
      color: #fff !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;

      .edit-section-icon {
        color: white;
        opacity: 1 !important;
      }
    }
  }

  .edit-section-text-area {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding: 10px;
    padding-left: 20px;
    border: 0;
    background-color: #fafafa;
    margin-bottom: 25px;
  }
}