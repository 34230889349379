.info-container {
    padding: 35px;
    .title-info-container {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    .section-container {
        .title {
            font-size: 16px;
            font-weight: 700;
            // margin-bottom: 20px;
        }
        .content {
            .text-area {
                width: 100%;
                font-size: 13px;
                font-weight: 700;
                color: #b2b2b2 !important;
                padding: 10px;
                padding-left: 20px;
                border: 0;
                background-color: #fafafa;
                margin-top: 15px;
            }
            .contact-row {
                // display: flex;
                width: 60%;
                // height: 70px;
                justify-content: space-between;
                margin-bottom: 25px;

                .add-contact-input {
                    // width: 32%;
                    // height: 100%;
                    height: 50px;
                    width: 100%;
                    border: 0;
                    font-size: 14px;
                    font-weight: 700;
                    padding-left: 15px;
                    background-color: #fafafa;
                    color: #666;
                    margin-top: 15px;
                }
            }
        }
    }
    .info-final-buttons {
        display: flex;
        justify-content: space-between;
        // margin-top: 20px;
    }

    .info-button {
        background-color: var(--primaryColor) !important;
        height: 50px;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        // width: 45%;

        .info-button-icon {
            color: white;
            opacity: 1 !important;
        }
        .info-button-icon-dark {
            color: #666666;
            opacity: 1 !important;
        }
    }

    .dark-background {
        background-color: #666666 !important;
    }

    .light-background {
        background-color: #d4d4d4 !important;
        color: #666666 !important;
    }
}

.sponsors-container {
    padding: 35px;
    .title-sponsors-container {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .add-sponsor-button {
            background-color: var(--primaryColor) !important;
            height: 50px;
            font-family: "Poppins", sans-serif !important;
            font-size: 13px !important;
            color: white !important;
            display: flex;
            align-items: center;

            .add-button-white-icon {
                color: white;
                padding-left: 20px;
                opacity: 1 !important;
            }
        }
    }
    .sponsors-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .sponsor-elem-container {
            padding: 20px;
            .logo-container {
                display: flex;
                justify-content: center;
                img {
                    height: 100px;
                    width: auto;
                }
            }
            a {
                color: #3f4254;
            }
            .sponsor-name {
                font-size: 16px;
                font-weight: 700;
                text-align: center;
                margin: 8px 0;
                text-decoration: none;
            }
            .modify-buttons {
                display: flex;
                justify-content: center;
                gap: 20px;

                .button {
                    cursor: pointer;
                }
                .button:hover {
                    color: red;
                }
            }
        }
    }
}

.enable-container,
.prizes-container {
    padding: 35px;
    .title-enable-container {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    .enable-registration-form {
        display: flex;
        // justify-content: space-between;
        gap: 30px;
        align-items: center;
        margin-bottom: 10px;
        .title {
            font-size: 20px;
            font-weight: 700;
        }

        .title2 {
            font-size: 16px;
            font-weight: 700;
        }
    }
    .table-prizes {
        .header-line {
            display: flex;
            padding: 10px 15px;
            font-size: 13px;
            font-weight: 700;
            color: #b2b2b2;
            align-items: center;
            .col1 {
                text-align: center;
            }
            .name {
                width: 30%;
                text-align: left;
            }
            .prize {
                width: 28%;
            }
            .prize-type {
                width: 30%;
            }
            .add-button {
                width: 12%;

                .info-button {
                    background-color: var(--primaryColor) !important;
                    height: 50px;
                    font-family: "Poppins", sans-serif !important;
                    font-size: 13px !important;
                    color: white !important;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    // width: 45%;

                    .info-button-icon {
                        color: white;
                        opacity: 1 !important;
                    }
                    .info-button-icon-dark {
                        color: #666666;
                        opacity: 1 !important;
                    }
                }

                .dark-background {
                    background-color: #666666 !important;
                }

                .light-background {
                    background-color: #d4d4d4 !important;
                    color: #666666 !important;
                }
            }
        }

        .content-lines {
            .line {
                display: flex;
                padding: 10px 15px;
                align-items: center;
                height: 80px;
                font-weight: 700;
                font-size: 14px;
                color: #666666;
                .col1 {
                }
                .name {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    .photo {
                    }

                    .player-name {
                        margin-left: 15px;
                    }
                }
                .prize {
                    width: 28%;
                    text-align: center;
                    padding: 0 10px;
                }
                .prize-type {
                    width: 30%;
                    text-align: center;
                    padding: 0 10px;
                }
                .add-button {
                    width: 12%;
                    display: flex;
                    // gap: 10px;
                    justify-content: center;
                    align-items: baseline;
                }
            }
            .no-line {
                background-color: #fafafa;
                padding: 10px 15px;
            }
        }
    }
}

.add-prize-container {
    width: 350px;
    min-height: 350px;

    .add-prize-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        .add-prize-title {
            font-size: 20px;
            line-height: 31.75px;
            font-weight: bold;
        }

        .close {
            cursor: pointer;
        }
    }

    .add-prize-row {
        margin-bottom: 15px;
        height: 50px;
        display: flex;
        justify-content: space-between;
        position: relative;

        .add-prize-input {
            width: 100%;
            height: 100%;
            border: 0;
            font-size: 14px;
            font-weight: 700;
            padding-left: 25px;
            background-color: #fafafa;
            color: #666666;
        }

        select {
            width: 100%;
            margin-bottom: 20px;
            height: 50px;
            border: 0;
            font-size: 14px;
            font-weight: 700;
            padding-left: 25px;
            background-color: #fafafa;
            color: #b2b2b2;

            option {
                padding-left: 25px;
                background-color: #fafafa;
                color: #666666;
                font-size: 13px;
            }
        }
    }

    .add-prize-row-photo {
        .image-input-container {
            background-color: #fafafa;
            padding-right: 0px;
            padding-bottom: 15px;
            width: 100%;
            margin-bottom: 20px;
            .title {
                font-size: 14px;
                font-weight: 700;
                color: #b2b2b2;
                padding: 15px 0 0 25px;
            }

            input {
                opacity: 0;
                position: absolute;
                z-index: -1;
                overflow: visible;
                width: 2px;
            }

            label {
                font-weight: 700;
                font-size: 14px;
                color: #b2b2b2;
                padding-left: 0px;
                cursor: pointer;
                width: 100px;
                height: 100px;
                display: block;
                margin: 0 auto !important;
                .image-view {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    border: 0.5px solid #d2d2d2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 80%;
                        height: auto;
                        max-height: 80%;
                        width: auto;
                    }
                    span {
                        font-size: 12px;
                        display: flex;
                        width: 100%;
                        align-items: center;
                        height: 100%;
                        text-align: center;
                        justify-content: center;
                        i {
                            font-size: 20px !important;
                        }
                    }
                }
            }
        }
    }
}

.MuiSwitch-colorPrimary.Mui-checked {
    color: var(--primaryColor) !important;
}

@media only screen and (max-width: 590px) {
    .sponsors-container {
        .title-sponsors-container {
            .add-sponsor-button {
                display: block;
                .add-button-white-icon {
                    padding-left: 0;
                }
            }
        }
        .sponsors-list {
            .sponsor-elem-container {
                .logo-container {
                    img {
                        height: 50px !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .info-container {
        .section-container {
            .content {
                margin-bottom: 25px;
                .contact-row {
                    width: 100%;
                }
            }
        }
    }
    .sponsors-container {
        .sponsors-list {
            .sponsor-elem-container {
                .logo-container {
                    img {
                        height: 70px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .prizes-container {
        .table-prizes {
            .header-line {
                .add-button {
                    .info-button {
                        display: block;
                    }
                }
            }
        }
    }
}
