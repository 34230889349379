.addedBy {
  margin-top: -10px;
  margin-bottom: 25px;
  z-index: 9999 !important;
}
.addedBy,
.descriptionP {
  color: #666;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 71.429% */
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .tag,
  .privateTag,
  .publicTag {
    flex-shrink: 0;
    border-radius: 3px;
    background: #f2f2f2;
    padding: 10px;
    color: #666;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 70% */
  }
  .publicTag {
    border: 2px solid var(--primaryColor);
  }
  .privateTag {
    border: 2px solid red;
  }
  .playersNoDiv {
    display: flex;
    margin-right: 6px;
  }
  .playersNo {
    flex-shrink: 0;

    display: inline;
    padding: 0px 6px 0px 0px;
    color: #666;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
  }

  .subtitle {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 11px; /* 68.75% */
  }

  .descriptionDiv {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .labelsDiv {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .labelsViewContainer {
    display: flex;
  }

  .mediaDiv {
    display: flex;
    flex-direction: column;
    gap: 25px;
    .mediaContainer {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      img {
        width: 250px;
        aspect-ratio: 1.5;
      }
      .sectionImg:hover {
        cursor: pointer;
      }
      .sectionImg {
        height: 100%;
      }
      .videoThumbnailDiv {
        position: relative;
      }
      .playButtonDiv {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 250px;
        aspect-ratio: 1.5;
      }
    }
  }
}
