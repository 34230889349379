.orders-container {
  padding: 50px;

  .orders-header {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

    .orders-title {
      font-size: 20px;
      font-weight: 700;
    }

    .orders-interactive {
      .add-orders-button {
        background-color: var(--primaryColor) !important;
        color: white !important;
        font-family: "Poppins", sans-serif !important;
        font-weight: 700;
        height: 50px;
      }
    }
  }

  .orders-list {
    .no-orders-call-up-message {
      text-align: center;
      .no-orders-call-up-message-text {
        font-size: 15px;
        font-weight: 600;
        padding-top: 5px;
      }
    }

    .desktop-header {
      display: flex;
    }

    .orders-row-table-header {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 15px;
    }

    .orders-row {
      height: 55px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: #666666;
      padding-left: 25px;
      padding-right: 25px;

      .content-row {
        width: 100%;
        display: flex;
        align-items: center;

        .photo-column {
          img {
            max-width: 50px;
            max-height: 50px;
          }
        }

        .actions-column {
          display: flex;
          justify-content: center;
          gap: 10px;
        }
      }
    }
  }

  .id-column {
    width: 7%;
  }
  .date-column,
  .shipping-column,
  .amount-column {
    width: 15%;
    text-align: center;
  }

  .actions-column {
    width: 10%;
    text-align: center;
  }


  .client-column,
  .status-column {
    width: 20%;
    text-align: center;
  }
}

@media only screen and (max-width: 992px) {
  .orders-container {
    padding: 10px;

    .desktop-header {
      display: none !important;
    }
    .orders-list {
      .orders-row {
        height: auto;

        .content-row {
          display: none;
        }
      }
    }
  }
}

.mobile-listing {
  display: none;

  @media only screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    gap: 20px;

    .mobile-list-item {
      display: flex;

      div {
        text-align: left;
        width: 100%;
        img {
          max-width: 50px;
          max-height: 50px;
        }

        button {
          margin-right: 10px;
        }
      }
    }
  }
}
