.tfbSearchInputGroup {
    // width: 100%;

    .tfbSearchInputLabel {
        color: #b2b2b2;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
    }

    .tfbSearchInput {
        div {
            width: 100%;
        }

        :global .ui.icon.input {
            input {
                background-color: #fafafa !important;
                border: 2px solid #f2f2f2 !important;
                border-radius: 3px !important;
                height: 50px;
                padding-left: 20px !important;
                padding-right: 20px !important;
                padding: 14px 20px !important;

                font-family: "Poppins";
                font-weight: 700;
                size: 14px;

                &::placeholder {
                    color: #666666 !important;
                }
            }

            .search.icon {
                color: #666666;
                opacity: 1;
                right: 10px;
            }
        }
    }
}
