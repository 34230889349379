.selectActivePlayer {
  margin: 25px 0;
}

.averageAge {
  color: var(--primaryColor);
}

.age {
  color: #b2b2b2;
}

.inactivePlayer {
  filter: opacity(0.85);
}

.inactivePlayerFlag {
  filter: grayscale(1);
}
.inactivePlayerAvatar {
  filter: grayscale(1);
}
.checkedRow {
  background-color: rgb(0 217 72 / 10%) !important;
}
.mainFootDiv {
  display: flex;
  flex-direction: column;
}

.headerCell {
  display: flex;
  gap: 10px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  .headerFilter {
    display: flex;
    flex-direction: column;
    gap: 2.5px;
    align-items: center;
    justify-content: center;
  }
}
