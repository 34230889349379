.searchAndFiltersDiv {
  // display: flex;
  // flex-direction: column;
  // gap: 10px;
  // justify-content: space-between;

  .filtersDiv {
    display: flex;
    gap: 10px;
    flex-direction: column;

    .firstRowDiv,
    .secondRowDiv {
      display: flex;
      gap: 16px;
      flex-direction: row;
    }
    .secondRowDiv {
      align-self: flex-end;
    }
    .playersNoDiv {
      margin-right: 12px;
    }
    .rangeSliderLabel {
      // margin-bottom: 16px;
      color: #b2b2b2;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
    }
  }
}
.playersNoDiv {
  input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    pointer-events: all;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #c6c6c6;
    cursor: pointer;
  }
}
.filterRangesDivAddTraining {
  display: flex;
  gap: 20px;
}

.buttonsDiv {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.sectionsDragContainer {
  border: 2px dashed #ededed;
  position: relative;
  margin-bottom: 65px;
  padding: 8px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  min-height: 175px;

  .table {
    border-collapse: separate;
    border-spacing: 0 10px;

    .tableCell {
      min-height: fit-content !important;
      padding: 0 !important;
      background-color: white !important;
    }

    .tableCellHead {
      min-height: fit-content !important;
      padding: 0 !important;
    }

    .tableCellTitle {
      background-color: #fafafa !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }

    .tableCellDuration {
      padding-left: 10px !important;
      padding-right: 5px !important;
    }

    .tableCellDurationEffective {
      padding-left: 5px !important;
      padding-right: 10px !important;
    }

    .totalRow {
      background-color: #666666 !important;
      color: white !important;

      .tableCellTotal {
        background-color: #666666 !important;
        color: white !important;
      }

      .tableCellTotalDuration {
        color: var(--primaryColor) !important;
      }
    }
  }
}

.sectionsAllDragContainer {
  border: 2px dashed #ededed;
  padding: 8px;

  .sectionList {
    .sections {
      display: flex;
      gap: 6px;
      flex-wrap: wrap;
      .sectionItem {
        height: 50px;
        padding: 20px;
      }
    }

    max-height: 50vh;
    overflow-y: auto;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1500px) {
  .cardCustomMacBook {
    padding: 34px !important;
  }
}
.sections > div {
  flex-grow: 4;
}
.durationInput {
  display: flex;
  flex-direction: column;
  width: 60px;
  flex-grow: 2;
}
.durationInputLabel {
  width: 60px;
  color: #b2b2b2;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 9px; /* 69.231% */
}

.addSectionContainer {
  display: flex;
  gap: 10px;
  align-items: end;
  margin-bottom: 20px;

  .addSectionBtn {
    min-width: 54px;
    height: 54px;
    max-width: 54px;
  }
}

@media only screen and (min-width: 1224px) {
  .rowCustomMacBook {
    flex-direction: row !important;
  }
  .onlyMobile {
    display: none;
  }
}

@media only screen and (max-width: 1224px) {
  .onlyDesktop {
    display: none;
  }

  .sectionsDragContainer {
    margin-bottom: 40px;
    padding: 10px;
  }

  .addSectionContainer {
    .addSectionBtn {
      min-width: 50px;
      height: 50px;
      max-width: 50px;
    }
  }

  .trainingDateTime {
    flex-direction: column !important;
    gap: 20px !important;
  }
}
.searchTrainingSections .ui.icon.input {
  width: 100%;
}
.searchTrainingSections .ui.icon.input input {
  width: 100%;
}

.searchBarAndFilter {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.searchBarDiv {
  flex-grow: 4;
}

.searchDiv {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;

  .searchComponent {
    width: 350px;
  }
}

@media only screen and (max-width: 1024px) {
  .searchDiv {
    gap: 10px;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    .searchComponent {
      width: 100%;
      .searchVideo {
        width: 100%;
      }
    }
    .fullWidth,
    .selectDiv {
      width: 100%;
    }
  }
  .searchAndFiltersDiv {
    .filtersDiv {
      display: flex;
      gap: 10px;
      flex-direction: column;
      width: 100%;
      .firstRowDiv,
      .secondRowDiv {
        width: 100%;
        display: flex;
        gap: 16px;
        flex-direction: column;
      }
      .playersNoDiv,
      .goalkeepersNoDiv {
        margin: 0px 10px 0px 10px;
      }
    }
  }
}
