.trainingStatsContainer {
  span {
    color: #b2b2b2;

    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px; /* 130.769% */
  }

  .tableHead {
    padding: 15px 25px;
    width: 30%;
    display: flex;
    justify-content: space-between;
  }
}

.monthHeadRow {
  background-color: #666666 !important;
  border-top: 1px solid white;
  :global .MuiTableCell-root {
    color: white !important;
  }

  .progressBar {
    height: 10px;
    border-radius: 5px;
  }
}
.monthHeadTableRow {
  background-color: #fafafa !important;
  :global .MuiTableCell-root {
    color: #b2b2b2 !important;
  }
}
.monthHeadTableRow {
  background-color: #fafafa !important;
  :global .MuiTableCell-root {
    color: #b2b2b2 !important;
  }
}
