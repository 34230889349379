.knockout-diagram-container {
  display: flex;

  .diagram-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    .round-name {
      font-size: 15px;
      font-weight: 700;
      color: #666666;
      margin-bottom: 25px;
    }

    .match-container {
      width: 100%;
      background-color: #666666;
    }
  }
}
