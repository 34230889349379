.wrapper {
  padding: 50px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  background-color: #fafafa;
}

.greenColor {
  color: green;
}
