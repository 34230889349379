.certificates-container {
  margin: 5%;

  .no-medical-certificates-team-call-up-message {
    text-align: center;
    .no-medical-certificates-team-call-up-message-text {
      font-size: 15px;
      font-weight: 600;
      padding-top: 5px;
    }
  }

  .certificates-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    .certificates-header-title {
      font-weight: 700;
      color: #000;
      font-size: 20px;
      display: flex;
      align-items: center;
    }
  }
  .certificates-table {
    .certificate-header-row {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 15px;
      padding-bottom: 15px;
      font-family: "Poppins", sans-serif !important;

      .col-header {
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      .player-name {
        width: 25%;
        justify-content: start;
      }
      .date-examination {
        width: 15%;
      }
      .date-expire {
        width: 15%;
      }
      .img-certificate {
        width: 35%;
      }
      .error-certificate {
        width: 10%;
      }
    }

    .player-certificate-row {
      display: flex;
      height: 60px;
      align-items: center;
      font-weight: 700;
      color: #666666;
      padding-left: 15px;
      .row1 {
        padding: 0 10px;
        display: flex;
        align-items: center;
        // text-align: center;
        justify-content: center;
      }

      .player-name {
        width: 25%;
        justify-content: start;
      }
      .date-examination {
        width: 15%;
      }
      .date-expire {
        width: 15%;
      }
      .img-certificate {
        width: 35%;
      }
      .error-certificate {
        width: 10%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .certificates-container {
    .certificates-header {
      flex-direction: column;
      margin-bottom: 19px;
      .add-team-button {
        width: 100%;
        margin-top: 15px;
      }
    }
    .certificates-table {
      .certificate-header-row {
        padding-left: 0;
        .player-name {
          text-align: start;
        }
        .date-examination {
          width: 25%;
        }
        .date-expire {
          width: 25%;
        }
        .img-certificate {
          width: 15%;
          color: transparent;
        }
        .error-certificate {
          width: 10%;
        }
      }
      .player-certificate-row {
        padding-left: 0;
        .player-name {
          justify-content: start;
        }
        .date-examination {
          width: 25%;
        }
        .date-expire {
          width: 25%;
        }
        .img-certificate {
          width: 15%;
          color: transparent;
        }
        .error-certificate {
          width: 10%;
        }
      }
    }
  }
}
