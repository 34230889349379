.shapeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 33px;
    max-width: fit-content;
    min-height: 33px;
    color: #fafafa;
    font-size: 14px;
    font-weight: 700;
    padding: 0 13.2px;
}

.square {
    border-radius: 5px;
}

.green {
    background: green;
    .shapeText {
        color: #ffffff;
    }
}

.red {
    background: red;
    .shapeText {
        color: #ffffff;
    }
}

.orange {
    background: #ff9500;
    .shapeText {
        color: #ffffff;
    }
}

.xlightGray {
    background: #fafafa;
    .shapeText {
        color: #666666;
    }
}
