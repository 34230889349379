.render-privacy-policy {
    font-family: "HelveticaNeue";
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: "HelveticaNeue" !important;
    }
}
