.matchResultWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  font-family: "Poppins";
  font-weight: 700;

  .matchResult {
    display: flex;
    align-items: center;
    width: 100%;

    .teamName {
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .result {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }
}
