.add-player-squad-popup-section {
  font-family: "Poppins";
  margin-bottom: 30px;
  .pop-up-section-title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #000000;
    margin-bottom: 20px;
  }
  .pop-up-section-body {
    .pop-up-row {
      margin-bottom: 20px;
      .row-input {
        width: 100%;
        background-color: #fafafa;
        border: 0;
        font-size: 13px !important;
        font-weight: 700;
        padding-left: 14px;
        padding-right: 14px;
        border-radius: 3px;
        min-height: 50px;
        color: black;
      }

      .row-input-with-currency {
        display: flex;
        gap: 10px;

        .currency-options {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      .row-input::placeholder {
        color: #666666;
      }
      .row-player {
        margin-top: 10px;
        display: flex;
        column-gap: 20px;
        flex-wrap: wrap;
      }
      .row-team {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      .delete-team-icon-button {
        margin-top: 18px;
      }

      .row-input-with-date {
        height: 50px;
        position: relative;
        width: 100%;
        display: flex;

        .input-with-date {
          width: 100%;
          input[name="payment_payed_date"] {
            color: #666666 !important;
          }
          fieldset {
            border: none;
          }
        }
        .calendar-icon {
          position: absolute;
          right: 15px;
          top: 0;
          bottom: 0;
          margin-top: auto !important;
          margin-bottom: auto !important;
        }
      }
    }
  }

  .animate-in {
    animation: animateIn 0.7s ease-in-out forwards;
    opacity: 0;
  }

  @keyframes animateIn {
    from {
      opacity: 0;
      transform: translateY(-5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
