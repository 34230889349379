.create-account-container {
  .tabs-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

    .tabs {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .create-account,
      .start-trial {
        flex: 1 1 0;
        display: flex;
        justify-content: center;
        gap: 15px;

        .text {
          align-self: center;
        }
      }

      .vertical-bar {
        width: 1px;
        height: 100%;
        background-color: #f2f2f2;
      }
    }

    .horizontal-bar {
      width: 100%;
      height: 1px;
      background-color: #f2f2f2;
      margin-top: 25px;
    }
  }
}

.create-account-step-circle-container {
  width: 35px;
  height: 35px;
  background-color: black;
  border-radius: 25px;
  position: relative;
  align-self: center;

  .number-value {
    color: white;
    position: absolute;
    right: 14.5px;
    bottom: 7px;
    font-weight: 700;
    align-self: center;
  }
}
