.add-mail-content-container {
    padding: 0px;
    .add-mail-content-header {
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      .add-mail-content-title {
        font-size: 20px;
        color: black;
        font-weight: 700;
      }
    }
  
    .add-mail-content-form-container {
      display: flex;
      justify-content: space-between;
      .add-mail-content-form {
        width: 100%;
        .add-mail-content-row {
          justify-content: space-between;
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 15px;
          height: 50px;
  
          .title-input {
            width: 100%;
            height: 100%;
            border: 0;
            font-size: 14px;
            font-weight: 700;
            padding-left: 20px;
            background-color: #fafafa;
            color: #b2b2b2 !important;
            font-family: "Poppins", sans-serif !important;
          }
  
          .tag-dropdown {
            width: 32%;
            color: #b2b2b2;
            font-family: "Poppins", sans-serif !important;
            font-size: 14px;
          }
  
          select {
            width: 47%;
            height: 100%;
            border: 0;
            font-size: 14px;
            font-weight: 700;
            padding-left: 25px;
            background-color: #fafafa;
            color: #b2b2b2;
  
            option {
              padding-left: 25px;
              background-color: #fafafa;
              color: #666666;
              font-size: 13px;
            }
          }
        }
  
        .image-input-container {
          display: flex;
          height: 50px;
          align-items: center;
          background-color: #fafafa;
          justify-content: space-between;
          padding-right: 20px;
          width: 47%;
  
          input {
            opacity: 0;
            position: absolute;
            z-index: -1;
            overflow: visible;
          }
  
          label {
            font-weight: 700;
            font-size: 14px;
            color: #b2b2b2;
            padding-left: 20px;
            cursor: pointer;
            margin-bottom: 0 !important;
          }
        }
  
        .element-to-add-image-input {
          width: 100% !important;
        }
  
        .add-mail-content-image-container {
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
          height: 115px;
  
          .add-mail-content-image-preview {
            width: 47%;
            background-color: #fafafa;
  
            img {
              width: 100%;
              object-fit: cover;
              max-height: 100%;
            }
          }
  
          .add-mail-content-image-right-side {
            width: 47%;
          }
        }
      }
  
      .sections-title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 25px;
      }
  
      .add-options-buttons {
        display: flex;
  
        justify-content: space-between;
        flex-flow: row wrap;
  
        .add-mail-content-button {
          width: 48% !important;
          font-size: 13px !important;
          height: 50px;
  
          display: flex;
          justify-content: space-between;
        }
      }
  
      .add-mail-content-sections-list {
        margin-bottom: 30px;
        .add-mail-content-section {
          display: flex;
          align-items: center;
          height: 50px;
          font-weight: 700;
          font-size: 14px;
          justify-content: space-between;
          padding-left: 15px;
          padding-right: 15px;
          color: #666666;
  
          .add-mail-content-section-type {
            width: 20%;
          }
          .add-mail-content-section-preview {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 65%;
          }
          .add-mail-content-section-options {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 15%;
  
            img {
              width: 12px;
              cursor: pointer;
            }
  
            .mail-content-option-icon {
              color: #666666;
            }
          }
        }
      }
  
      .element-to-add-buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        margin-top: 15px;
      }
  
      .text-area-add-mail-content {
        width: 100%;
        font-size: 13px;
        font-weight: 700;
        color: #b2b2b2 !important;
        padding: 10px;
        padding-left: 20px;
        border: 0;
        background-color: #fafafa;
      }
    }
  
    .add-mail-content-button {
      background-color: var(--primaryColor) !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: white !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
  
      .add-mail-content-button-icon {
        color: white;
        opacity: 1 !important;
      }
      .add-mail-content-button-icon-dark {
        color: #666666;
        opacity: 1 !important;
      }
    }
    .dark-background {
      background-color: #666666 !important;
    }
  
    .light-background {
      background-color: #d4d4d4 !important;
      color: #666666 !important;
    }
  
    .mail-content-article-final-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
    }
  }
  
  .mail-content-article-preview-container {
    padding: 0px;
    height: fit-content;
  
    .preview-text-container {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
  
    .mail-content-article-preview-header {
      font-size: 20px;
      color: black;
      font-weight: 700;
      height: 50px;
      margin-bottom: 50px;
    }
  
    .preview-main-image-container {
      margin-bottom: 45px;
      img {
        width: 100%;
      }
    }
  
    .preview-article-title {
      font-size: 30px;
      color: #000;
      font-weight: 700;
      padding-bottom: 35px;
      text-align: center;
    }
  
    .mail-content-article-preview-content {
      font-family: "HelveticaNeue";
      padding: 30px;
      background-color: #fafafa;
      min-height: 339px;
    }
  
    .preview-paragraph-container {
      margin-bottom: 25px;
      color: grey;
      font-size: 18px;
      line-height: 1.75;
    }
  
    .preview-subtitle-container {
      font-size: 25px;
      color: #000;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: 1.143;
    }
  
    .preview-highlighted-container {
      font-size: 12px;
      color: #000;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: 17.8px;
      padding-left: 15px;
      border-left: solid 1px #fbeb24;
    }
  
    .preview-image-container {
      margin-bottom: 75px;
      img {
        width: 100%;
      }
    }
  }
  
  .loader-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .edit-section-add-mail-content-container {
    width: 25vw;
    .edit-mail-content-popup-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
  
      .edit-mail-content-popup-title {
        font-size: 20px;
        font-weight: 700;
      }
    }
  
    .edit-section-text-area {
      width: 100%;
      font-size: 13px;
      font-weight: 700;
      color: #b2b2b2 !important;
      padding: 10px;
      padding-left: 20px;
      border: 0;
      background-color: #fafafa;
      margin-bottom: 25px;
    }
  
    .edit-section-popup-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .edit-section-button {
        height: 50px;
        width: 47%;
        font-family: Poppins, sans-serif !important;
        font-size: 13px !important;
        color: #fff !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
  
        .edit-section-icon {
          color: white;
          opacity: 1 !important;
        }
      }
    }
  }

  .card-mail-content {
    box-shadow: none !important;
  }
  