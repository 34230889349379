@media (min-width: 991px) {
  .venue-management-page {
    padding: 50px;
    .venue-management-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;

      .venue-management-title {
        font-size: 24px;
        font-weight: 700;
      }
      .venue-management-buttons {
        display: flex;
        gap: 20px;
        .calendar-button {
          white-space: nowrap;
          // background-color: var(--primaryColor) !important;
          color: white !important;
          font-weight: 700 !important;
          font-family: "Poppins", sans-serif !important;
          height: 50px;
          padding-left: 20px;
          padding-right: 20px;

          .plus-icon-button {
            padding-left: 15px;
            color: white;
            opacity: 1 !important;
          }
        }
      }
    }
    .fc {
      .fc-header-toolbar {
        .fc-toolbar-chunk {
          text-transform: capitalize !important;
          // .fc-button-group {
          // }

          .fc-button-group {
            // display: flex;
            align-items: center;
            // background-color: aquamarine;

            button {
              border: 0 !important;
              // color: black !important;
              color: white !important;
              background-color: #2c3e50 !important;
              padding-left: 5px !important;
              padding-right: 5px !important;
            }
            .fc-button-active {
              background-color: var(--primaryColor) !important;
            }
            button:focus {
              box-shadow: none !important;
            }

            h2 {
              font-size: 25px !important;
              font-weight: 800 !important;
            }
          }
        }
      }
    }
  }

  .add-player-form {
    .add-player-row {
      justify-content: space-between;
      gap: 5px;
      align-items: center;
      .add-icon {
        cursor: pointer;
      }
    }
    .add-checkbox-input {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 20px;
      background-color: #fafafa;
      padding-top: 15px;

      label {
        color: #b3b3b3;
      }

      label:hover {
        // color: #b3b3b3;
      }
    }
    .add-player-buttons {
      margin-top: 30px;
    }
    .add-new-sub-venue {
      padding-left: 20px;
      font-size: 12px;
      text-decoration: underline;
      margin-top: 5px;
      cursor: pointer;
    }

    .add-match-row {
      margin-bottom: 15px;
      position: relative;

      .date-input {
        width: 100%;
        height: 50px;
        background-color: #fafafa;

        div {
          color: #b2b2b2;
          font-weight: 700;
          padding-left: 5px;
        }

        fieldset {
          border-color: #fafafa;
        }

        .calendar-icon {
          position: absolute;
          right: 15px;
          top: 0;
          bottom: 0;
          margin-top: auto !important;
          margin-bottom: auto !important;
        }
      }

      .round-input {
        width: 100%;
        height: 50px;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 25px;
        background-color: #fafafa;
        color: #b2b2b2;
      }

      .add-team-dropdown {
        select {
          width: 100%;
          height: 50px;
          border: 0;
          font-size: 14px;
          font-weight: 700;
          padding-left: 25px;
          background-color: #fafafa;
          color: #b2b2b2;

          option {
            padding-left: 25px;
            background-color: #fafafa;
            color: #666666;
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .venue-management-page {
    padding: 10px 0px 20px 6px;
    .venue-management-header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 40px;

      .venue-management-title {
        font-size: 24px;
        font-weight: 700;
      }
      .venue-management-buttons {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .calendar-button {
          white-space: nowrap;
          // background-color: var(--primaryColor) !important;
          color: white !important;
          font-weight: 700 !important;
          font-family: "Poppins", sans-serif !important;
          height: 50px;
          padding-left: 20px;
          padding-right: 20px;

          .plus-icon-button {
            padding-left: 15px;
            color: white;
            opacity: 1 !important;
          }
        }
      }
    }
    .fc {
      .fc-header-toolbar {
        flex-direction: column;

        .fc-toolbar-chunk {
          margin-bottom: 20px;
          text-transform: capitalize !important;
          // .fc-button-group {
          // }

          .fc-button-group {
            // display: flex;
            align-items: center;
            // background-color: aquamarine;

            button {
              border: 0 !important;
              // color: black !important;
              color: white !important;
              background-color: #2c3e50 !important;
              padding-left: 5px !important;
              padding-right: 5px !important;
            }
            .fc-button-active {
              background-color: var(--primaryColor) !important;
            }
            button:focus {
              box-shadow: none !important;
            }

            h2 {
              font-size: 25px !important;
              font-weight: 800 !important;
            }
          }
        }
      }
    }
  }

  .add-player-form {
    .add-player-row {
      justify-content: space-between;
      gap: 5px;
      align-items: center;
      .add-icon {
        cursor: pointer;
      }
    }
    .add-checkbox-input {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 20px;
      background-color: #fafafa;
      padding-top: 15px;

      label {
        color: #b3b3b3;
      }

      label:hover {
        // color: #b3b3b3;
      }
    }
    .add-player-buttons {
      margin-top: 30px;
    }
    .add-new-sub-venue {
      padding-left: 20px;
      font-size: 12px;
      text-decoration: underline;
      margin-top: 5px;
      cursor: pointer;
    }

    .add-match-row {
      margin-bottom: 15px;
      position: relative;

      .date-input {
        width: 100%;
        height: 50px;
        background-color: #fafafa;

        div {
          color: #b2b2b2;
          font-weight: 700;
          padding-left: 5px;
        }

        fieldset {
          border-color: #fafafa;
        }

        .calendar-icon {
          position: absolute;
          right: 15px;
          top: 0;
          bottom: 0;
          margin-top: auto !important;
          margin-bottom: auto !important;
        }
      }

      .round-input {
        width: 100%;
        height: 50px;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 25px;
        background-color: #fafafa;
        color: #b2b2b2;
      }

      .add-team-dropdown {
        select {
          width: 100%;
          height: 50px;
          border: 0;
          font-size: 14px;
          font-weight: 700;
          padding-left: 25px;
          background-color: #fafafa;
          color: #b2b2b2;

          option {
            padding-left: 25px;
            background-color: #fafafa;
            color: #666666;
            font-size: 13px;
          }
        }
      }
    }
  }
}
