.userNameRelationship {
    .relationship {
        color: #b2b2b2;
    }
}

.searchDiv {
    display: flex;
    align-items: flex-end;
    gap: 30px;

    .searchVideo {
        width: 540px;
    }
}