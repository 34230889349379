.stepTitle {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 16px;
}

.editorContainer {
  margin-bottom: 20px;
  margin-top: 20px;
}

.teamsContainer {
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .teamContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

.btnsContainer {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.documentTypeContainer {
  margin: 20px 0;
  .title {
    font-weight: 700;
    font-size: 13px;
    color: #b2b2b2;
    margin-bottom: 8px;
  }
  .documentTypeList {
    display: flex;
    gap: 20px;
  }
}

.documentActiveLabel {
  background-color: rgb(0 219 73 / 60%);
  border-radius: 20px;
}

.documentArchivedLabel {
  background-color: rgb(0 170 255 / 60%);
  border-radius: 20px;
}

.relationship {
  font-size: 12px;
  color: #b2b2b2;
}

.customCell {
  border-bottom: 1px solid #e0e0e0 !important;
}

.addPlayerToDocument {
  .teamsContainer {
    margin-bottom: 30px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .teamContainer {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}

.searchDiv {
  display: flex;
  align-items: flex-end;
  gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .searchVideo {
    width: 470px;
  }
}

.selectDiv {
  min-width: 200px;
}

@media only screen and (max-width: 991px) {
  .searchDiv {
    gap: 10px;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    .searchComponent {
      width: 100%;
      .searchVideo {
        width: 100%;
      }
    }
    .fullWidth,
    .selectDiv {
      width: 100%;
    }
  }
}

.variablesContainer {
  margin-top: 20px;
  .variablesHeader,
  .variableRow {
    display: flex;
    align-items: center;
    .actionColumn,
    .variableColumn,
    .meaningColumn {
      display: flex;
      align-items: center;
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: bold;
      color: #b2b2b2;
      margin: 0;
      cursor: pointer;
    }
    .actionColumn {
      justify-content: center;
    }
  }
}

.variableRow {
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
}

.variableColumn {
  flex: 1;
  padding-right: 10px;
}

.meaningColumn {
  flex: 2;
  padding-right: 10px;
}

.actionColumn {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyIcon {
  cursor: pointer;
  color: #007bff;

  &:hover {
    color: #0056b3;
  }
}
