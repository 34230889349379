.inactivePlayer {
  color: black;
  filter: opacity(0.85);
}

.playerRow {
  border-bottom: 1px solid #ffffff;
  .reasonText {
    font-size: 11px;
    color: red;
    font-weight: 700;
    margin-top: 8px;
  }

  .notPaymentMonth {
    background-color: white;
    color: black !important;
  }

  .paidMonth {
    background-color: #3fc380e6;
    color: white !important;
  }

  .notPaidMonth {
    background-color: #f22613e6;
    color: white !important;
  }

  .monthCell {
    text-wrap: nowrap;

    .paymentType {
      font-size: 10px;
      font-weight: 500;
    }
  }
}

.playerRow:hover {
  border-bottom: 1px solid white;
  background-color: #eeeeee !important;
}

.searchDiv {
  display: flex;
  align-items: flex-end;
  gap: 30px;

  .leftContainer {
    display: flex;
    align-items: flex-end;
    gap: 32px;
  }

  .rightContainer {
    display: flex;
    // flex-direction: column;
    gap: 32px;
    justify-content: flex-end;
    align-items: flex-end;
    .buttonsContainer {
      display: flex;
      gap: 16px;
    }
    .rangeSliderLabel {
      color: #b2b2b2;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      margin-bottom: 12px;
    }
  }

  .searchVideo {
    width: 540px;
  }
}
