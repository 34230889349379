.tfbDatePickerContainer {
  position: relative;
  .tfbDatePikerIcon {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.tfbTimePicker {
  background-color: #fafafa;
  flex-direction: initial !important;
  display: block !important;

  div {
    color: #b2b2b2;
    font-size: 14px;
    font-weight: 700;
    padding-left: 5px;
    height: 48px;
  }

  fieldset {
    border-color: #fafafa;
  }
}

.tfbTimeGroup {
  width: 100%;
}

.timeLabel {
  color: #b2b2b2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
}
