.categories-container {
  padding: 50px;

 
  .categories-header {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

    .categories-title {
      font-size: 20px;
      font-weight: 700;
    }

    .categories-interactive {
      .add-categories-button {
        background-color: var(--primaryColor) !important;
        color: white !important;
        font-family: "Poppins", sans-serif !important;
        font-weight: 700;
        height: 50px;
      }
    }
  }

  .categories-list {
    .no-categories-call-up-message {
      text-align: center;
      .no-categories-call-up-message-text {
        font-size: 15px;
        font-weight: 600;
        padding-top: 5px;
      }
    }
    .category-delete-button {
      background-color: red !important;
    }
    .desktop-header {
      display: flex;
    }

    .categories-row-table-header {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 15px;
    }

    .categories-row {
      height: 55px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: #666666;
      padding-left: 25px;
      padding-right: 25px;

      .content-row {
        width: 100%;
        display: flex;
        align-items: center;

        .photo-column {
          img {
            max-width: 50px;
            max-height: 50px;
          }
        }

        .actions-column {
          display: flex;
          justify-content: center;
          gap: 10px;
        }
      }
    }
  }

  .actions-column,
  .photo-column {
    width: 10%;
  }

  .name-column {
    width: 80%;
  }

  .name-column,
  .actions-column {
    text-align: center;
  }
}

@media only screen and (max-width: 992px) {
  .categories-container {
    padding: 10px;

    .desktop-header {
      display: none !important;
    }
    .categories-list {
      .categories-row {
        height: auto;

        .content-row {
          display: none;
        }
      }
    }
  }
}

.mobile-listing {
  display: none;

  @media only screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    gap: 20px;

    .mobile-list-item {
      display: flex;

      div {
        text-align: left;
        width: 100%;
        img {
          max-width: 50px;
          max-height: 50px;
        }

        button {
          margin-right: 10px;
        }
      }
    }
  }
}

.clickable-text {
  cursor: pointer;
}