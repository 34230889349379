@media(max-width: 1400px){
  .fans-dashboard-filters{
    flex-direction: column;
    align-items: flex-start !important;
  }
}

@media(max-width: 767px){
  .fans-dashboard-filters{
    height: auto !important;
    margin-bottom: 30px !important;
      .filters-container{
        flex-direction: column !important;
          .fans-dashboard-social-dropdown{
            margin-right: 0px !important; 
            margin-bottom: 10px;
          }

          .fans-dashboard-year-dropdown{
            margin-bottom: 10px;
          }

          .connect-account-button{
            margin-left: 0px !important;
          }
      }
  }

  .dashboard-box-container{
    .clicks-stat-box{
      flex-direction: column;
      align-content: center;
      justify-content: center;
        img{
          margin-right: 0px !important;
          margin-bottom: 20px;
        }
    }

    .stats-row{
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
        .stat-column{
          margin-bottom: 20px;
          width: 100% !important;
        }
    }
  }
}

.dashboard-box-container {
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 50px;
  padding-right: 50px;

  .dashboard-box-title {
    font-size: 20px;
    font-weight: 700;
    color: black;
    margin-bottom: 60px;
    display: flex;
    align-items: center;

    .info-button {
      background-color: var(--primaryColor);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
      height: 20px;
      width: 20px;
      border-radius: 50px;
      font-size: 13px;
      font-weight: 700;
      color: white;
    }
  }

  //Facebook page interactions

  .facebook-total-likes-number {
    color: var(--primaryColor);
    font-weight: 700;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 25px;
  }
  .facebook-total-likes-text {
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    color: #666666;
    margin-bottom: 65px;
  }
  .stats-row {
    display: flex;
    justify-content: space-between;

    .stat-column {
      width: 48%;

      .stat-column-title {
        font-size: 16px;
        font-weight: 700;
        color: black;
        margin-bottom: 25px;
        height: 24px;
      }
      .stat-row {
        height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14px;
        font-weight: 700;

        .stat-name {
          color: #666666;
          display: flex;
          align-items: center;

          img {
            height: 20px;
            margin-right: 10px;
          }
        }
        .stat-value {
          color: var(--primaryColor);
        }
      }
    }
  }

  //Facebook total clicks

  .clicks-stat-box {
    background-color: #fafafa;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 350px;

    img {
      height: 100px;
      margin-right: 170px;
    }

    .clicks-stat-number {
      color: var(--primaryColor);
      font-weight: 700;
      font-size: 50px;
      line-height: 50px;
      margin-bottom: 25px;
      text-align: center;
    }

    .clicks-stat-text {
      color: #666666;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
    }
  }

  .param-value-box {
    background-color: #fafafa;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 350px;
  }

  .comparison-content-container {
    .image-container {
      width: 45px;
      height: 65px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
    }
    img {
      width: 100%;

      display: block;
    }

    .parameter-name {
      color: #000;
      font-weight: 700;
      margin-bottom: 45px;
      text-align: center;
      font-size: 16px;
    }

    .parameter-value {
      color: var(--primaryColor);
      font-weight: 700;
      text-align: center;
      font-size: 30px;
    }
  }
}

.fans-dashboard-filters {
  margin-bottom: 50px;
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;

  .fans-dashboard-title {
    font-weight: 700;
    font-size: 25px;
    font-weight: 700;
    color: black;
  }

  .filters-container {
    display: flex;
  }

  .fans-dashboard-social-dropdown {
    display: flex !important;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    height: 50px;
    min-width: 200px !important;

    .divider.default.text {
      color: white !important;
    }

    i {
      top: 36% !important;
      color: white !important;
    }

    .divider.text {
      color: white !important;
    }
  }

  .insta-bg {
    background: #f09433 !important;
    background: -moz-linear-gradient(
      90deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    ) !important;
    background: -webkit-linear-gradient(
      90deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    ) !important;
    background: linear-gradient(
      90deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    ) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 ) !important;
  }

  .fans-dashboard-year-dropdown {
    display: flex !important;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    height: 50px;
    min-width: 200px !important;

    .divider.default.text {
      color: white !important;
    }

    i {
      top: 36% !important;
      color: white !important;
    }

    .divider.text {
      color: white !important;
    }
  }
}

.connect-account-button {
  font-size: 13px !important;
  font-weight: 700 !important;
  color: #666666;
  height: 50px;
  font-family: "Poppins", sans-serif !important;
  background-color: #d4d4d4;
  margin-left: 15px !important;
}

.no-networks-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../../assets/icons/fans-dashboard/blur.png");

  .no-networks-button {
    width: 25vw;
    height: 80px;
    background-color: var(--primaryColor);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 700;
    color: white;
    padding-left: 40px;
    padding-right: 40px;
    cursor: pointer;
  }
}

.youtube-video-row-table-header {
  font-size: 13px;
  font-weight: 700;
  color: #b2b2b2;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.youtube-video-row {
  width: 100%;
  height: 110px;
  align-items: center;
  display: flex;
  font-weight: 700;
  margin-bottom: 10px;
  background-color: #fafafa;

  .thumbnail-container {
    width: 175px;
    height: 100%;
    margin-right: 30px;

    .video-thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .video-details {
    max-width: 390px;
    .video-name {
      font-size: 16px;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 5px;
    }
    .video-stats-row {
      display: flex;
      align-items: center;

      .video-stat {
        display: flex;
        align-items: center;
        margin-right: 25px;
        font-size: 13px;

        .stat-icon {
          margin-right: 10px;
        }

        .stat-value {
          color: var(--primaryColor);
          margin-right: 5px;
        }
        .stat-name {
          color: #b2b2b2;
        }
      }
    }
  }

  .redirect-button {
    height: 35px;
    width: 35px;
    border-radius: 5px;
    background-color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 35px;
    cursor: pointer;

    img {
      height: 15px;
    }
  }
}
