@media (max-width: 479px) {
  .top-radius {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    .on-loan-players {
      padding-top: 5px;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 700;
      color: #666666;
      .MuiCheckbox-colorSecondary.Mui-checked {
        color: var(--primaryColor) !important;
      }
    }
  }
}
@media (min-width: 479px) {
  .top-radius {
    display: flex;
    flex-direction: row;
    align-items: center;
    .on-loan-players {
      padding-top: 30px;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 700;
      color: #666666;
      .MuiCheckbox-colorSecondary.Mui-checked {
        color: var(--primaryColor) !important;
      }
    }
  }
}
