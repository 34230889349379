@media (max-width: 991px) {
  .col-xxl-6 {
    // padding-right: 0px !important;
    // padding-left: 0px !important;
  }

  .injuries-list > .columns-names-injuries {
    display: none !important;
  }

  .injuries-active-container .injuries-active-content .injury-row.columns-names-injuries {
    display: flex !important;
    flex-direction: column;
    width: 40%;
  }

  .injuries-active-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .injuries-active-container .injuries-active-content {
    display: flex;
    flex-direction: row;
  }

  .injuries-active-container .injuries-active-content .injury-row {
    flex-direction: column;
    width: 60%;
    height: 100% !important;
    .injury-estimated-recovery {
      display: flex;
      align-content: center;
      align-items: center;
      height: 31px;
    }
  }

  .injuries-active-container {
    .injury-player-name {
      width: 100% !important;
      // height: 40px;
      padding-left: 0px !important;
    }

    .injury-type,
    .injury-date,
    .injury-estimated-recovery {
      width: 100% !important;
      text-align: left !important;
    }

    .injury-options {
      height: 48px !important;
      width: 100% !important;
      justify-content: flex-end !important;
      margin-right: 20px;
    }
  }

  .injuries-list {
    .injuries-active-container {
      border-top: 1px solid #d2d2d2;
      // border-bottom: 1px solid #d2d2d2;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      // margin-bottom: 20px;
    }

    //.injury-row{
    //padding-left: 0px !important;
    //padding: 15px 5px 5px 10px !important;
    //}

    .injury-row,
    .columns-names-injuries {
      padding: 10px 10px;
    }
  }
}

@media (max-width: 767px) {
  .injuries-container-a {
    padding: 0px !important;
  }

  .edit-injury-popup-all {
    width: 100% !important;
    padding: 0 10px !important;
    .end-injury-button-container {
      .end-injury-button {
        width: 100%;
      }
    }
  }

  .injuries-container-a .injuries-header {
    flex-direction: column;
    padding: 10px;
    align-items: flex-start !important;
    margin-bottom: 0px !important;
    .injuries-title {
      padding-bottom: 10px;
    }

    .add-injury-header-button {
      width: 100%;
    }
  }

  .add-injury-form-container {
    width: 100% !important;
    .injury-edit-delete-buttons {
      .add-treatment-button {
        width: 100%;
      }
    }
  }
}

.injuries-active-container .columns-names-injuries {
  display: flex;
}

.injuries-active-container .columns-names-injuries {
  display: none !important;
}

.injuries-container-a {
  padding: 45px;
  .injuries-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .injuries-title {
      font-size: 20px;
      font-weight: 700;
    }
    .add-injury-header-button {
      height: 50px;
      white-space: nowrap;
    }
  }

  .injuries-list {
    .columns-names-injuries {
      color: #b2b2b2 !important;
    }
    .injury-row {
      display: flex;
      align-items: center;
      height: 55px;
      font-weight: 700;
      color: #666666;
      .injury-player-name {
        width: 25%;
        padding-left: 10px;
      }
      .injury-type {
        width: 25%;
        text-align: center;
      }
      .injury-date {
        width: 20%;
        text-align: center;
      }
      .injury-estimated-recovery {
        width: 20%;
        text-align: center;
      }
      .injury-options {
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;

        .injury-see-more-button {
          background-color: #666666;
          width: 35px;
          height: 35px;
          padding: 7px;
          border-radius: 5px;
          cursor: pointer;
        }

        .end-injury-icon {
          color: black;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

.edit-injury-popup-all {
  width: 25vw;

  .injury-date-input {
    width: 100%;
    height: 50px;
    background-color: #fafafa;
    margin-bottom: 15px;

    div {
      color: #b2b2b2;
      font-weight: 700;
      padding-left: 5px;
    }

    fieldset {
      border-color: #fafafa;
    }
  }

  .end-injury-button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    .end-injury-button {
      background-color: #00d948 !important;
      font-family: "Poppins", sans-serif !important;
      height: 50px;
      color: white !important;
    }
  }
}

.end-injury-error-row {
  text-align: center;
  color: red;
  font-weight: 600;
  margin-bottom: 15px;
}

@media only screen and (max-width: 1520px) {
  .current-injuries-container {
    margin-bottom: 20px;
  }
}
