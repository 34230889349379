.add-users-notifications-container {
  .sub-header {
    margin-top: 20px;
  }

  .group-select-filter {
    margin-top: 40px;
    display: flex;
    align-items: flex-end;
    gap: 25px;

    .custom-select-wrapper {
      width: 380px !important;
    }

    .tfb-button-custom {
      height: 50px;
    }

    .apply-filter {
      width: 20%;
    }

    .cancel-filter {
      width: 20%;
    }
  }

  .users-table {
    margin-top: 40px;

    .header-checkbox {
      display: flex;
      align-items: center;
    }

    .row-checkbox-player {
      display: flex;
      align-items: center;

      .user-details {
        display: flex;
        flex-direction: column;
      }

      .relationship {
        color: #b2b2b2;
      }
    }
  }

  .message {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .add-users-notifications-container {
    .group-select-filter {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 25px;

      .custom-select-wrapper {
        width: 100% !important;
      }

      .tfb-button-custom {
        height: 50px;
      }

      .apply-filter {
        width: 100%;
      }

      .cancel-filter {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 575px) {
  .action-buttons-add-notification {
    .tfb-button-custom {
      height: 50px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .add-users-notifications-container {
    .tfb-button-custom {
      height: 100% !important;
    }
  }
}
