@media(max-width: 991px){
  .fans-list-container .fans-list>.fans-list-table-header{
    display: none !important;
  }

  .fans-list-container .fans-list .fans-list-table-container .fans-list-table-header{
    display: flex !important;
  }

  .fans-list-table-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 1px solid #d2d2d2;
    // border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    // margin-bottom: 20px;
      .fans-list-table-content{
        display: flex;
        flex-direction: row;
      }

       .fan-row{
        display: flex;
        flex-direction: column !important;
        width: 50%;
        height: 100% !important;
        padding-left: 0px !important;
        padding-top: 10px;

          .name, .email, .phone, .age, .options{
            width: 100% !important;
            text-align: left !important;
          }

          .options{
            height: 31px;
            margin-bottom: 20px;
            justify-content: flex-end !important;
          }
      }

      .fans-list-table-header{
        padding-top: 10px;
        flex-direction: column;
        width: 50%;
          .name, .email, .phone, .age, .options{
            width: 100% !important;
            text-align: left !important;
          }
      }
  }

  @media(max-width: 767px){
    .fans-list-container {
      .fans-list-second-header{
        flex-direction: column;
        align-items: flex-start !important;
          .search-field{
            margin-bottom: 20px;
            margin-left: 10px;
          }
  
          .right-side{
            flex-direction: column;
            align-items: flex-start !important;
            margin-left: 10px;
              .period-filter:first-child{
                margin-bottom: 20px;
              }
          }
      }

      .fans-list-header{
        flex-direction: column;
        align-items: flex-start !important;
        margin: 20px 10px;
      }

      .filter-popup-container{
        width: 100% !important;
      }

    }

    .fans-list-container{
      padding: 0px !important;
    }



    // .fans-list-table-header{
    //   .name{
    //     height: 40px;
    //   }

    //   .email{
    //     height: 44px;
    //   }
    // }


  }
}

.fans-list-container .fans-list .fans-list-table-container .fans-list-table-header{
  display: none;
} 

.fans-list-container {
  padding: 50px;
  .fans-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 700;

    .total {
      margin-right: 20px;
      display: flex;
      align-items: center;
    }
  }

  .fans-list-second-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    .right-side {
      display: flex;
      align-items: center;
    }
  }

  .fans-list {
    .fans-list-table-header {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
    }

    .fan-row {
      height: 55px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #666666;
      font-weight: 700;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
    }

    .fan-row-checkbox {
      position: absolute;
      left: -30px;
    }

    .name {
      width: 30%;
    }
    .email {
      width: 20%;
      text-align: center;
    }
    .phone {
      width: 20%;
      text-align: center;
    }
    .age {
      width: 20%;
      text-align: center;
    }
    .options {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: center;

      .eye-icon-container {
        background-color: #666666;
        width: 35px;
        height: 35px;
        padding: 7px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  .pagination-container {
    .dropdown-ppp {
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;

      .dropdown-label {
        margin-right: 20px;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}

.filter-popup-container {
  width: 20vw;

  .filter-popup-title {
    font-size: 18px;
    font-weight: 700;
    color: black;
    margin-bottom: 25px;
  }

  .filter-popup-row {
    width: 100%;
    margin-bottom: 15px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 700;

    .row-label {
      color: #b2b2b2;
      width: 30%;
    }

    .date-dropdown {
      background-color: #666666 !important;
      border: 0 !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      padding-right: 20px !important;
      padding-left: 20px !important;
      font-weight: 700;
      height: 45px;
      min-width: 0 !important;

      div {
        color: white !important;
        font-size: 14px !important;
      }

      i {
        margin: 0 !important;
      }

      div.visible.menu.transition > div {
        background-color: #666666 !important;
        font-weight: 600;
      }
    }
  }

  .filter-popup-buttons-row {
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-top: 25px;
  }
}

.period-filter {
  height: 50px;
  min-width: 150px !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 13px !important;
  color: white !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  .period-filter-icon {
    color: white;
    opacity: 1 !important;
  }
}

.merge-profiles-container {
  margin-bottom: 50px;
  margin-top: 50px;

  .merge-row-1 {
    display: flex;
    justify-content: space-evenly;
  }
  .merge-row-2 {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .profile-box {
    width: 23%;
    height: 200px;
    background-color: #fafafa;
    padding: 25px;
    border-radius: 3px;

    .title {
      font-size: 18px;
      font-weight: 700;
      color: #666666;
      margin-bottom: 25px;
    }

    .profile-box-row {
      width: 100%;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;

      .row-label {
        width: 40%;
        color: #b2b2b2;
      }

      .row-value {
        width: 60%;
        color: #666666;
      }
    }
  }

  .edit-result-input {
    width: 100%;
    height: 30px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    padding-left: 15px;
    background-color: white;
    color: #666666;
  }

  .edit-result-date-input-wrapper {
    position: relative;
    display: flex;

    .edit-date-input {
      width: 100%;
      height: 100%;
      background-color: white;

      div {
        color: #b2b2b2;
        font-weight: 700;
        padding-left: 20px;
        height: 100%;
      }
      ::before {
        border-bottom: 0 !important;
      }

      ::after {
        border-bottom: 0;
      }

      fieldset {
        border-color: #fafafa;
      }
    }

    .calendar-icon {
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
