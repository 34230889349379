.sectionsContainer {
    display: flex;
    gap: 50px;
    .container {
        .containerTitle {
            font-family: "Poppins";
            font-weight: 700;
            font-size: 16px;
        }

        .containerContent {
            margin-top: 40px;
            .formationsList {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;
                width: 454px;
            }

            .playersList {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                width: 440px;
            }
        }
    }
}

.formationContainer {
    height: 130px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex: 0 0 120px;
    border-radius: 3px;
    background: #fafafa;
    border: 2px solid #fafafa;
    cursor: pointer;

    .formationTitle {
        color: #b2b2b2;
        font-size: 13px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        line-height: 9px;
    }
}

.selectedFormation {
    border: 2px solid #00d948 !important;
}

.pitchContainerImg {
    width: 440px;
    height: 690px;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 20px;

    .formationRow {
        height: 16.66%;
        display: flex;

        .playerRow {
            width: 20%;
            display: flex;
            justify-content: center;
            padding-top: 30px;

            .player {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 7.5px;

                .shirtContainer {
                    cursor: pointer;
                    width: 45px;
                    height: 46px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    .playerNumber {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 700;
                        line-height: 13px;
                        font-size: 12px;
                        text-align: center;
                        padding-top: 10px;
                    }
                }

                .playerName {
                    color: black;
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 700;
                    line-height: 11px;
                    font-size: 11px;
                    text-align: center;
                }
            }
        }
    }
}

.playerContainer {
    padding: 20px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    background: #fafafa;
    border: 2px solid #fafafa;
    border-radius: 3px;
    cursor: pointer;

    width: calc(50% - 5px);
}

.matchformationName {
    font-size: 12px;
}

.addEvent {
    .halfTypeDiv {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
}

.eventDiv {
    align-items: center;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    .eventData {
        align-items: center;
        display: flex;
        gap: 20px;
        .verticalBar {
            height: 20px;
            width: 1px;
            background-color: #e6e6e6;
        }
        .eventFirstPart {
            align-items: center;
            display: flex;
            gap: 10px;
            .eventSvg,
            .eventAssistSvg {
                img {
                    height: 20px;
                    width: auto;
                }
            }
        }
    }
    .deleteEvent {
        display: none;
    }
}

.awayEvent {
    flex-direction: row-reverse;
    .eventData {
        flex-direction: row-reverse;
        .eventFirstPart {
            flex-direction: row-reverse;
        }
    }
}

.eventRow:hover {
    background-color: rgb(0 217 72 / 10%) !important;

    .deleteEvent {
        display: block;
        cursor: pointer;
    }
    .eventData {
        .eventFirstPart {
            .player:hover,
            .playerAssist:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}

.lineupList {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .listColumn {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .lineupRowContainer {
            padding: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;

            .lineupData {
                display: flex;
                align-items: center;
                gap: 16px;
                flex-wrap: wrap;

                .number {
                    color: var(--primaryColor);
                    font-weight: 700;
                    font-family: Poppins;
                    font-size: 14px;
                }

                .player {
                    font-family: Poppins;
                    font-size: 14px;
                    color: #666;
                    font-weight: 700;

                    .minutes {
                        color: black;
                    }

                    .role {
                        color: var(--primaryColor);
                        font-size: 10px;
                        font-weight: 700;
                    }
                }
            }

            .lineupAction {
                display: none;
                align-items: center;
                gap: 20px;

                .eventIcon {
                    display: block;
                    cursor: pointer;
                }
            }
        }

        .staffDiv {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
}

.lineupRowContainer:hover {
    background-color: rgb(0 217 72 / 10%) !important;

    .lineupData {
        .player:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .lineupAction {
        display: flex !important;
    }
}

@media only screen and (max-width: 570px) {
    .sectionsContainer {
        flex-direction: column;
        .container {
            .containerContent {
                .formationsList {
                    width: 100%;
                    justify-content: space-evenly;
                }

                .playersList {
                    width: 100%;
                    flex-direction: column;
                }
            }
        }
    }

    .pitchContainerImg {
        width: 100%;
        height: auto;
        background-repeat: round;
        background-size: initial;

        .playerContainer {
            width: 100%;
        }
    }
    .playerContainer {
        width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .lineupList {
        .listColumn {
            .lineupRowContainer {
                .lineupAction {
                    display: flex;
                }
            }
        }
    }
}
