.notificationMessage {
    margin: 30px 0;

    .label {
        color: #b2b2b2;
        font-family: "Poppins";
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .text {
        color: #000;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
    }

    .boldText {
        color: #666;
    }
}

.selectGroup {
    margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {
    .selectGroup {
        flex-direction: column;
    }
}
