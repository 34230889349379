//Reusable

.add-report-button {
  background-color: var(--primaryColor) !important;
  color: white !important;
  height: 50px;
  font-size: 13px !important;
  display: flex !important;
  font-family: "Poppins", sans-serif !important;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;

  .add-report-button-icon {
    color: white;
    opacity: 1 !important;
  }
}
.reports-placeholder {
  margin-top: 20px;
  text-align: center;

  .reports-placeholder-text {
    margin-top: 10px;
    font-size: 14px;
    padding: 15px;
  }
}

.report-page-players-container {
  padding: 50px;
  .report-page-players-list-header {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .report-page-players-list {
    .player-row {
      display: flex;
      font-size: 14px;
      font-weight: 700;
      height: 80px;
      align-items: center;
      color: #666666;
      .name {
        width: 36%;
        display: flex;
        align-items: center;
        padding-left: 10px;
      }
      .grade {
        width: 50%;
        text-align: center;
      }
      .see-more {
        width: 20%;
        text-align: -webkit-right;
        padding-right: 10px;

        .report-player-see-more-button {
          background-color: #666666;
          width: 35px;
          height: 35px;
          padding: 7px;
          border-radius: 5px;
          cursor: pointer;

          .download-icon {
            color: white;
          }
        }
      }
    }

    .report-column-titles {
      color: #b3b3b3 !important;
    }
  }
}
.test-header {
  margin-bottom: 40px;
  .test-name {
    font-size: 25px;
    font-weight: 700;
  }

  .test-evaluator {
    color: #b2b2b2;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    margin-top: 5px;
  }
}

.report-parameters-list {
  padding: 15px;

  .parameters-list-title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .parameters-list {
    .parameter-row {
      display: flex;
      height: 50px;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: #666666;

      .name {
        width: 60%;
        padding-left: 20px;
      }
      .um {
        width: 40%;
        text-align: center;
      }

      .expand-more-arrow {
        img {
          height: 20px;
          cursor: pointer;
        }
      }
    }

    .param-list-column-titles {
      color: #b3b3b3 !important;
    }
  }
}

.technical-parameter-row {
  display: flex;
  font-size: 13px;
  padding-left: 40px;
  height: 50px;
  font-weight: 600;
  align-items: center;

  .technical-parameter-name {
  }

  .technical-parameter-detail {
    margin-left: 10px;
    text-transform: lowercase;
  }
}

.delete-category-popup-container {
  .delete-category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .delete-category-title {
      font-size: 20px;
      font-weight: 700;
    }

    .close {
      cursor: pointer;
    }
  }

  .delete-category-text {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  .delete-category-buttons {
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 20px;
    .cancel-button {
      color: #fafafa !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      background-color: #666666 !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 47%;
    }
    .delete-comp-button {
      background-color: #db2828 !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: #fafafa !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 47%;
    }
  }
}

@media (min-width: 991px) {
  .report-display-mobile {
    display: none;
  }
  .reports-page-container {
    .report-list-container {
      padding: 45px;
      .report-list-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        align-items: center;

        .report-category-name {
          font-size: 20px;
          font-weight: 700;
        }
        .header-buttons {
          display: flex;
        }
      }

      .reports-list {
        .report-row {
          display: flex;
          font-weight: 700;
          padding: 15px;
          // height: 55px;
          align-items: center;
          color: #797979;
          .name {
            width: 36%;
            // padding-left: 10px;
          }
          .report-row-column {
            width: 18%;
            text-align: center;
          }
          .options-button {
            width: 10%;
            text-align: -webkit-right;
            padding-right: 10px;

            .report-see-more-button {
              background-color: #666666;
              width: 35px;
              height: 35px;
              padding: 7px;
              border-radius: 5px;
              cursor: pointer;
            }
          }

          .report-wrapper-name {
            width: 36%;
            .name {
              width: 70%;
            }
          }

          .report-wrapper-team {
            width: 18%;
            .report-row-column {
              width: 100%;
            }
          }

          .report-wrapper-evaluation-year {
            width: 18%;
            .report-row-column {
              width: 100%;
            }
          }

          .report-wrapper-evaluation-date {
            width: 18%;
            .report-evaluation-date {
              width: 100%;
              text-align: center;
            }
          }
          .options-button {
            width: 10%;
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: flex-end;

            .trash-hover:hover {
              background-color: #d91200;
              cursor: pointer;
            }

            .evaluations-delete-button {
              background-color: #666666;
              width: 35px;
              height: 35px;
              padding: 7px;
              border-radius: 5px;
              cursor: pointer;

              .delete-icon {
                color: white;
                position: relative;
                top: 1px;
                left: 0px;
              }
            }

            .evaluations-edit-button {
              background-color: #666666;
              width: 35px;
              height: 35px;
              padding: 7px;
              border-radius: 5px;
              cursor: pointer;

              .edit-icon {
                color: white;
                position: relative;
                left: 2px;
              }
            }
          }
        }

        .list-column-names {
          color: #b3b3b3 !important;
          font-weight: 600 !important;
        }
      }
    }
  }

  .reports-dropdown {
    background-color: #666666 !important;
    display: flex !important;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    height: 50px;
    min-width: 200px !important;
    margin-right: 20px;
    color: white !important;

    .divider.default.text {
      color: white !important;
    }

    i {
      top: 30% !important;
      color: white;
    }

    .divider.text {
      color: white !important;
    }
  }
}

@media (max-width: 991px) {
  .report-page-players-container {
    padding: 10px;
    .report-page-players-list-header {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .report-page-players-list {
      .player-row {
        display: flex;
        font-size: 14px;
        font-weight: 700;
        height: 80px;
        align-items: center;
        color: #666666;
        align-items: center;
        .name {
          width: 36%;
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
        .grade {
          width: 50%;
          text-align: center;
        }
        .see-more {
          width: 20%;
          text-align: -webkit-right;
          padding-right: 10px;

          .report-player-see-more-button {
            background-color: #666666;
            width: 35px;
            height: 35px;
            padding: 7px;
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }

      .report-column-titles {
        color: #b3b3b3 !important;
      }
    }
  }

  .display-flex {
    display: flex;
  }

  .report-evaluation-date {
    width: 100%;
  }
  .reports-page-container {
    .report-list-container {
      padding: 25px 0px 20px 0px;
      .report-list-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 40px;
        align-items: baseline;

        .report-category-name {
          font-size: 20px;
          font-weight: 700;
        }
        .header-buttons {
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;
        }
      }

      .reports-list {
        .report-row {
          display: flex;
          font-weight: 700;
          // height: 55px;
          align-items: center;
          color: #797979;
          flex-direction: column;

          .report-mobile-row {
            display: flex;
            justify-content: flex-start;
            // margin-bottom: 5px;
            margin-left: 5px;
            margin-top: 10px;
            width: 100%;
          }

          .name {
            width: 100%;
            // padding-left: 10px;
          }
          .report-row-column {
            width: 100%;
            // text-align: center;
          }
          .options-button {
            width: 100%;
            text-align: -webkit-right;
            margin-right: 20px;
            margin-bottom: 10px;

            .report-see-more-button {
              background-color: #666666;
              width: 35px;
              height: 35px;
              padding: 7px;
              border-radius: 5px;
              cursor: pointer;
            }
          }
        }

        .list-column-names {
          color: #b3b3b3 !important;
          font-weight: 600 !important;
        }
      }
    }
  }

  .reports-dropdown {
    background-color: #666666 !important;
    display: flex !important;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    height: 50px;
    min-width: 200px !important;
    // margin-right: 20px;
    color: white !important;

    .divider.default.text {
      color: white !important;
    }

    i {
      top: 30% !important;
      color: white;
    }

    .divider.text {
      color: white !important;
    }
  }
  .reports-list {
    .report-display-desktop {
      display: none !important;
    }
  }
}

.display-flex {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
