.about-us-container {
  padding: 50px;
  .about-us-header {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    .about-us-title {
      font-size: 20px;
      color: black;
      font-weight: 700;
    }
  }
  .image-input-container {
    display: flex;
    height: 50px;
    align-items: center;
    background-color: #fafafa;
    justify-content: space-between;
    padding-right: 20px;
    width: 100%;

    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
      overflow: visible;
    }

    label {
      font-weight: 700;
      font-size: 14px;
      color: #b2b2b2;
      padding-left: 20px;
      cursor: pointer;
      margin-bottom: 0 !important;
    }
  }

  .sections-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 25px;
    margin-top: 50px;
  }

  .about-us-sections-list {
    margin-bottom: 30px;
    .about-us-section {
      display: flex;
      align-items: center;
      height: 50px;
      font-weight: 700;
      font-size: 14px;
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 15px;
      color: #666666;

      .about-us-section-type {
        width: 20%;
      }
      .about-us-section-preview {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 65%;
      }
      .about-us-section-options {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 15%;

        img {
          width: 12px;
          cursor: pointer;
        }

        .news-option-icon {
          color: #666666;
        }
      }
    }
  }

  .add-news-image-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    height: 115px;

    .add-news-image-preview {
      width: 47%;
      background-color: #fafafa;

      img {
        width: 100%;
        object-fit: cover;
        max-height: 100%;
      }
    }

    .add-news-image-right-side {
      width: 47%;
    }
  }

  .element-to-add-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 15px;
  }

  .text-area-about-us {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding: 10px;
    padding-left: 20px;
    border: 0;
    background-color: #fafafa;
  }

  .add-options-buttons {
    display: flex;

    justify-content: space-between;
    flex-flow: row wrap;

    .about-us-button {
      width: 48% !important;
      font-size: 13px !important;
      height: 50px;
      display: flex;
      justify-content: space-between;
    }
  }

  .about-us-final-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }

  .about-us-button {
    background-color: var(--primaryColor) !important;
    height: 50px;
    font-family: "Poppins", sans-serif !important;
    font-size: 13px !important;
    color: white !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;

    .about-us-button-icon {
      color: white;
      opacity: 1 !important;
    }
    .about-us-button-icon-dark {
      color: #666666;
      opacity: 1 !important;
    }
  }

  .dark-background {
    background-color: #666666 !important;
  }

  .light-background {
    background-color: #d4d4d4 !important;
    color: #666666 !important;
  }

  .about-us-preview-content {
    font-family: "HelveticaNeue";
    padding: 50px;
    background-color: #fafafa;
    min-height: 339px;

    .preview-main-image-container {
      margin-bottom: 50px;
      img {
        width: 100%;
      }
    }

    .preview-title {
      font-size: 38px;
      color: #000;
      font-weight: 700;
      padding-bottom: 35px;
      text-align: center;
    }

    .preview-paragraph-container {
      margin-bottom: 20px;
      color: grey;
      font-size: 10px;
      line-height: 1.75;
    }

    .preview-subtitle-container {
      font-size: 18px;
      color: #000;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: 1.143;
    }

    .preview-highlighted-container {
      font-size: 12px;
      color: #000;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: 17.8px;
      padding-left: 15px;
      border-left: solid 1px #fbeb24;
    }

    .preview-image-container {
      margin-bottom: 40px;
      img {
        width: 100%;
      }
    }
  }

  .about-us-preview-text-container {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}
