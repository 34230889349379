@media (max-width: 991px) {
  .widget {
    // margin-bottom: 5px !important;
  }

  .active-players-list > .player-row.active-players-table-columns {
    display: none !important;
  }

  .player-container .player-row.active-players-table-columns {
    display: flex !important;
  }

  .player-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .player-container {
    padding-top: 4px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
  }

  .player-content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .player-row.active-players-table-columns {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .player-row {
    display: flex;
    flex-direction: row !important;
    width: 100% !important;
    // align-items: flex-start !important;
  }

  .active-players-container .active-players-list .player-row {
    height: 100% !important;
  }

  .active-players-container {
    padding: 0px !important;
    .active-players-header {
      padding: 0 10px;
      margin-bottom: 5px !important;
    }
    .active-players-list {
      margin-top: 15px;
      .player-container {
        border-top: 1px solid #d2d2d2;
        // border-bottom: 1px solid #d2d2d2;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        // margin-bottom: 20px;
      }

      .player-row {
        padding-left: 0px !important;
        padding: 0px 5px 5px 10px !important;
        .player-name {
          width: 100% !important;
        }

        .player-age {
          width: 100% !important;
          text-align: left !important;
          span.player-dob {
            display: none;
          }
        }

        .player-position,
        .player-team {
          width: 100% !important;
          text-align: left !important;
        }
      }
    }
  }

  .add-staff-wrapper {
    width: 100% !important;
  }

  .add-staff-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
    .add-staff-buttons {
      .add-button {
        width: 100%;
        .white-icon {
          padding: 0 6px;
        }
      }
    }
  }
}

@media (min-width: 991px) {
  .display-none {
    display: none;
  }

  .player-content {
    display: flex !important;
    flex-direction: row !important;
  }

  .active-players-table-columns {
    color: #b3b3b3;
    font-weight: 600;
  }
  .player-row {
    display: flex;
    // width: 100% !important;
    width: 100%;
    align-items: center;
    font-weight: 700;
    color: #666666;
    padding-left: 15px;

    .player-name {
      // width: 100% !important;
      width: 100%;

      cursor: pointer;
      .on-loan {
        font-size: 11px;
        color: var(--primaryColor);
      }
    }
    .player-position {
      width: 100% !important;
      cursor: pointer;
      text-align: center;
    }
    .player-age {
      width: 100% !important;
      cursor: pointer;
      text-align: center;
      // height: 36px !important;
    }
    .player-team {
      width: 100% !important;
      cursor: pointer;
      text-align: center;
    }
  }
}

.header-color {
  color: #b3b3b3 !important;
}

// .player-container .player-content
.player-row.active-players-table-columns {
  display: none;
}

.active-players-container {
  padding: 40px;
  .active-players-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .active-players-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .active-players-list {
    .active-players-table-columns {
      color: #b3b3b3;
      font-weight: 600;
    }
    .player-row {
      display: flex;
      width: 100%;
      min-height: 60px;
      // align-items: center;
      font-weight: 700;
      color: #666666;
      padding-left: 15px;

      .player-name {
        // width: 30%;
        width: 100%;
        cursor: pointer;
        .on-loan {
          font-size: 11px;
          color: var(--primaryColor);
        }
      }
      .player-position {
        // width: 20%;
        width: 100%;
        cursor: pointer;
        text-align: center;
      }
      .player-age {
        // width: 10%;
        width: 100%;
        cursor: pointer;
        text-align: center;
        // height: 36px !important;
      }
      .player-team {
        width: 40%;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}

.dark-background {
  background-color: #666666 !important;
}

.switch-team-player {
  // padding: 0px 40px;
  // position: relative;
  // bottom: -30px;
  // z-index: 99;
  // font-size: 13px;
  // font-weight: 700;
  // color: #666666;
  // padding: 30px 40px 0 40px;
  font-size: 13px;
  font-weight: 700;
  color: #666666;
  > span {
    cursor: pointer;
  }
  .MuiIconButton-label {
    color: var(--primaryColor) !important;
  }
  .Mui-checked + .MuiSwitch-track {
    background-color: var(--primaryColor) !important;
  }

  .MuiSwitch-root {
    height: 30px;
  }

  .MuiSwitch-switchBase {
    .MuiSwitch-thumb {
      width: 22px;
      height: 20px;
      margin: -4px;
      border-radius: 3px;
    }
  }
}

.searchable-players {
  .results.visible {
    display: none !important;
  }
  .ui.left.icon.input {
    width: 100%;
  }

  .search-component {
    input {
      background-color: #fafafa !important;
      border: 0 !important;
      border-radius: 0px !important;
      height: 50px;

      &::placeholder {
        color: black !important;
      }
    }

    .search.icon {
      color: black;
    }
  }
}

.active-players-content {
  .loader-container {
    margin-top: 50px;
  }
}

.active-players-team-div-123:hover {
  cursor: pointer;
}
