.stepTitle {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 20px;
}

.documentTypeContainer {
    margin: 20px 0;
    .title {
        font-weight: 700;
        font-size: 13px;
        color: #b2b2b2;
        margin-bottom: 8px;
    }
    .documentTypeList {
        display: flex;
        gap: 20px;
    }
}

.teamsContainer {
    margin-bottom: 30px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .teamContainer {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}
