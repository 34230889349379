.content-row {
  .team-settings-image {
    width: 16%;
    .team-image-wrapper {
      display: flex;
      justify-content: center;

      i {
        position: relative;
        // right: 5px;
        left: 5px;
        top: 18px;
      }
      i:hover {
        color: #db2828 !important;
        cursor: pointer;
      }

      .team-image-schedule:hover {
        -webkit-filter: brightness(0.5);
        filter: brightness(0.5);
        cursor: pointer;
      }
    }
  }
}

.view-team-image-dialog {
  min-width: 400px;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 26px;
  .view-team-image-dialog-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-left: 12px;
    padding-bottom: 10px;
    .view-team-image-dialog-title {
      font-size: 20px;
      line-height: 31.75px;
      font-weight: bold;
    }
  }
}

.delete-team-setting-image-popup-container {
  min-width: 400px;
  padding: 26px;

  .delete-team-setting-image-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    .delete-team-setting-image-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .delete-team-setting-text {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  .delete-team-setting-buttons {
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 20px;
    .cancel-button {
      color: #fafafa !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      background-color: #666666 !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 47%;
      i {
        color: white !important;
      }
    }

    .delete-team-setting-button {
      background-color: #db2828 !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: #fafafa !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 47%;
      i {
        color: white !important;
      }
    }
  }
}

.news-page-container {
  padding: 50px;

  .final-settings-submit-button-row {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    height: 50px;

    .final-submit-button {
      background-color: #00d948;
      height: 50px;
      width: 185px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: white !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;

      .final-submit-button-icon {
        color: white;
        opacity: 1 !important;
      }
    }
  }

  .news-page-header {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .news-page-title {
      font-size: 25px;
      font-weight: 700;
    }

    .add-news-button {
      background-color: var(--primaryColor) !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: white !important;
      display: flex;
      align-items: center;

      .add-news-button-icon {
        color: white;
        padding-left: 20px;
        opacity: 1 !important;
      }
    }
  }

  .last-news-list {
    .last-news-row-header {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;

      // .news-title {
      //   width: 20%;
      // }
      // .news-tag {
      //   width: 20%;
      //   text-align: center;
      // }
      // .team-setting-date {
      //   width: 16%;
      //   text-align: center;
      // }
      // .news-option {
      //   width: 16%;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   img {
      //     width: 13px;
      //     margin-left: 8px;
      //     margin-right: 8px;
      //     cursor: pointer;
      //   }
      // }
    }

    .last-news-row {
      display: flex;
      align-items: center;
      height: 80px;
      font-size: 14px;
      color: #666666;
      font-weight: 700;
      padding-left: 15px;
      padding-right: 15px;
    }
    .team-setting-title {
      width: 20%;
    }
    .news-tag {
      width: 20%;
      text-align: center;
    }
    .team-setting-date {
      width: 16%;
      text-align: center;
    }
    .team-settings-option {
      width: 16%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      img {
        width: 13px;
        margin-left: 8px;
        margin-right: 8px;
        cursor: pointer;
      }
    }
  }
}

//Delete popup container

// .news-options-delete-popup-container {
//   // width: 400px;
// }

.news-options-delete-popup {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .delete-message {
    font-weight: 600;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }

  .delete-buttons {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .news-options-button {
      background-color: #00d948 !important;
      height: 40px;
      font-family: "Poppins", sans-serif !important;
      font-size: 12px !important;
      color: white !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      width: 47%;

      .news-options-button-icon {
        color: white;
        padding-left: 20px;
        opacity: 1 !important;
      }
    }
    .dark-background {
      background-color: #666666 !important;
    }
    .red-button {
      background-color: red !important;
    }
  }
}

@media only screen and (max-width: 992px) {
  .news-page-container {
    .last-news-row {
      height: auto !important;
      padding: 20px !important;
    }

    .mobile-header div {
      align-items: center !important;
    }

    .news-option {
      justify-content: flex-start !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .news-page-header {
    flex-direction: column;
    align-items: flex-start !important;

    .news-page-title {
      margin-bottom: 50px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .container-fluid {
    padding: 0 !important;
  }

  .news-page-container {
    padding: 20px !important;
  }
}
