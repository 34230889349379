@media (max-width: 1465px) {
  .match-schedule-head-desktop {
    display: none !important;
  }

  .flex-column-mobile {
    flex-direction: column;

    .match-details {
      display: flex;
    }
  }

  .match-container {
    display: flex;
    width: 100%;
    // height: 120px;

    padding-top: 10px;
    // font-size: 10px;
    color: #666666;

    .match-date {
      align-items: center;
      text-align: center;
      justify-content: center;
      font-size: 12px;
      // width: 40%;
      padding-bottom: 20px;
      font-weight: 700;
    }

    .match-column-title {
      font-size: 14px;
      width: 50%;
      text-align: center;
    }

    .match-team {
      font-weight: 700;
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: uppercase;

      .team-name {
        font-size: 12px;
        // word-break: break-word;
        width: 90%;
        padding-right: 0px !important;
        padding-left: 0px !important;
      }

      .match-logo {
        padding: 5px;
        width: max-content;
        img {
          height: 40px;
        }
      }
    }

    .match-score {
      font-weight: 700;
      font-size: 16px;
      padding-top: 15px;
      width: 20%;
      text-align: center;
    }

    .match-see-more {
      // width: 20%;
      // display: flex;
      // justify-content: flex-end;
      .match-see-more-button {
        background-color: #666666;
        width: 35px;
        height: 35px;
        padding: 7px;
        border-radius: 5px;
        cursor: pointer;
      }
    }

    .match-costs {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: auto;
    }

    .match-editable-score {
      width: 10%;
      text-align: center;
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      align-items: center;

      .score-input {
        width: 40%;
      }
    }

    .icons-uneditable {
    }

    .icons-container {
      // width: calc(17% - 40px);
      display: flex;
      align-items: baseline;
      gap: 20px;
      padding-top: 20px;
      padding-bottom: 10px;
      margin-left: 30px;
      justify-content: flex-end;
    }
  }

  .matches-header {
    flex-direction: column;
    // padding: 20px;
    display: flex;
    align-items: baseline !important;
    justify-content: space-between;
    margin-bottom: 40px;

    .matches-section-title {
      font-size: 20px;
      padding-right: 21px;
      font-weight: 700;
    }

    .dark-background {
      background-color: #666666 !important;
    }

    .team-dropdown {
      // background-color: var(--primaryColor) !important;
      background-color: var(--secondaryColor) !important;
      display: flex !important;
      align-items: center;
      font-family: "Poppins", sans-serif !important;
      font-weight: 700;
      height: 50px;
      min-width: 200px !important;
      margin-right: 20px;
      color: white !important;
      width: 100%;

      .divider.default.text {
        color: white !important;
      }

      i {
        top: 36% !important;
        color: white;
      }

      .divider.text {
        color: white !important;
      }
    }
  }

  .schedules-match {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 0px;
    margin-top: 20px;
    width: 100%;
    padding-right: 14px;

    .add-match-button {
      width: 100%;
      background-color: var(--primaryColor) !important;
      color: white !important;
      font-family: "Poppins", sans-serif !important;
      font-weight: 500;
      height: 50px;
    }
  }

  .matches-menu-options {
    // align-items: center;
    justify-content: center;
  }

  .match-list-col-title {
    justify-content: center;
    font-weight: 700;
    color: #b3b3b3 !important;
  }

  .matches-list {
    padding-left: 0px !important;
    padding-right: 0px !important;

    background-color: #fafafa;
    padding-bottom: 50px;
  }
  .matches-list-desktop {
    display: none;
  }

  .matches-list-mobile {
    display: block;
  }
}

.matches-menu-options {
  display: flex;
  font-weight: 700;
  background-color: white;

  .matches-option {
    width: 170px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.matches-list {
  background-color: #fafafa;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  &.manual-matches-list {
    .match-container {
      .icons-container {
        width: 20%;
        display: flex;
        align-items: center;
      }
    }
  }
}
.match-list-col-title {
  font-weight: 700;
  color: #b3b3b3 !important;
}

@media (min-width: 1465px) {
  .matches-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    .matches-section-title {
      font-size: 20px;
      padding-right: 21px;
      font-weight: 700;
    }

    .dark-background {
      background-color: #666666 !important;
    }

    .team-dropdown {
      // background-color: var(--primaryColor) !important;
      background-color: var(--secondaryColor) !important;
      display: flex !important;
      align-items: center;
      font-family: "Poppins", sans-serif !important;
      font-weight: 700;
      height: 50px;
      min-width: 200px !important;
      margin-right: 20px;
      color: white !important;

      .divider.default.text {
        color: white !important;
      }

      i {
        top: 36% !important;
        color: white;
      }

      .divider.text {
        color: white !important;
      }
    }
  }

  .matches-list-desktop {
    display: block;
  }

  .matches-list-mobile {
    display: none;
  }

  .match-container {
    display: flex;
    min-height: 80px;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    color: #666666;
    padding: 20px;
    
    .match-details {
      width: 70%;
      justify-content: space-between;
      display: flex;
      align-items: center;
    }

    .match-date {
      width: 37%;
      font-weight: 700;
    }

    .match-column-title {
      width: 35%;
      text-align: center;
    }

    .match-team {
      font-weight: 700;
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: uppercase;

      .team-name {
        width: 80%;
      }

      .match-logo {
        padding: 5px;
        width: 20%;
        img {
          height: 40px;
        }
      }
    }

    .match-score {
      font-weight: 700;
      font-size: 20px;
      width: 10%;

      text-align: center;
    }

    .match-see-more {
      // width: 20%;
      // display: flex;
      // justify-content: flex-end;
      .match-see-more-button {
        background-color: #666666;
        width: 35px;
        height: 35px;
        padding: 7px;
        border-radius: 5px;
        cursor: pointer;
      }
    }

    .match-costs {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: auto;
    }

    .match-editable-score {
      width: 10%;
      text-align: center;
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      align-items: center;

      .score-input {
        width: 40%;
      }
    }

    .icons-container {
      // width: calc(20% - 40px);
      // width: 30%;
      width: 25%;

      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: flex-end;
    }
  }
}

.matches-subheader,
.matches-header {
  display: flex;
  margin-bottom: 20px;
  padding-left: 20px;
  margin-top: 5px;

  .add-match-button {
    background-color: var(--primaryColor) !important;
    color: white !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 500;
    height: 50px;
  }
}

.highlighted-team,
.match-team[highlight-team="1"] {
  color: #00d948;
}

.save-change-icon {
  display: flex;
  .save-icon {
    color: #00d948;
  }

  .cancel-icon {
    color: red;
  }
}

.no-matches-placeholder {
  padding: 15px;
  font-weight: 600;
  text-align: center;
}

.icons-container {
  .icon-notification {
    margin-top: 10px;
    .add-score-icon {
    }
    .notification-container {
      position: relative;
      top: -35px;
      right: -15px;
      height: 0;
      margin-right: 10px;
      .notification-circle {
        background-color: red;
        border-radius: 10px;
        height: 20px;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .notification-number {
          color: white;
          font-size: 10px;
          padding: 0 5px;
        }
      }
    }
  }
}
