.create-account-container {
  .create-account-form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .form-row {
      display: flex;
      align-items: end;
      gap: 10px;

      .form-input {
        flex: 1 1 0;
      }
    }

    .redirect-to-login-wrapper {
      font-weight: 600;
      .redirect-to-login {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media only screen and (max-width: 772px) {
  .create-account-container {
    .form-row {
      flex-direction: column;
      align-items: unset !important;
    }
  }
}
