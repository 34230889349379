.add-category-container {
  .category-description-row {
    margin-bottom: 15px;
  }
  .category-categories-row {
    margin-bottom: 15px;

    .category-categories-checkbox-list {
        
      .category-categories-checkbox-list-item {
      }
    }
  }
  .add-file-form-row {
    .add-category-input {
      width: 100%;
      height: 50px;
    }
  }
}
