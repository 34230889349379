@media (max-width: 900px) {
  .add-bonus-form-container {
    width: 100%;
  }
  .bonus-bottom-buttons-container {
    width: 100%;
  }
}

@media (min-width: 900px) {
  .add-bonus-form-container {
    width: 60%;
  }
  .bonus-bottom-buttons-container {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .cashflow-header {
    flex-direction: column;
    .cashflow-header-title {
      margin-bottom: 10px;
    }
  }

  .cashflow-container {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}

.form-element-icon {
  display: flex;
}

.paid-amount {
  display: flex;
}

.partial-bonus-amount {
  padding-left: 15px;
  padding-top: 6px;
}

#kt_content
  > div.d-flex.flex-column-fluid
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr.MuiTableRow-root.MuiTableRow-hover.Mui-selected {
  background-color: #d1d1d1;
}

.subheader-cashflow-row-1 {
  display: flex;
  margin-left: 20px;
}

.subheader-cashflow-row-2 {
  display: flex;

  .currency-dropdown-cashflow {
    margin-right: 15px;
    min-width: 100px !important;
  }
}

.cashflow-currency-button {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px !important;
  margin-bottom: 5px !important;

  .currency-button-content {
    display: flex;
  }
}

.cashflow-export-button {
  background-color: var(--primaryColor) !important;
}

.transaction-name {
  max-width: 23vw;
  overflow: auto;
  max-height: 3vw;
}

#kt_content > div.d-flex.flex-column-fluid > div > div > div > table > tbody > tr:nth-child(1) > td:nth-child(1) {
  width: 23vw;
}

// BONUSES PAGE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.bonus-button {
  background-color: var(--primaryColor) !important;
  color: white !important;
}

.bonus-change-status-button {
  background-color: #d1d1d1 !important;
  color: #666666 !important;
  white-space: nowrap;
}

.bonus-delete-button {
  background-color: #666666 !important;
  color: white !important;
}

.white-icon {
  color: white !important;
  padding-left: 5px;
  opacity: 1 !important;
}

.button-content {
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif !important;
  font-size: 13px;
}

.data-table-title {
  padding: 15px;
  font-weight: 600;
}

.bonus-cell-bold {
  font-weight: 600;
}

.more-options-container {
  display: flex;
  justify-content: space-between;
}

.form-item-dual {
  width: 48%;
}

.form-item {
  width: 100%;
}

.add-bonus-form-container {
  margin-left: auto;
  margin-right: auto;

  .form-row-bonus {
    display: flex;
    margin-bottom: 20px;
  }

  .sb {
    justify-content: space-between;
  }
}

.button-container-add-criteria {
  display: flex;
  justify-content: flex-end;

  .bonus-button-add-criteria {
    width: 47%;
    background-color: var(--primaryColor) !important;
  }
}

.bonus-bottom-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;

  .bottom-button-back {
    background-color: #d1d1d1 !important;
    color: #666666 !important;
    width: 47%;
  }

  .bottom-button-next {
    background-color: var(--primaryColor) !important;
    color: white !important;
    width: 47%;
  }
}

.criterias-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: 800;
  height: 3vh;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.criterias-row-name {
  width: 50%;
  text-align: center;
}

.criterias-row-amount {
  width: 50%;
  justify-content: center;
  display: flex;
}
.bonus-criteria-list-currency {
  margin-left: 5px;
}

.grey {
  color: #666666;
}
.light-grey {
  color: #d1d1d1;
}
.green {
  color: #00d948;
}

.green_icon {
  color: #00d948;
}

.cashflow-container {
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 30px;

  .cashflow-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cashflow-header-title {
      font-size: 20px;
      font-weight: 700;
    }
  }
}
.cashflow-change-currency-popup {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 15px;
}

.payments-container {
  // margin: 5%;
  .payments-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    .payments-header-title {
      font-weight: 700;
      color: #000;
      font-size: 20px;
      display: flex;
      // align-items: center;
    }
  }
  .payment-header-row {
    display: flex;
    color: #b3b3b3;
    font-weight: 600;
    font-size: 13px;
    padding-left: 15px;
    padding-bottom: 15px;
    font-family: "Poppins", sans-serif !important;

    .col-header {
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .transaction-name {
      width: 35%;
      justify-content: start;
    }
    .transaction-type {
      width: 20%;
    }
    .date-transaction {
      width: 25%;
    }
    .sum {
      width: 20%;
    }
  }
  .payments-table {
    max-height: 425px;
    overflow: auto;
    .payment-row {
      display: flex;
      height: 60px;
      align-items: center;
      font-weight: 700;
      color: #666666;
      padding-left: 15px;
      .row1 {
        padding: 0 10px;
        display: flex;
        align-items: center;
        // text-align: center;
        justify-content: center;
        text-align: center;
      }

      .transaction-name {
        width: 35%;
        justify-content: start;
      }
      .transaction-type {
        width: 20%;
      }
      .date-transaction {
        width: 25%;
      }
      .sum {
        width: 20%;
      }
    }
  }
}
