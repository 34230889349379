.video-training-section {
  // width: 100%;
  // height: 10000px;
  overflow-y: none !important;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 26px;
  padding-right: 26px;
  padding-left: 26px;

  video {
    width: 100% !important;
  }
}
