.videoItemDiv {
    margin-top: 50px;
    margin-bottom: 50px;

    .img {
        width: 225px;
        height: 140px;
        border-radius: 3px;
    }
    p {
        color: #000;

        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 71.429% */
    }

    .imgDiv {
        position: relative;
    }
    .imgDiv:hover {
        cursor: pointer;
    }
    .playVideoTutorialIcon {
        width: 11.957px;
        height: 12.997px;
    }
    .playVideoTutorialButtonDiv {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 225px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
    }
}
