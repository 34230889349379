.dialogWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .flexDivRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 24px;
  }
  .flexDivRowColorPicker {
    display: flex;
    // justify-content: space-between;
    align-items: flex-end;
    gap: 24px;
  }
}
