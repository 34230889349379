.dialogBody {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;

  .articleContent {
    color: #666;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;

    img.wp-caption {
      max-width: 30vw !important;
    }
  }
}
.articleDetails {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif !important;
  color: #b3b3b3;
  font-weight: 600;
  font-size: 13px;

  .source:hover,
  .author:hover {
    color: var(--primaryColor);
    cursor: pointer;
  }
}
@media (max-width: 1024px) {
  .dialogBody {
    display: flex;
    flex-direction: column;
  }
}
