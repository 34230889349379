.player-radar-chart {
  display: flex;
  flex-direction: row;

  .radar-chart-itself {
    width: 40%;
  }

  .player-evaluation-detail {
    width: 60%;
    display: flex;
    flex-direction: row;
    font-size: 17px;
    flex-wrap: wrap;
    max-height: max-content;
    gap: 20px;

    .player-evaluation-category-wrapper {
      width: 31%;
      height: max-content;
      .evaluation-category-header {
        display: flex;
        flex-direction: row;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .evaluation-subcategories-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 1px;
        justify-content: space-between;
        min-height: 40px;
        height: max-content;
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;
        font-size: 13px;

        .evaluation-subcategory-name {
          font-weight: 700;
          color: #b3b3b3;
        }

        .evaluation-subcategory-grade {
          font-weight: 700;
          color: #b3b3b3;
        }
      }
    }
  }
}

.evaluation-switch {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: max-content;
  margin-top: 25px;
  margin-bottom: 50px;

  .MuiIconButton-label {
    color: var(--primaryColor) !important;
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: var(--primaryColor) !important;
  }

  .MuiSwitch-root {
    height: 30px;
  }

  .MuiSwitch-switchBase {
    .MuiSwitch-thumb {
      width: 22px;
      height: 20px;
      margin: -4px;
      border-radius: 3px;
    }
  }
}

@media only screen and (min-width: 991px) {
  .players-to-compare {
    display: flex;
    gap: 25px;
    width: 75%;
    margin-top: 50px;
  }

  .radar-chart-itself-two-players {
    width: 40%;
  }
}

@media only screen and (max-width: 991px) {
  .players-to-compare {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    margin-top: 50px;
  }

  .radar-chart-itself-two-players {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .player-radar-chart {
    .player-evaluation-detail {
      width: 50%;
      font-size: 17px;
      flex-wrap: wrap;
      max-height: max-content;
      gap: 13px;

      .evaluation-subcategory-name {
        font-size: 12px;
      }
    }
  }

  .radar-chart-itself-two-players {
    width: 50%;
  }

  .players-to-compare {
    width: 100%;
  }
}
