.player-last-matches-stats-table {
    .player-last-matches-stats-header-row {
        display: flex;
        color: #b3b3b3;
        font-weight: 600;
        font-size: 13px;
        padding-left: 0px;
        padding-bottom: 15px;
        font-family: "Poppins", sans-serif;
        text-align: center;
        align-items: center;

        .player-last-matches-stats-match {
            width: 22%;
            padding-left: 15px;
            text-align: left;
        }
        .player-last-matches-stats-score {
            width: 13%;
        }
        .player-last-matches-stats-played-minutes {
            width: 13%;
        }
        .player-last-matches-stats-scored-goals {
            width: 13%;
        }
        .player-last-matches-stats-assists {
            width: 13%;
        }
        .player-last-matches-stats-yellow-cards {
            width: 13%;
        }
        .player-last-matches-stats-red-cards {
            width: 13%;
        }
    }

    .player-last-matches-stats-row {
        display: flex;
        min-height: 60px;
        align-items: center;
        font-weight: 700;
        color: #666666;
        text-align: center;
        padding: 5px 0;

        .row1 {
            padding: 0 10px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
        }

        .player-last-matches-stats-match {
            width: 22%;
            text-align: left;
            padding-left: 15px;
            .date {
                font-size: 11px;
                color: var(--primaryColor);
                font-weight: 500;
            }
        }
        .player-last-matches-stats-score {
            width: 13%;
        }
        .player-last-matches-stats-played-minutes {
            width: 13%;
        }
        .player-last-matches-stats-scored-goals {
            width: 13%;
            display: flex;
            justify-content: center;
        }
        .player-last-matches-stats-assists {
            width: 13%;
            display: flex;
            justify-content: center;
            .value {
                // border-radius: 0px;
            }
        }
        .player-last-matches-stats-yellow-cards {
            width: 13%;
            display: flex;
            justify-content: center;
        }
        .player-last-matches-stats-red-cards {
            width: 13%;
            display: flex;
            justify-content: center;
        }
        .value {
            border-radius: 12px;
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .no-player-last-matches-stats-message {
        text-align: center;
        .no-player-last-matches-stats-message-text {
            font-size: 15px;
            font-weight: 600;
            padding-top: 5px;
        }
    }
}

.player-last-matches-stat {
    display: flex;
    justify-content: center;
    align-items: center;

    .stat {
        border-radius: 500px;
        height: 29px;
        width: 29px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
    }

    .green {
        background-color: var(--primaryColor);
        color: white;
    }

    .yellow {
        background-color: #eedb00;
    }

    .red {
        background-color: #d91200;
        color: white;
    }
}
