.wrapper {
  background-color: #d91200;
  height: 46px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .message {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 10px;
  }
}
