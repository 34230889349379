.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 15px 0 15px 0;

  .logoContainer {
    border: 3px solid #fafafa;
    border-radius: 12px;
    aspect-ratio: 1;
    height: 118px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .imgDiv {
      position: relative;
      height: 75px;
      z-index: 1000;

      img {
        object-fit: contain;
      }
    }
  }

  .logoContainer:hover {
    cursor: pointer;
    border: 4px solid #dddddd;
  }
  .selected {
    border: 4px solid var(--primaryColor);
  }
  .selected:hover {
    border: 4px solid var(--primaryColor);
  }
}
