.textAreaGroup {
  width: 100%;
  .areaLabel {
    color: #b2b2b2;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
  }
}
.tfbTextArea {
  color: #666666 !important;
  font-weight: 700;
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  width: 100%;
  padding: 15px 15px 15px 30px;
  background-color: #fafafa !important;
  border: 2px solid #f2f2f2 !important;
}
