.football-api-match-page-container {
  .football-api-match-title {
    font-size: 25px;
    font-weight: 700;
    color: black;
    margin-bottom: 25px;
  }
}

.match-events-container-instat {
  padding-top: 65px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;

  .match-events-title {
    font-size: 20px;
    font-weight: 700;
    color: black;
    margin-bottom: 60px;
  }
  .events-list {
    .event-row {
      display: flex;
      padding-left: 20px;
      padding-right: 20px;
      height: 55px;
      align-items: center;
      font-size: 14px;
      color: #666666;
      font-weight: 700;

      .minute {
        width: 40px;
      }

      .event-image {
        img {
          height: 10px;
        }

        .yellow-card-icon {
          height: 11px;
          width: 7px;
          background-color: #ffe100;
        }

        .red-card-icon {
          height: 11px;
          width: 7px;
          background-color: #d91200;
        }

        .goal-icon-container {
          height: 35px;
          width: 35px;
          border-radius: 50%;
          background-color: var(--primaryColor);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .player-name {
        font-weight: 700;
      }

      .player-assist {
        color: #666666;
      }
    }

    .away-team {
      justify-content: flex-end;
    }
  }
}

.match-lineup-container-instat {
  padding-top: 65px;
  padding-bottom: 65px;
  padding-left: 50px;
  padding-right: 50px;
  .match-lineup-title {
    font-size: 20px;
    color: black;
    font-weight: 700;
    margin-bottom: 60px;
  }

  .match-lineup-details-container {
    height: 125px;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 16px;
    color: #666666;
    font-weight: 700;
    margin-bottom: 25px;

    .details-team-container {
      display: flex;
      align-items: center;
      width: 45%;

      .logo-container {
        height: 70px;

        img {
          height: 100%;
        }
      }

      .vs {
        width: 10%;
      }
    }
  }

  .match-lineup-list {
    display: flex;
    justify-content: space-between;

    .player-lineup-row {
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: center;
      height: 55px;
      width: 100%;
      .name {
        color: #666666;
      }

      .number {
        color: var(--primaryColor);
        width: 10%;
        white-space: nowrap;
        text-align: center;
      }
    }
  }

  .substitutes-title {
    font-size: 17px;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: center;
    color: #666666;
  }
}

.match-basic-details-container-instat {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 70px;
  padding-bottom: 50px;
  .match-teams {
    display: flex;
    justify-content: center;

    padding-bottom: 70px;
    border-bottom: solid 1px #f2f2f2;
    .team-container {
      height: 145px;
      img {
        // height: 100%;
      }
    }

    .score-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 50px;
      padding-right: 50px;
      font-size: 50px;
      font-weight: 700;
      color: black;

      .score-line {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .match-details-instat {
    padding-top: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #c1c1c1;
    font-size: 16px;

    .match-date {
      display: flex;
      align-items: baseline;
      margin-right: 35px;
    }

    .match-location {
      display: flex;
      align-items: baseline;
    }
  }
}
