.labelsDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  //   width: 80%;
  .numberInputFilters {
    display: flex;
    gap: 10px;
  }
}
.subtitle {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 11px;
  margin-bottom: 25px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;

  .imgDiv {
    position: relative;
    height: 150px;
    img:hover {
      cursor: pointer;
    }
  }

  .fileInputs {
    display: flex;
    gap: 9%;
    justify-content: center;

    input {
      height: 96px !important;
    }
  }
}
