.variablesContainer {
  margin-top: 20px;
  .variablesHeader,
  .variableRow {
    display: flex;
    align-items: center;
    .actionColumn,
    .variableColumn,
    .meaningColumn {
      display: flex;
      align-items: center;
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: bold;
      color: #b2b2b2;
      margin: 0;
    }
    .actionColumn {
      justify-content: center;
    }
  }
}

.variableRow {
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
}

.variableColumn {
  flex: 1;
  padding-right: 10px;
}

.meaningColumn {
  flex: 2;
  padding-right: 10px;
}

.actionColumn {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyIcon {
  cursor: pointer;
  color: #007bff;

  &:hover {
    color: #0056b3;
  }
}

.title {
  font-weight: 700;
  font-size: 13px;
  color: #b2b2b2;
  margin-bottom: 8px;
}
