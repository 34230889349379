@media (min-width: 1110px) {
  .frf-list-mobile {
    display: none;
  }

  .frf-list-row-container.frf-list-row-title {
    width: 100%;
  }

  .frf-list-players {
    background-color: #fafafa;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    .frf-list-row-container {
      width: 100%;
      display: flex;
      height: 60px;
      align-items: center;
      // padding-left: 20px;
      padding-right: 20px;
      font-size: 14px;
      color: #666;
      font-weight: 700;

      .frf-list-desktop {
        width: 100%;
        display: flex;
        // height: 60px;
        align-items: center;
        // padding-left: 20px;
        // padding-right: 20px;
        font-size: 14px;
        color: #666;
        font-weight: 700;
      }

      .crt {
        width: 8%;
        text-align: center;
      }
      .player-name {
        width: 50%;
        padding-left: 20px;
        cursor: pointer;
        text-transform: uppercase;
      }
      .player-dob {
        text-align: center;
        width: 20%;
      }
      .player-number {
        text-align: center;
        width: 17%;
      }
      .edit-section {
        width: 5%;
        text-align: center;
        .edit-team-name-icon {
          // color: #000;
          opacity: 1 !important;
          cursor: pointer;
        }
        .edit-team-name-icon:hover {
          // color: red;
        }
      }
    }

    .frf-list-row-title {
      font-weight: 700;
      color: #b3b3b3 !important;
      .player-name {
        text-transform: initial;
      }
    }
  }
  .download-list-button {
    background-color: var(--secondaryColor) !important;
    color: white !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 500;
    height: 50px;
  }
}

@media (max-width: 1110px) {
  .matches-header {
    padding-right: 20px;
  }

  .frf-list-header-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .matches-option {
    width: 50%;
  }

  .frf-list-row-title {
    display: none;
  }

  .frf-list-players {
    background-color: #fafafa;
    // padding-left: 10px;
    // padding-right: 10px;
    padding-bottom: 50px;
    .frf-list-row-container {
      // display: flex;
      // height: 60px;
      margin-top: 12px;
      align-items: center;
      // padding-left: 20px;
      // padding-right: 20px;
      font-size: 14px;
      color: #666;
      font-weight: 700;
      .frf-list-desktop {
        display: none;
      }

      .frf-list-desktop {
        // display: flex;
        // // height: 60px;
        // align-items: center;
        // // padding-left: 20px;
        // padding-right: 20px;
        // font-size: 14px;
        // color: #666;
        // font-weight: 700;
        display: none;
      }

      .crt {
        // width: 8%;
        width: 50%;
        font-size: 12px;
        // text-align: center;
      }
      .player-name {
        width: 50%;
        font-size: 12px;
        // padding-left: 20px;
        cursor: pointer;
        text-transform: uppercase;
      }
      .player-dob {
        font-size: 12px;
        // text-align: center;
        width: 50%;
      }
      .player-number {
        font-size: 12px;
        // text-align: center;
        width: 50%;
      }
      .edit-section {
        width: 5%;
        text-align: center;
        .edit-team-name-icon {
          // color: #000;
          opacity: 1 !important;
          cursor: pointer;
        }
        .edit-team-name-icon:hover {
          // color: red;
        }
      }
    }

    .frf-list-row-title {
      font-weight: 700;
      color: #b3b3b3 !important;
      .player-name {
        text-transform: initial;
      }
    }
  }
  .download-list-button {
    background-color: var(--secondaryColor) !important;
    color: white !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 500;
    height: 50px;
  }
  .frf-list-mobile {
    gap: 5px;
    display: flex;
    flex-direction: column;
    .frf-list-row-crt {
      display: flex;
    }
  }
}
