.wrapper:hover {
  cursor: pointer;
}

.wrapper {
  border-radius: 10px;
  background-color: #f2f2f2;
  display: flex;
  height: 300px;

  flex-grow: 2;

  .thumbnailImg {
    background-size: cover;

    background-position: center;
    width: 200px;
    border-radius: 10px 0px 0px 10px;
    flex-shrink: 0;
  }

  .content {
    padding: 15px 25px 10px 25px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
      margin-top: -25px;
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin: 10px 0px 10px 0px;
      max-width: 330px;
    }
    .tag:hover {
      background-color: var(--primaryColor);
      color: white;
    }
    .tag {
      flex-shrink: 0;
      border-radius: 3px;
      background: #fff;
      padding: 6px;
      color: #666;
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 7px;
    }
    .description {
      font-family: "Poppins", sans-serif !important;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
    }
    .title {
      font-family: "Poppins", sans-serif !important;

      color: #666;
      font-weight: 700;
      font-size: 18px;
    }
    .author:hover,
    .source:hover {
      color: var(--primaryColor);
    }
  }
}

@media (max-width: 1024px) {
  .wrapper {
    flex-direction: column;
  }
}
