//
// Content
//

// Desktop Mode
@include media-breakpoint-up(lg) {
	.content {
		padding: get($page-padding, desktop) 0;
	}
}

// Tablet & Mobile Mode
@include media-breakpoint-down(md) {
	.content {
		padding:0 get($page-padding, tablet-and-mobile);

		// Subheader Enabled Mode
		// .subheader-enabled.subheader-transparent & {
		// 	padding-top: 0;
		// }
	}
}
