.add-category-form {
  min-width: 400px;
  padding-left: 26px;
  padding-right: 26px;

  .add-new-subcategory {
    padding-left: 20px;
    font-size: 12px;
    text-decoration: underline;
    margin-top: 5px;
    cursor: pointer;
  }

  .add-category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 30px;
    .add-category-title {
      font-size: 20px;
      line-height: 31.75px;
      font-weight: bold;
    }
  }

  .add-category-row {
    justify-content: space-between;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    height: 50px;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    position: relative;

    .add-category-name,
    .add-subcategory-name {
      width: 100%;
      height: 50px;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 20px;
      background-color: #fafafa;
      color: #666;
    }

    .add-um-subcategory-name {
      width: 17%;
      height: 50px;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 20px;
      background-color: #fafafa;
      color: #666;
    }

    .MuiDialog-container.MuiDialog-scrollPaper .add-category-row input {
      background-color: #fafafa;
      height: 50px;
      border: 0;
      font-size: 14px !important;
      font-weight: 700;
      color: #666666 !important;
      padding-left: 20px;
      width: 100%;
      border-radius: 3px;
    }

    .delete-subcategory-icon {
      cursor: pointer;
    }
  }

  .add-category-buttons {
    margin-top: 30px;
    .grey-button {
      background-color: #666666 !important;
    }

    .add-button {
      background-color: var(--primaryColor) !important;
      color: white !important;

      .button-content {
        display: flex;
        align-items: center;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px;
      }
    }

    .custom-button-dual {
      color: white !important;
    }
  }
}
