.match-squad-content {
  .save-match-squad {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }

  .player-observations {
    .text-input {
      min-width: 220px;
      width: 90%;
      border: solid 1px hsl(0deg 0% 80%) !important;
      border-radius: 5px;
      font-size: 14px;
      background-color: transparent;
      color: #666;

      .MuiOutlinedInput-input {
        padding: 11px 14px;
      }
    }

    input {
      font-weight: 700;
    }
  }
}
