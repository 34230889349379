.notifications-page {
    padding: 50px;
    .notifications-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .header-text {
            font-size: 20px;
            font-weight: 700;
            font-family: "Poppins";
        }
        .header-sort-buttons {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            .sort-btn {
                // background-color: var(--primaryColor) !important;
                height: 50px;
                font-family: "Poppins", sans-serif !important;
                font-size: 12px !important;
                color: white !important;
                display: flex;
                align-items: center;
                margin: 0 !important;
            }
            .selected-sort-type {
                background-color: var(--primaryColor) !important;
            }
            .unselected-sort-type {
                background-color: #666666;
            }
        }
    }

    .no-notifications-message {
        margin-top: 50px;
        text-align: center;
    }

    .notifications-table {
        margin-top: 30px;
        .table-header {
            display: flex;

            .col-header {
                font-size: 13px;
                color: #b3b3b3;
                font-weight: 700;
                font-family: "Poppins";
                // text-align: center;
                padding: 20px 0;
            }

            .not-type {
                width: 150px;
                // padding-left: 10px;
                text-align: center;
            }

            .not-date {
                width: 150px;
            }

            .not-description {
                width: calc(85% - 150px - 150px);
            }
            .not-view {
                width: 15%;
            }
        }
        .table-rows {
            .notification-container {
                display: flex;
                align-items: center;
                .col1 {
                    font-size: 14px;
                    color: #666666;
                    font-weight: 700;
                    font-family: "Poppins";
                    padding: 20px 0;
                }

                .not-type {
                    width: 150px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    .not-icon {
                        color: #d91200 !important;
                    }
                }

                .not-date {
                    width: 150px;
                }

                .not-description {
                    width: calc(85% - 150px - 150px);
                }

                .read {
                    color: #b3b3b3;
                    font-weight: 500;
                }

                .unread {
                    color: #666666;
                    font-weight: 700;
                }
                .not-view {
                    width: 15%;
                    display: flex;
                    justify-content: flex-end;
                    .see-icon {
                        background-color: #666666;
                        width: 35px;
                        height: 35px;
                        padding: 7px;
                        border-radius: 5px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .notifications-page {
        padding: 0px;
        .notifications-header {
            flex-wrap: wrap;
            display: none;
            .header-sort-buttons {
                flex-wrap: wrap;
            }
        }
        .notifications-table {
            display: none;
        }
        .notifications-table-mobile {
            display: block;
            .table-rows {
                .notification-container {
                    padding: 15px 15px;
                    font-family: "Poppins";
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    border-top: 1px solid #d2d2d2;
                    border-left: 1px solid #fff;
                    border-right: 1px solid #fff;

                    .row-not {
                        .read {
                            color: #b3b3b3;
                            font-weight: 500;
                        }

                        .unread {
                            color: #666666;
                            font-weight: 700;
                        }
                    }
                    .row-first {
                        display: flex;
                        align-items: center;
                        gap: 15px;
                    }
                    .row-second {
                        display: flex;
                        justify-content: space-between;
                        gap: 20px;
                        .not-view {
                            // width: 15%;
                            display: flex;
                            justify-content: flex-end;
                            .see-icon {
                                background-color: #666666;
                                width: 35px;
                                height: 35px;
                                padding: 7px;
                                border-radius: 5px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .notifications-page {
        .notifications-table-mobile {
            display: none;
        }
    }
}
