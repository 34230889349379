.match-costs-edit-header {
  .add-training-page-title {
    margin-bottom: 0px;
  }
  margin-bottom: 20px;
}

.edit-match-costs-container {
  // padding-top: 65px;
  // padding-bottom: 65px;
  // padding-left: 50px;
  // padding-right: 50px;

  .plan-training-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    height: 50px;
    align-items: center;

    .plan-training-title {
      font-weight: 700;
      font-size: 20px;
      color: black;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .add-section-button {
      background-color: var(--primaryColor) !important;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: white !important;
      height: 50px;
      min-width: 230px !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      .add-section-button-icon {
        opacity: 1 !important;
        color: white;
      }
    }
  }

  .edit-match-costs-title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 25px;
    color: black;
    .add-training-row select {
      margin-bottom: -12px;
      margin-top: 20px;
    }
  }

  .plan-training-subtitle {
    font-size: 16px;
    margin-bottom: 25px;
    font-weight: 700;
    color: black;
  }

  .edit-match-costs-final-drop-area {
    border: 2px dashed #ededed;
    position: relative;
    margin-bottom: 65px;
    padding: 25px;
    display: flex;
    flex-direction: column;

    .sections-list {
      min-height: 175px;
      margin-bottom: 15px;
    }

    .edit-match-costs-final-drop-row {
      display: flex;
      gap: 10px;
      height: 50px;
      margin-bottom: 15px;
      justify-content: space-between;

      .final-drop-row-name {
        width: 56%;
        background-color: #fafafa;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;
        color: #666666;
        font-size: 14px;
        font-weight: 700;

        .delete-icon-section-row {
          color: #666666;
          cursor: pointer;
        }
      }

      .final-drop-row-input {
        width: 24%;
        background-color: #fafafa;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 25px;
        background-color: #fafafa;
        color: #666;
      }

      .currency-container-options {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 50px;

        .currency-container {
          .checkbox-background {
            background-color: #fafafa;
            width: 23px;
            height: 23px;
            border-radius: 5px;
            margin-right: 5px;

            .checked-center {
              position: relative;
              left: 6px;
              top: 6px;
            }
          }
        }
      }
    }

    .final-drop-area-total-row {
      width: 100%;
      background-color: #666666;
      height: 55px;
      padding-left: 20px;
      padding-right: 20px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 700;

      .currency-container-options {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .currency-container {
          .checkbox-background {
            background-color: #fafafa;
            width: 23px;
            height: 23px;
            border-radius: 5px;
            margin-right: 5px;

            .checked-center {
              position: relative;
              left: 6px;
              top: 6px;
            }
          }
          .currency-name {
            color: #fafafa;
          }
        }

        .total-value {
          margin-left: 20px;
          color: var(--primaryColor) !important;
        }
      }

      .total-text {
        color: white !important;
      }
    }

    .final-drop-area-placeholder {
      font-size: 14px;
      color: #b2b2b2;
      font-weight: 700;
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      text-align: center;
    }
  }

  .plan-training-text-area {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    border: 0;
    background-color: #fafafa;
  }

  .plan-training-row {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .plan-training-hour-input {
      width: 32%;
      // height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-size: 14px;
        font-weight: 700;
        padding-left: 5px;
      }

      fieldset {
        border-color: #fafafa;
      }
    }

    .plan-training-date-input {
      width: 32%;
      // height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-size: 14px;
        font-weight: 700;
        padding-left: 5px;
      }

      fieldset {
        border-color: #fafafa;
      }
    }
  }

  .plan-training-error-row {
    color: red;
    font-size: 12px;
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }

  .add-training-save-button {
    background-color: #00d948 !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    color: white !important;
    height: 50px;
    width: 100%;
  }

  .plan-training-error-row {
    text-align: center;
    color: red;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .add-section-row {
    height: 50px;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;

    .add-section-input {
      width: 80%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666;
    }

    .add-section-options {
      display: flex;
      align-items: center;
      .add-section-option-button {
        width: 50px;
        height: 50px;
        background-color: #666666;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .add-section-option-icon {
          opacity: 1 !important;
          color: white;
          margin: 0 !important;
        }
      }
    }
  }

  .existing-sections-list {
    display: flex;
    flex-wrap: wrap;
  }
}
.plan-training-container-edit-costs {
  .plan-training-header {
    .match-costs-edit-btn {
      display: flex;
      width: 50%;
      .match-costs-edit-btn-elem {
        width: 50%;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        min-height: 50px;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
      }
      .green-background {
        background-color: var(--primaryColor) !important;
        .add-section-button-icon {
          opacity: 1 !important;
          color: white;
        }
      }
    }
  }
  .existing-sections-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .existing-section-container {
      background-color: #fafafa;
      font-size: 14px;
      font-weight: 700;
      color: #666666;
      height: 50px;
      border-radius: 3px;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      width: min-content;
      white-space: nowrap;

      .circle-container {
        position: relative;
        top: -35px;
        height: 0px;
        width: 0px;
        left: 10px;
        .circle-delete {
          .delete-icon-section-row {
            color: white;
            background-color: red;
            border-radius: 10px;
            height: 20px;
            width: 20px;
            margin: 0px !important;
            padding: 0px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .add-section-row {
    height: 50px;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;

    .add-section-input {
      width: calc(100% - 100px);
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666;
    }

    .add-section-options {
      display: flex;
      align-items: center;
      .add-section-option-button {
        width: 50px;
        height: 50px;
        background-color: #666666;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .add-section-option-icon {
          opacity: 1 !important;
          color: white;
          margin: 0 !important;
        }
      }
    }
  }
}

.add-training-row {
  select {
    width: 100%;
    height: 50px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    padding-left: 18px;
    background-color: #fafafa;
    color: #b2b2b2 !important;
    margin-bottom: 15px;

    option {
      padding-left: 25px;
      background-color: #fafafa;
      color: #b2b2b2;
      font-size: 13px;
    }
  }
}

.training-history-container.plan-training-list-container {
  .plan-training-header {
    > div:last-child {
      display: flex;
    }
  }
  .add-training-button {
    background-color: var(--primaryColor) !important;
    color: white !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    height: 50px;
  }
}

@media only screen and (min-width: 992px) {
  .desktop-header {
    display: flex !important;
  }

  .header-row {
    display: none !important;
  }

  .content-row {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 991px) {
  .desktop-header {
    display: none !important;
  }

  .training-row {
    // height: 230px !important;
    padding: 20px !important;
  }

  .training-row-table-header.header-row {
    padding: 0 !important;
  }

  .header-row {
    display: block;
    width: 50%;
    height: 100%;
  }

  .mobile-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    div {
      width: 100% !important;
      height: 37px !important;
      display: flex;
      justify-content: flex-start;
    }
  }

  // .content-row {
  //   width: 50%;
  //   height: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: flex-start;

  //   div:not(div:last-child, .optimal-weight div) {
  //     width: 100% !important;
  //     height: 45px !important;
  //     padding-bottom: 15px;
  //     display: flex;
  //     justify-content: flex-start;
  //   }

  //   .options-column {
  //     width: 100% !important;
  //     display: flex;
  //     justify-content: flex-start;
  //   }

  //   .player-options {
  //     width: 100% !important;
  //     justify-content: flex-start !important;

  //     .add-weight-button-container {
  //       width: 100% !important;
  //       height: 35px !important;
  //     }
  //   }
  // }
}

@media only screen and (max-width: 800px) {
  .plan-training-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .plan-training-title {
    margin-bottom: 50px;

    + div {
      .training-teams-dropdown {
        font-size: 11px !important;
        min-width: 150px !important;
      }

      button div {
        font-size: 11px !important;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .container-fluid {
    padding: 0 !important;
  }

  .plan-training-container {
    padding: 20px !important;
  }
}

.delete-cost-category-message {
  font-size: 14px;
  font-weight: 700;
  color: #666666;
}

@media only screen and (max-width: 768px) {
  .match-costs-edit-header {
  margin-bottom: 10px;
    .add-training-page-title,
    .arrow-back-nav {
      margin-bottom: 0;
    }
  }
  .match-costs-edit-header {
    .arrow-back-nav {
      margin-bottom: 12.5px !important;
    }
  }
  .edit-match-costs-container {
    .edit-match-costs-final-drop-area {
      margin-bottom: 25px;
      padding: 10px;

      .sections-list {
        margin-bottom: 10px;
      }
      .edit-match-costs-final-drop-row {
        .final-drop-row-name {
          padding-left: 10px;
          padding-right: 10px;
        }
        .final-drop-row-input {
          padding-left: 10px;
        }
      }
    }
    .final-drop-area-total-row {
      flex-direction: column;
      gap: 5px;
      height: auto !important;
      padding: 10px !important;
    }
  }
}
