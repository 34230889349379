.headerRow {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  flex-wrap: wrap;

  .dateDiv {
    display: flex;
    gap: 20px;
  }
}
.headerRowBigDiv {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.tablePresence {
  margin-top: 30px;

  .headerCell {
    display: flex;
    gap: 10px;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    .headerFilter {
      display: flex;
      flex-direction: column;
      gap: 2.5px;
      align-items: center;
      justify-content: center;
    }
  }

  .right {
    justify-content: flex-end;
  }

  .playerRow {
    position: relative;
    .noPresence {
      position: absolute;
      left: 0;
      right: 0;
      top: 8px;
      margin-left: auto;
      margin-right: auto;
      width: 100px; /* Need a specific value to work */
      display: flex;
      justify-content: center;
      font-weight: 700;
    }
    .progressBar {
      height: 35px !important;
      min-width: 100px;
    }
  }
}

.placeholder {
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .headerRow {
    display: flex;
    flex-direction: column;
    align-items: initial;
    gap: 10px;

    .dateDiv {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .playerRow {
    .progressBar {
      height: 30px !important;
    }
  }
}
