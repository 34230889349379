//PLAYER CONTRACT WIDGET ==========================================================================
.player-info-widget {
  .player-profile-img {
    height: 100%;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
  }
}

.edit-player-details-date {
  .MuiInputBase-input.MuiInput-input {
    height: auto;
  }
}

.width48 {
  width: 48% !important;
}

.player-radar-chart-evaluation-widget {
  .no-evaluation-player-message {
    text-align: center;
    .no-evaluation-player-message-text {
      font-size: 15px;
      font-weight: 600;
      padding-top: 5px;
    }
  }
}

.player-add-costs-per-match {
  .match-costs-edit-btn-elem {
    // width: 72%;
    font-family: "Poppins", sans-serif !important;
    font-size: 13px !important;
    color: white !important;
    // min-height: 50px;
    height: 50px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  .green-background {
    background-color: var(--primaryColor) !important;
    .add-section-button-icon {
      opacity: 1 !important;
      color: white;
    }
  }
}

.player-transaction-popup-container {
  margin-bottom: 20px;
  .transaction-currency {
    display: flex;
    background-color: #fafafa;
    margin-top: 20px;
  }

  .player-transaction-row {
    .cost-category {
      width: 100%;
      height: 50px;
      margin-top: 15px;
    }
    .currency-container {
      // margin-top: 15px;
      margin-right: 42px;
      margin-left: 16px;
    }
  }
  .player-transaction-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    .player-transaction-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .add-categories-dropdown {
    select {
      width: 100%;
      height: 50px;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #b2b2b2;
    }
  }

  .player-cost-value-per-match {
    width: 100%;
    height: 50px;
    // margin-top: 15px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    padding-left: 25px;
    background-color: #fafafa;
    color: #b2b2b2;
  }

  .player-transaction-cost-date {
    margin-top: 20px;
    margin-bottom: 20px;

    .cost-date-wrapper {
      position: relative;
      .calendar-icon {
        position: absolute;
        // right: 40px;
        // top: 136px;
        bottom: 0;
        // margin-top: auto ;
        // margin-bottom: auto ;
      }
    }

    .MuiFormControl-root {
      width: 100% !important;
    }
  }

  .player-spending-details-buttons {
    display: flex;
    .player-spending-button {
      .edit-details-icon {
        color: white;
      }

      width: 50%;
      height: 50px;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      color: #fff !important;
      font-weight: 700;
      font-size: 14px;
      font-family: Poppins, sans-serif !important;
    }
  }
}

@media (min-width: 900px) {
  .player-radar-chart-evaluation-widget {
    .national-team-call-up-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      margin-bottom: 30px;
      .national-team-call-up-title {
        font-size: 20px;
        font-weight: 700;
        color: black;
      }
    }
  }
}

@media (max-width: 991px) {
  .player-radar-chart-evaluation-widget {
    .player-radar-chart {
      flex-direction: column;
      .radar-chart-itself {
        width: 100%;
      }
      .player-evaluation-detail {
        width: 100%;
        flex-direction: column;
        .player-evaluation-category-wrapper {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1520px) {
  .col-lg-4,
  .col-lg-8 {
    max-width: 100% !important;
    // margin-bottom: 20px;
    padding-top: 50px;
    .ui.card {
      width: 100% !important;
    }
  }

  .col-lg-10 {
    max-width: 100% !important;
    .col-lg-5 {
      max-width: 100% !important;
      // margin-bottom: 20px;
    }

    .col-lg-7 {
      max-width: 100% !important;
    }
  }

  // div[name="selected_season"] {
  //   position: absolute;
  //   top: 110px;
  //   right: 17px;
  // }
  .first-elem-flex {
    margin-bottom: 25px;
  }

  .no-padding-horizontal-mobile-1520 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .margin-top-25 {
    margin-top: 25px;
  }

  .player-info-widget {
    .player-profile-img {
      width: initial;
    }
  }

  .player-season-teams-history .player-teams-container {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-flow: wrap;

    .notes-container .player-notes {
      max-width: 81%;
    }
  }
}

@media (max-width: 600px) {
  .instat-no-display-mobile {
    display: none !important;
  }

  .advanced-stats-container {
    display: none !important;
  }

  .dropdown-season-select {
    padding-left: 12px !important;
    margin-bottom: 12px !important;

    div[name="selected_season"] {
      margin-bottom: 0px !important;
    }
  }

  .contract-info-profile-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
    .contract-info-title-container {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      .contract-info-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 31.75px;
      }
    }
  }

  .widget {
    padding-top: 0px !important;
  }
  .player-transactions {
    // padding: 30px 50px;
    // height: 100%;

    .transaction-row {
      display: flex;
      min-height: 50px;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
      width: 100%;
      font-weight: 700;
      font-size: 14px;
      color: #666666;
    }

    .transactions-list-header {
      line-height: 31.75px;
      font-weight: 700;
      font-size: 13px;
      color: #b2b2b2;
      display: flex;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
      width: 100%;
      padding-bottom: 15px;
    }
    .transactions-list {
      max-height: 350px;
      overflow: auto;

      .transaction-description {
        width: 33% !important;
      }

      .transaction-date {
        width: 33% !important;
        text-align: center;
      }

      .transaction-amount {
        width: 33% !important;
        text-align: center;
      }
    }
  }

  .no-margin-bottom {
    margin-bottom: 0px !important;
  }

  .player-profile-injury-container {
    height: 100%;
    flex-direction: column;
    .injuries-table-container {
      padding: 20px;
    }
  }

  .basic-info-row {
    height: 100%;
  }

  .player-transactions-title {
    padding-left: 30px;
  }

  .title-highlights-container {
    font-size: 20px;
    font-weight: 700;
    color: black;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .event-type-select {
      select,
      input.event-type {
        height: 44px !important;
        background-color: #fafafa !important;
        color: #666666 !important;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 700;
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571429rem;
        width: 200px;
        padding: 10px;
      }
    }
  }

  .training-table {
    .training-header-row {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 0px !important;
      padding-bottom: 15px;
      font-family: "Poppins", sans-serif !important;
    }

    .training-date {
      width: 29% !important;
      justify-content: start;
    }
    .training-present {
      width: 29% !important;
    }
    .training-raiting {
      width: 13% !important;
    }
    .training-comment {
      width: 29% !important;
    }
  }

  .training-row {
    .training-date {
      width: 29% !important;
      justify-content: start;
    }
    .training-present {
      width: 29% !important;
    }
    .training-raiting {
      width: 13% !important;
    }
    .training-comment {
      width: 29% !important;
    }
  }

  .min-width-overwrite {
    min-width: 30% !important;
  }
}

@media (max-width: 767px) {
  .add-player-form {
    min-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .edit-team-name-popup {
    width: 100%;
  }

  .delete-player-popup-container,
  .edit-player-details-container {
    width: 100% !important;
  }

  .edit-player-details-container {
    .edit-details-row:nth-child(3) {
      flex-direction: column;
      height: 100%;
      .edit-player-details-dropdown {
        width: 100%;
        margin-bottom: 20px;
      }

      .edit-player-details-input {
        width: 100% !important;
        height: 50px;
      }
    }
  }
}

.contract-info-profile-container {
  .contract-info-title-container {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    .contract-info-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 31.75px;
    }
  }

  .contract-financial-notifications {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 15px;
    .notification-container {
      min-height: 60px;
      display: flex;
      gap: 10px;
      // justify-content: space-between;
      align-items: center;
      border-radius: 3px;
      background-color: #fafafa;
      padding: 10px;
      .image-svg {
      }
      .notification-text {
        font-size: 14px;
        color: #b2b2b2;
        font-weight: 700;
        font-family: "Poppins";
        .bold-word {
          color: #666666;
        }
      }
    }
    .yellow-card {
      border: 2px solid #ffd400;
    }
    .red-card {
      border: 2px solid #d91200;
    }
  }

  .contract-info-subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 31.75px;
    padding-bottom: 10px;
  }

  .active-contract-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 50px;
    font-weight: 700;
    font-size: 13px;
    color: #b2b2b2;

    .active-contract-date {
      width: 35%;
      text-align: center;
    }

    .active-contract-wage {
      width: 30%;
      text-align: center;

      .contract-currency-icon {
        color: #b2b2b2;
      }
    }
  }

  .bonuses-container {
    width: 100%;
    // max-height: 165px;
    overflow-y: auto;

    .contract-widget-bonus {
      display: flex;
      justify-content: space-between;
      min-height: 50px;
      font-size: 13px;
      align-items: center;
      font-weight: 700;

      .bonus-title {
        color: #b2b2b2;
        padding-left: 10px;
        padding-right: 20px;
      }

      .bonus-element {
        color: #666666;
        display: flex;
      }
    }
  }
}

.placeholder-bonuses {
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
}

// PLAYER PROFILE MEDICAL =======================================================================

.profile-medical-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  .profile-medical-title {
    display: flex;
    align-items: center;
    div {
      font-size: 20px;
      font-weight: 700;
      line-height: 31.75px;
      margin-left: 5px;
    }
  }

  .profile-injuries-dropdown {
    background-color: #666666 !important;
    border: 0 !important;
    display: flex !important;
    align-items: center;
    padding-left: 20px !important;
    font-weight: 700;
    height: 50px;

    div {
      color: white !important;
      font-size: 14px !important;
    }

    i {
      margin: 0 !important;
    }

    div.visible.menu.transition > div {
      background-color: #666666 !important;
      font-weight: 600;
    }
  }
}

//REPORTS CHARTS

.chart-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 40px;

  .chart-card-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 31.75px;
  }

  .profile-chart-dropdown {
    background-color: #666666 !important;
    border: 0 !important;
    display: flex !important;
    align-items: center;
    padding-left: 20px !important;
    font-weight: 700;
    height: 50px;

    div {
      color: white !important;
      font-size: 14px !important;
    }

    i {
      margin: 0 !important;
    }

    div.visible.menu.transition > div {
      background-color: #666666 !important;
      font-weight: 600;
    }
  }
}

.chart-card-menu {
  display: flex;
  padding-left: 50px;
  .chart-card-menu-option {
    height: 50px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .option-text {
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.profile-test-chart-container-graph {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}

.player-profile-add-image-button {
  height: 30px;
  width: 30px;
  background-color: var(--primaryColor);
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;

  .add-image-icon {
    color: white;
    opacity: 1 !important;
    margin: 0 !important;
  }
}

.cancel-player-profile-add-image-button {
  height: 30px;
  width: 30px;
  background-color: red;
  position: absolute;
  right: 40px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;

  .add-image-icon {
    color: white;
    opacity: 1 !important;
    margin: 0 !important;
  }
}

.edit-player-details-profile {
  height: 30px;
  width: 30px;
  background-color: var(--primaryColor);
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;

  .edit-details-icon {
    color: white;
    opacity: 1 !important;
    margin: 0 !important;
  }
}

.delete-player-details-profile {
  height: 30px;
  width: 30px;
  background-color: #666666;
  position: absolute;
  right: 40px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;

  .edit-details-icon {
    color: white;
    opacity: 1 !important;
    margin: 0 !important;
  }
}

.move-player-profile {
  height: 30px;
  width: 30px;
  background-color: #666666;
  position: absolute;
  right: 75px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;

  .edit-details-icon {
    color: white;
    opacity: 1 !important;
    margin: 0 !important;
  }
}

.edit-player-details-container {
  .edit-details-row-buttons {
    margin-top: 21px;
  }

  .edit-details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    .edit-details-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .edit-details-row-ds {
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;
    position: relative;
    display: flex;
    justify-content: space-between;
    .edit-player-phone-input {
      .PhoneInputInput {
        height: 50px !important;
      }
    }
  }

  .edit-details-row {
    width: 100%;
    margin-bottom: 15px;
    height: 50px;
    position: relative;
    display: flex;
    justify-content: space-between;

    .edit-player-details-input {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666;
    }

    .edit-player-details-dropdown {
      background-color: #fafafa !important;
      border: 0 !important;
      display: flex !important;
      align-items: center;
      padding-left: 20px !important;
      font-weight: 700;
      height: 50px;
      width: 48%;

      div {
        color: #b2b2b2 !important;
        font-size: 14px !important;
      }

      input {
        display: flex;
        height: 50px;
        align-items: center;
        padding-left: 20px;
        color: #b2b2b2 !important;
        font-size: 14px !important;
      }
    }

    .edit-player-details-date {
      width: 100%;
      height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-weight: 700;
        padding-left: 20px;
        height: 100%;
      }
      ::before {
        border-bottom: 0 !important;
      }

      ::after {
        border-bottom: 0;
      }

      fieldset {
        border-color: #fafafa;
      }
    }

    .calendar-icon {
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    .edit-details-button {
      width: 48%;
      height: 50px;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      color: white !important;
      font-weight: 700;
      font-size: 14px;
      font-family: "Poppins", sans-serif !important;

      .edit-details-icon {
        opacity: 1 !important;
        color: white;
      }
    }
  }
}

.delete-player-popup-container {
  // width: 25vw;
  width: 100%;
  .delete-player-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .delete-player-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .delete-player-text {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
    color: #666666;
  }

  .delete-player-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    .delete-player-button {
      width: 48%;
      height: 50px;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      color: white !important;
      font-weight: 700;
      font-size: 14px;
      font-family: "Poppins", sans-serif !important;

      .delete-player-icon {
        opacity: 1 !important;
        color: white;
      }
    }
  }

  .change-team-dropdown {
    background-color: #fafafa !important;
    border: 0 !important;
    display: flex !important;
    align-items: center;
    padding-left: 20px !important;
    font-weight: 700;
    height: 50px;
    margin-bottom: 40px;

    div {
      color: #666666 !important;
      font-size: 14px !important;
    }

    i {
      margin: 0 !important;
    }

    div.visible.menu.transition > div {
      background-color: #fafafa !important;
      font-weight: 600;
    }
  }
}

.player-transactions {
  padding: 30px 50px;
  height: 100%;
  .player-transactions-container {
    .player-transactions-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      margin-bottom: 40px;

      .player-transactions-title {
        font-size: 20px;
        font-weight: 700;
        color: black;
      }
    }

    .transactions-list-header {
      line-height: 31.75px;
      font-weight: 700;
      font-size: 13px;
      color: #b2b2b2;
      display: flex;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
      width: 100%;
      padding-bottom: 15px;
      .transaction-description {
        width: calc(50% - 20px);
      }

      .transaction-date {
        width: 25%;
        text-align: center;
      }

      .transaction-amount {
        width: 25%;
        text-align: center;
      }
    }

    .transactions-list {
      max-height: 350px;
      overflow: auto;

      .transaction-row {
        display: flex;
        min-height: 50px;
        align-items: center;
        padding-left: 25px;
        padding-right: 25px;
        width: 100%;
        font-weight: 700;
        font-size: 14px;
        color: #666666;
        .transaction-description {
          width: calc(50% - 20px);
        }

        .transaction-date {
          width: 25%;
          text-align: center;
        }

        .transaction-amount {
          width: 25%;
          text-align: center;
          // display: flex;
          // justify-content: center;
          // align-items: center;
        }

        .row-transaction {
          // justify-content: baseline;
        }
        .transaction-delete {
          width: 20px;
          .delete-transaction-icon {
            color: #d91200;
          }
        }
      }
    }
  }

  .transactions-total-row {
    background-color: #666666;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    height: 55px;
    padding-left: 25px;
    padding-right: 25px;
    align-items: center;
    margin-top: 25px;

    .total-text {
      color: #fff;
      width: 75%;
    }

    .total-amount {
      color: var(--primaryColor);
      width: 25%;
      text-align: center;
      .green-icon {
        color: var(--primaryColor) !important;
      }
    }
  }
}

.custom-parameters-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  position: relative;

  .custom-parameters-title-col {
    width: 33%;
    .custom-parameters-title {
      font-size: 20px;
      line-height: 31.75px;
      font-weight: 700;
    }
  }

  .custom-parameter-container {
    width: 33%;

    .custom-parameter-header {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .param-name {
        font-size: 14px;
        font-weight: 700;
        color: #666666;
        margin-right: 20px;
      }
      .param-value {
        font-size: 30px;
        font-weight: 700;
        color: var(--primaryColor);
        line-height: 31.75px;
        margin-right: 20px;
      }
      .info-circle {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: #f5f5f5;
        color: #666666;
        font-size: 13px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .custom-parameter-explanation {
      color: #b2b2b2;
      font-size: 13px;
      line-height: 20px;
      padding-right: 100px;
      font-weight: 500;
    }
  }

  .custom-parameters-image {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.dropdown-season-select {
  // justify-content: space-between;
  gap: 20px;
  padding-right: 12px;
  margin-bottom: 20px;
  padding-left: 12.5px;
  .inactive-container {
    border-radius: 3px;
    border: 2px solid #d91200;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    flex-grow: 1;

    .inactive-text {
      font-size: 20px;
      color: #d91200;
      font-weight: 700;
      font-family: "Poppins";
    }
  }

  .loaned-text {
    font-family: "Poppins", sans-serif !important;
    font-size: 19px;
    font-weight: 700;
    display: flex;
    align-items: center;
    .first-part {
      color: var(--secondaryColor);
    }
    .loaned-team {
      color: var(--primaryColor);
      font-style: italic;
    }
  }
}

.profile-season-dropdown {
  background-color: #666666 !important;
  border: 0 !important;
  display: flex !important;
  align-items: center;
  padding-left: 20px !important;
  font-weight: 700;
  height: 50px;

  div {
    color: white !important;
    font-size: 14px !important;
  }

  i {
    margin: 0 !important;
  }

  div.visible.menu.transition > div {
    background-color: #666666 !important;
    font-weight: 600;
  }
}

///////////player highlights widget
.player-highlights-widget-container {
  // padding: 50px;

  .title-highlights-container {
    font-size: 20px;
    font-weight: 700;
    color: black;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .event-type-select {
      select,
      input.event-type {
        height: 44px !important;
        background-color: #fafafa !important;
        color: #666666 !important;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 700;
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571429rem;
        width: 200px;
        padding: 10px;
      }
    }
  }

  .content-highlights-container {
    display: flex;
    gap: 10px;

    .video-container {
      width: 50%;
      // height: 375px;
      height: fit-content;
    }

    .events-container {
      padding: 0 15px;
      width: calc(50% - 10px);

      .player-video-container {
        margin-bottom: 15px;
        // border-width: thick;
        // border: #e5e5e5 solid;
        padding-top: 10px;
        .player-video-title {
          font-family: "Poppins", sans-serif !important;
          font-size: 18px;
          font-weight: 700;
          padding-left: 10px;
        }

        .player-video-tags {
          display: flex;
          gap: 17px;
          flex-flow: wrap;
          justify-content: space-between;
          .player-video-tag {
            display: flex;
            padding: 10px 17px;

            width: 48%;
            justify-content: space-between;
            cursor: pointer;
            background-color: #fafafa;

            .video-state {
              display: flex;
              align-items: center;
              padding: 0 8px;

              .video-start-stop-button {
                background-color: #666666;
                width: 35px;
                height: 35px;
                padding: 7px;
                border-radius: 5px;
                cursor: pointer;

                svg {
                  position: relative;
                  left: 5px;
                }
              }
            }
            .video-tag-data {
              .video-time-event {
                display: flex;
                align-items: center;
                gap: 8px;
              }

              .video-tag-name {
                font-weight: 800;
                font-size: 16px;
              }

              .video-tage-time {
                // background-color: rgb(0, 217, 72, 0.3);
                color: #b9b9b9;

                font-weight: 600;
                padding: 3px;
                margin: 3px 0;
                width: fit-content;
              }

              .video-tag-details {
                font-weight: 700;
                font-size: 13px;
                color: #b2b2b2;
              }
            }
          }
        }
      }
    }
  }

  .no-video-placeholder {
    text-align: center;
    .no-video-placeholder-text {
      font-size: 15px;
      font-weight: 600;
      padding-top: 5px;
    }
  }
}

.training-table {
  .training-header-row {
    display: flex;
    color: #b3b3b3;
    font-weight: 600;
    font-size: 13px;
    padding-left: 15px;
    padding-bottom: 15px;
    font-family: "Poppins", sans-serif !important;

    .col-header {
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .training-date {
      width: 20%;
      justify-content: start;
    }
    .training-present {
      width: 20%;
    }
    .training-raiting {
      width: 20%;
    }
    .training-comment {
      width: 40%;
    }
  }

  .training-row {
    display: flex;
    height: 60px;
    align-items: center;
    font-weight: 700;
    color: #666666;
    padding-left: 15px;
    .row1 {
      padding: 0 10px;
      display: flex;
      align-items: center;
      // text-align: center;
      justify-content: center;
    }

    .training-date {
      width: 20%;
      justify-content: start;
    }
    .training-present {
      width: 20%;
    }
    .training-raiting {
      width: 20%;
    }
    .training-comment {
      width: 40%;
    }
  }
}

.no-video-placeholder {
  text-align: center;
  .no-video-placeholder-text {
    font-size: 15px;
    font-weight: 600;
    padding-top: 5px;
  }
}

//National team call up widget
.national-team-call-up-widget {
  padding: 30px 50px;
  .national-team-call-up-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    margin-bottom: 30px;
    .national-team-call-up-title {
      font-size: 20px;
      font-weight: 700;
      color: black;
    }
    .player-add-national-team-call-up {
      .national-team-call-up-edit-btn-elem {
        // width: 72%;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        // min-height: 50px;
        height: 50px;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        .national-team-call-up-btn-text {
        }
      }
      .green-background {
        background-color: var(--primaryColor) !important;
        .add-section-button-icon {
          opacity: 1 !important;
          color: white;
        }
      }
    }
  }
  .national-team-call-up-table {
    .national-team-call-up-header-row {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 0px;
      padding-bottom: 15px;
      font-family: "Poppins", sans-serif;
      text-align: center;
      align-items: center;

      .national-team-call-up-date {
        width: 8%;
        padding-left: 15px;
        text-align: left;
      }
      .national-team-call-up-team {
        width: 8%;
      }
      .national-team-call-up-competition {
        width: 11%;
      }
      .national-team-call-up-age-group {
        width: 8%;
      }
      .national-team-call-up-activity-type {
        width: 11%;
      }
      .national-team-call-up-score {
        width: 8%;
      }
      .national-team-call-up-played-minutes {
        width: 8%;
      }
      .national-team-call-up-scored-goals {
        width: 8%;
      }
      .national-team-call-up-assists {
        width: 8%;
      }
      .national-team-call-up-yellow-cards {
        width: 8%;
      }
      .national-team-call-up-red-cards {
        width: 8%;
      }
      .national-team-call-up-delete {
        width: 3%;
      }
      .national-team-call-up-edit {
        width: 3%;
      }
    }

    .national-team-call-up-row {
      display: flex;
      height: 60px;
      align-items: center;
      font-weight: 700;
      color: #666666;
      // padding-left: 15px;
      text-align: center;

      .row1 {
        padding: 0 10px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }

      .national-team-call-up-date {
        width: 8%;
        text-align: left;
        padding-left: 15px;
      }
      .national-team-call-up-team {
        width: 8%;
      }
      .national-team-call-up-competition {
        width: 11%;
      }
      .national-team-call-up-age-group {
        width: 8%;
      }
      .national-team-call-up-activity-type {
        width: 11%;
      }
      .national-team-call-up-score {
        width: 8%;
      }
      .national-team-call-up-played-minutes {
        width: 8%;
      }
      .national-team-call-up-scored-goals {
        width: 8%;
      }
      .national-team-call-up-assists {
        width: 8%;
      }
      .national-team-call-up-yellow-cards {
        width: 8%;
      }
      .national-team-call-up-red-cards {
        width: 8%;
      }
      .national-team-call-up-delete {
        width: 3%;
      }
      .national-team-call-up-edit {
        width: 3%;
      }
    }
  }
  .no-national-team-call-up-message {
    text-align: center;
    .no-national-team-call-up-message-text {
      font-size: 15px;
      font-weight: 600;
      padding-top: 5px;
    }
  }
}

.national-team-dropdown {
  background-color: #fafafa !important;
  border: 0 !important;
  display: flex !important;
  align-items: center;
  padding-left: 20px !important;
  font-weight: 700;
  height: 50px;
  width: 48%;

  .dropdown.icon {
    line-height: normal !important;
    top: auto !important;
  }

  div {
    // color: #b2b2b2 !important;
    font-size: 14px !important;
  }

  input {
    display: flex;
    height: 50px;
    align-items: center;
    padding-left: 20px;
    // color: #b2b2b2 !important;
    color: #000000de;
    font-size: 14px !important;
  }

  margin-bottom: 15px;
}

//DOCUMENTS WIDGET
.documents-widget-container {
  padding: 30px 50px;
  .documents-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    margin-bottom: 30px;
    .documents-title {
      font-size: 20px;
      font-weight: 700;
      color: black;
    }
    .documents-add-doc {
      .documents-add-doc-btn {
        // width: 72%;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        // min-height: 50px;
        height: 50px;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        .documents-add-doc-btn-text {
        }
      }
      .green-background {
        background-color: var(--primaryColor) !important;
        .add-section-button-icon {
          opacity: 1 !important;
          color: white;
        }
      }
    }
  }
  .documents-table {
    .documents-table-header-row {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 0px;
      padding-bottom: 15px;
      font-family: "Poppins", sans-serif;
      text-align: center;
      align-items: center;

      .doc-icon {
        width: 70px;
        padding: 0 20px;
      }
      .doc-tittle {
        width: calc(46% - 70px);
        text-align: start;
        padding-left: 10px;
      }
      .doc-type {
        width: 8%;
      }
      .doc-size {
        width: 12%;
      }
      .doc-date-add {
        width: 12%;
      }
      .doc-add-by {
        width: 12%;
      }
      .doc-btns {
        width: 10%;
      }
    }
    .documents-table-content {
      .documents-table-row {
        display: flex;
        height: 60px;
        align-items: center;
        font-weight: 700;
        color: #666666;
        // padding-left: 15px;
        text-align: center;

        .row1 {
          padding: 0 10px;
          // display: flex;
          // align-items: center;
          // justify-content: center;
        }
        .doc-icon {
          width: 70px;
          padding: 0 20px;
          .type-logo {
            width: 30px;
            height: auto;
          }
        }
        .doc-tittle {
          width: calc(46% - 70px);
          text-align: start;
          cursor: pointer;
        }
        .doc-type {
          width: 8%;
        }
        .doc-size {
          width: 12%;
        }
        .doc-date-add {
          width: 12%;
        }
        .doc-add-by {
          width: 12%;
        }
        .doc-btns {
          width: 10%;
          display: flex;
          gap: 15px;
          justify-content: flex-end;
          padding-right: 20px;
          align-items: center;
          i.icon {
            height: auto !important;
          }
        }
      }
    }
  }
  .no-national-team-call-up-message {
    text-align: center;
    .no-national-team-call-up-message-text {
      font-size: 15px;
      font-weight: 600;
      padding-top: 5px;
    }
  }
}

//TRANSFER HISTORY
.transfer-history-widget {
  .transfer-history-club {
    display: flex;
    gap: 10px;
    align-items: center;
    .club-img {
      img {
        width: 30px;
        height: auto;
      }
    }
  }
  .transfer-history-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    margin-bottom: 30px;
    .transfer-history-title {
      font-size: 20px;
      font-weight: 700;
      color: black;
    }
    .player-add-transfer-history {
      .transfer-history-edit-btn-elem {
        // width: 72%;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        // min-height: 50px;
        height: 50px;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
      }
      .green-background {
        background-color: var(--primaryColor) !important;
        .add-section-button-icon {
          opacity: 1 !important;
          color: white;
        }
      }
    }
  }
  .transfer-history-table {
    .transfer-history-header-row {
      line-height: 31.75px;
      font-weight: 700;
      font-size: 13px;
      color: #b2b2b2;
      display: flex;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
      width: 100%;
      padding-bottom: 15px;
      .transfer-history-club {
        width: 40%;
      }
      .transfer-history-period {
        width: 50%;
        text-align: center;
      }
      .action-buttons {
        width: 10%;
      }
    }
    .transfer-history-row {
      .transfer-history-club {
        width: 40%;
        display: flex;
        gap: 10px;
        align-items: center;
        .club-img {
          img {
            width: 30px;
            height: auto;
          }
        }
      }
      .transfer-history-period-start {
        width: 25%;
        text-align: center;
      }
      .transfer-history-period-end {
        text-align: center;
        width: 25%;
      }
      .action-buttons {
        width: 10%;
        display: flex;
        align-items: center;
        gap: 15px;
        justify-content: flex-end;
        .icon {
          height: initial !important;
        }
      }
    }
    .row1 {
      display: flex;
      min-height: 50px;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
      width: 100%;
      font-weight: 700;
      font-size: 14px;
      color: #666666;
    }
  }
  .no-national-team-call-up-message {
    text-align: center;
    .no-national-team-call-up-message-text {
      font-size: 15px;
      font-weight: 600;
      padding-top: 5px;
    }
  }
}
input[name="date-picker"] {
  padding-left: 0 !important;
}
.add-event-form-container {
  .add-team-manual-image {
    .image-input-container {
      padding: 10px 15px;
    }
  }
}

.green-icon {
  color: var(--primaryColor) !important;
}

.margin-bottom-row {
  margin-bottom: 25px;
}

.training-stats-widget {
  padding: 30px;
}

.player-no-contract {
  position: initial;
}

.no-padding {
  padding: 0 !important;
}

.edit-hover:hover {
  color: black;
  cursor: pointer;
}
.trash-hover:hover {
  color: #d91200;
  cursor: pointer;
}

.player-season-teams-history {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  .player-teams-container {
    display: flex;
    // width: 100%;
    // min-width: 29%;
    gap: 10px;
    // flex-flow: wrap;

    .player-team-container {
      height: 50px;
      border-radius: 3px;
      background-color: #ffffff;
      display: flex;
      gap: 20px;
      align-items: center;
      padding: 5px 20px;

      .player-team-name {
        font-size: 14px;
        color: #666666;
        font-weight: 700;
        font-family: "Poppins";
        text-transform: uppercase;
      }
      .deleted-player-team-icon {
        .icon-close-color {
          color: #666666;
          margin: 0 !important;
          cursor: pointer;
        }
      }
    }
  }
  .add-player-to-team-btn {
    font-family: "Poppins", sans-serif !important;
    font-size: 13px !important;
    color: white !important;
    height: 50px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin: 0 !important;
  }
  .green-background {
    background-color: var(--primaryColor) !important;
    .add-player-to-team-btn-icon {
      opacity: 1 !important;
      color: white;
    }
  }

  .notes-container {
    display: flex;
    justify-content: space-between;
    // width: 70%;
    max-width: 60%;
    flex-flow: wrap;
    gap: 10px;

    .player-notes {
      align-self: center;
      padding: 10px 10px;
      // min-width: 450px;
      // max-width: 81%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .add-notes-button {
    min-width: 174px;
    margin-left: auto;
  }
}

.delete-player-popup-container {
  .move-player-row {
    margin-bottom: 30px;
  }
}

.doc-name {
  display: flex;
  align-items: center;
  .doc-icon {
    width: 70px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .player-info-widget {
    padding-top: 0;
  }
  .player-profile-content {
    padding-top: 10px !important;
  }
  .no-padding-horizontal-mobile-991 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .second-elem-flex {
    margin-top: 20px;
  }

  .first-elem-flex {
    margin-bottom: 0;
  }

  .national-team-call-up-widget,
  .training-stats-widget {
    padding: 30px;
  }

  .training-stats-widget {
    margin-bottom: 20px;
  }

  .margin-bottom-row {
    margin-bottom: 20px;
  }

  .doc-name {
    .doc-icon {
      width: 40px;
      padding-right: 10px;
    }
  }
}

@media only screen and (max-width: 1531px) {
  .player-season-teams-history {
    flex-wrap: wrap;
  }

  .player-season-teams-history {
    .player-notes {
      min-width: 335px;
    }
  }
}

@media (max-width: 1870px) {
  .player-season-teams-history {
    .add-notes-button {
      margin-left: 0;
      margin-right: auto;
    }
  }
}
