.training-name {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 25px;
}

.mobile-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.training-page-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 50px;

  .training-page-title {
    font-size: 20px;
    font-weight: 700;
    color: black;
    margin-bottom: 30px;
  }

  .training-page-players-list {
    .training-player-row {
      height: 80px;
      align-items: center;
      display: flex;
      font-size: 14px;
      font-weight: 700;
      color: #666666;
      padding-left: 20px;
      padding-right: 20px;
    }

    .training-player-list-table-header {
      padding-bottom: 15px;
      font-size: 13px;
      font-weight: 700;
      color: #b2b2b2;
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;

      .player-name-header {
        width: 35%;
      }
      .player-parameter-header {
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: normal;
        overflow-wrap: normal;
        cursor: pointer;
        padding-left: 10px;
        padding-right: 10px;
      }
      .player-double-parameter-header {
        width: 15%;
        text-align: center;
        cursor: pointer;

        .row-2 {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .player-double-parameter {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 15%;

      .first {
        font-size: 14px;
      }
      .second {
        font-size: 12px !important;
      }
    }

    .player-parameter {
      width: 15%;
      text-align: center;
    }

    .player-name {
      width: 35%;
      display: flex;
      align-items: center;

      .player-avatar {
        margin-right: 15px;
      }
    }
  }

  .training-schedule-table-header {
    padding-bottom: 15px;
    font-size: 13px;
    font-weight: 700;
    color: #b2b2b2;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;

    .section-name {
      width: 15%;
    }

    .section-image {
      width: 25%;
    }

    .section-video {
      width: 18%;
      display: flex;
      justify-content: flex-start;
    }

    .section-time {
      width: 42%;
      text-align: center;
    }
  }

  .training-schedule-row {
    min-height: 55px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    padding-left: 20px;
    padding-right: 20px;
    color: #666666;
    height: 78px;

    .training-schedule-name {
      width: 15%;
    }

    .training-schedule-image {
      width: 25%;

      .image-training-schedule {
        cursor: pointer;
        background-size: cover;
        height: 50px;
        width: 73px;
      }
    }

    // .training-schedule-video {
    //   width: 18%;
    //   // margin-top: 29px;

    //   .training-video-thumbnail {
    //     margin-top: 29px;
    //     .react-thumbnail-generator {
    //       height: 100px;
    //       display: flex;
    //       justify-content: flex-start;
    //       img {
    //         width: 73px;
    //         height: 50px;
    //       }
    //       // padding-top: 10px;
    //     }
    //   }

    //   .thumbanail-play-button {
    //     position: relative;
    //     top: 65px;
    //     left: 30px;
    //     color: white;
    //   }
    // }
    .training-schedule-no-video {
      width: 18%;
    }

    .training-schedule-video {
      width: 18%;
      height: 0px;
      padding-bottom: 92px;
      .training-video-thumbnail:hover {
        -webkit-filter: brightness(1);
        filter: brightness(1);
      }
      .training-video-thumbnail {
        width: 88px;
        -webkit-filter: brightness(0.5);
        filter: brightness(0.5);
        cursor: pointer;
      }
      .thumbanail-play-button {
        position: relative;
        top: 35px;
        left: 35px;
        color: white;
        z-index: 3;
        cursor: pointer;
      }
      .thumbanail-play-button:hover + .training-video-thumbnail {
        -webkit-filter: brightness(1);
        filter: brightness(1);
      }
    }

    // .training-schedule-video:hover {
    //   transition-delay: 0s;
    //   filter: brightness(60%);
    // }

    .training-schedule-hour {
      width: 42%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .no-training-call-up-message {
    text-align: center;
    .no-training-call-up-message-text {
      font-size: 15px;
      font-weight: 600;
      padding-top: 5px;
    }
  }
}

@media (max-width: 767px) {
  .training-page-container {
    .training-page-title {
      font-size: 20px;
      font-weight: 700;
      color: black;
      margin-bottom: 20px;
      margin-left: 10px;
    }
  }

  .training-name {
    margin-left: 20px;
    display: block;
    margin-bottom: 20px;
    font-size: 20px;
  }

  // .col-lg-8 {
  //   margin-bottom: 20px;
  // }

  .training-page-container
    .training-page-players-list
    .training-player-list-table-header {
    flex-direction: column;
    .player-name-header {
      width: 100% !important;
    }

    .player-double-parameter-header {
      width: 100% !important;
      text-align: left !important;
    }
  }
}

// .training-view-details {
//   display: flex;
// }

@media only screen and (max-width: 1520px) {
  // .row div {
  //   max-width: 100%;
  // }

  // .row > div {
  //   margin-bottom: 100px;
  // }
}

@media only screen and (max-width: 991px) {
  .physical-tracking-results {
    display: none;
  }
}
