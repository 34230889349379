.delete-category-popup {
  .delete-category-text {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
    color: #666666;
  }
  .delete-category-buttons {
    height: 50px;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    color: white !important;
    font-weight: 700;
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;

    .grey-btn {
      background-color: #666666 !important;
    }

    .delete-category-button {
      width: 48%;
      height: 50px;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      color: white !important;
      font-weight: 700;
      font-size: 14px;
      font-family: "Poppins", sans-serif !important;
    }

    .button-content {
      display: flex;
      align-items: center;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px;
      justify-content: space-between;
    }
  }
}
