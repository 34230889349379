@media (max-width: 1200px) {
  .team-see-more {
    gap: 5px !important;
  }

  .active-teams-container {
    padding: 40px 20px !important;
  }
}

@media (max-width: 991px) {
  .col-xxl-8,
  .col-xxl-4 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .team-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .team-container .team-content {
    display: flex;
    flex-direction: row;
  }

  .team-row.active-teams-table-columns {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .team-row {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .active-teams-container .active-teams-list .team-row {
    height: auto !important;
  }

  .team-container .team-row.active-teams-table-columns {
    display: flex !important;
  }

  .active-teams-list > .team-row.active-teams-table-columns {
    display: none !important;
  }

  // .add-team-wrapper {
  //   width: 100% !important;
  //   .add-team-container {
  //     padding-left: 0;
  //     padding-right: 0;
  //     .add-team-header {
  //       align-items: center;
  //       margin-bottom: 10px;
  //     }

  //     .add-team-buttons {
  //       .add-button {
  //         width: 100%;
  //       }
  //     }
  //   }
  // }

  .switch-team-player {
    padding: 0 10px !important;
    // margin-bottom: 30px;
    bottom: 0px !important;
  }

  .on-loan-players {
    padding-top: 0px !important;
  }

  .active-teams-container {
    padding: 0px !important;
    .active-teams-header {
      padding: 0 10px;
      div:nth-child(2) {
        flex-direction: column;
        width: 100%;
        .add-team-button {
          width: 100%;
        }

        .dark-background {
          margin-bottom: 10px;
        }
      }
    }
    .active-teams-list {
      .team-container {
        border-top: 1px solid #d2d2d2;
        // border-bottom: 1px solid #d2d2d2;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        // margin-bottom: 20px;
      }

      .team-row {
        padding-left: 0px !important;
        padding: 15px 5px 5px 10px !important;
        .team-name {
          width: 100% !important;
        }

        .team-category,
        .team-nr-players,
        .team-coach {
          width: 100% !important;
          text-align: left !important;
        }

        .team-see-more {
          height: 45px;
          width: 100% !important;
          // text-align: center;
          display: flex;
          justify-content: flex-end;
          padding-right: 5px;
        }
      }
    }
  }

  .MuiPaper-root {
    width: 100% !important;
  }

  .roles-list-wrapper {
    width: 100% !important;
  }
}

@media (max-width: 548px) {
  .active-teams-container {
    .active-teams-header {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }

  .staff-members-container {
    .staff-members-header {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
}

.team-container .team-content .team-row.active-teams-table-columns {
  display: none;
}

.active-teams-container {
  padding: 40px;
  .active-teams-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .active-teams-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .active-teams-list {
    .active-teams-table-columns {
      color: #b3b3b3 !important;
      font-weight: 600;
    }
    .team-row {
      display: flex;
      height: 60px;
      align-items: center;
      font-weight: 700;
      color: #666666;
      padding-left: 15px;

      .team-name {
        width: 20%;
        cursor: pointer;
      }
      .team-category {
        width: 25%;
        text-align: center;
      }
      .team-nr-players {
        width: 15%;
        text-align: center;
      }
      .team-coach {
        width: 20%;
        text-align: center;
      }
      .team-see-more {
        width: 20%;
        text-align: center;
        display: flex;
        gap: 10px;

        .team-see-more-button {
          background-color: #666666;
          width: 35px;
          height: 35px;
          padding: 7px;
          border-radius: 5px;
          cursor: pointer;

          .icon-notification {
            .notification-container {
              position: relative;
              top: -35px;
              right: -15px;
              .notification-circle {
                background-color: red;
                border-radius: 10px;
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                .notification-number {
                  color: white;
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.add-team-button {
  background-color: var(--primaryColor) !important;
  height: 50px;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  color: white !important;
  display: flex;
  align-items: center;

  .add-team-button-icon {
    color: white;
    padding-left: 20px;
    opacity: 1 !important;
  }
}

.dark-background {
  background-color: #666666 !important;
}

.add-team-container {
  padding-left: 26px;
  padding-right: 26px;

  .add-team-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .add-team-title {
      font-size: 20px;
      line-height: 31.75px;
      font-weight: bold;
    }
  }

  .custom-select-wrapper {
    margin-bottom: 25px;
  }

  .add-team-season {
    margin-bottom: 25px;
    .MuiInputBase-root,
    .MuiOutlinedInput-root,
    .MuiInputBase-formControl {
      width: 100%;
    }
  }

  .license-date-end {
    .MuiInputBase-root,
    .MuiOutlinedInput-root,
    .MuiInputBase-formControl {
      width: 100%;
    }
  }

  .add-team-row {
    height: 50px;
    margin-bottom: 15px;
    position: relative;
    background-color: #fafafa;

    .add-team-input {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666666;
    }

    .add-team-select {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666666;

      option {
        padding-left: 25px;
        background-color: #fafafa;
        color: #666666;
        font-size: 13px;
      }
    }
  }

  .tm-link-input {
    color: #666666 !important;
    font-weight: 700;
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;
    width: 100%;
    padding: 15px 15px 15px 30px;
    background-color: #fafafa !important;
    border: 0;
    margin-bottom: 15px;
  }

  .add-team-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    height: 50px;

    .cancel-button {
      background-color: #666666 !important;
      color: white !important;
      height: 100%;
    }

    .add-button {
      background-color: var(--primaryColor) !important;
      color: white !important;
      height: 100%;
    }
  }
}

// .add-team-wrapper {
//   width: 450px;
// }

.close-icon-popup {
  cursor: pointer;
  font-size: 20px !important;
}

.team-add-date-input {
  width: 100%;
  height: 50px;
  background-color: #fafafa;

  div {
    color: #666666;
    font-weight: 700;
    padding-left: 15px;
  }

  fieldset {
    border-color: #fafafa;
  }
}

@media (min-width: 846px) {
  .active-teams-export-players-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
    gap: 20px;
    .title {
      margin: 50px 0px;
    }

    .filters-wrapper {
      display: flex;
      align-items: flex-end;

      .dates-filters {
        display: flex;
        width: 47%;
        align-items: center;
      }
      .filter-buttons {
        display: flex;
        gap: 10px;
        width: 53%;
        height: 50px;
        margin-bottom: "3px";

        .reset-button {
          width: 50%;
        }
      }
    }

    .export-buttons-wrapper {
      display: flex;
      gap: 10px;
    }
  }
}

@media (max-width: 846px) {
  .active-teams-export-players-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
      margin: 50px 0px;
    }

    .filters-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 25px;

      .dates-filters {
        display: flex;
        width: 100%;
        align-items: center;

        .players-date-pickers {
          width: 100%;

          .date-picker {
            width: 100%;
          }
        }
      }
      .filter-buttons {
        display: flex;
        gap: 10px;
        width: 100%;
        height: 50px;
        margin-bottom: "3px";

        .reset-button {
          width: 100%;
        }
      }
    }

    .export-buttons-wrapper {
      display: flex;
      gap: 10px;

      .export-inactive-players,
      .export-added-players {
        width: 50%;
      }
    }
  }
}

@media (max-width: 1286px) {
  .filter-buttons {
    justify-content: center;
  }
}
