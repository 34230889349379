.playerAvatarWrapper {
  display: flex;
  gap: 5px;
  align-items: center;

  .playerName {
    color: #666666;
    cursor: pointer;
    font-family: "Poppins";
    font-weight: 700;
    font-size: 14px;
    word-break: keep-all;
  }

  .matricol {
    font-size: 11px;
    color: var(--primaryColor);
    font-weight: 700;
    cursor: pointer;
  }

  .reason {
    font-size: 11px;
    color: red;
    font-weight: 700;
  }
}
