.addNews {
    :global .df-g20 {
        flex-direction: column;
        .fileInput {
            width: 50%;
        }
    }
    .sectionsDragContainer {
        border: 2px dashed #ededed;
        position: relative;
        margin-bottom: 65px;
        padding: 25px;
        display: flex;
        flex-direction: column;
        min-height: 175px;
    }

    .addSectionBtnGrid {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .submitGroupBtn {
        display: flex;
        gap: 20px;
        margin-top: 40px;

        .addArticleBtn {
            width: 66%;
        }

        .cancelArticleBtn {
            width: 33%;
        }
    }

    .newsContainer {
        .newsTitle {
            color: black;
            font-family: "Poppins";
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.18px;
            margin-bottom: 20px;
        }

        .newsTagsContainer {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            margin-bottom: 20px;

            .newsTag {
                display: flex;
                height: 36px;
                padding: 10px;
                align-items: center;
                flex-shrink: 0;
                border-radius: 18px;
                background: rgba(33, 33, 33, 0.08);

                color: rgba(0, 0, 0, 0.87);
                font-family: "Poppins";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.25px;
            }
        }

        .content_text {
            color: rgba(0, 0, 0, 0.87);
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.5px;
            margin-bottom: 20px;
        }
        .sub_title_text {
            color: rgba(0, 0, 0, 0.87);
            font-family: "Poppins";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.15px;
            margin-bottom: 20px;
        }
        .section_image,
        .news_main_img {
            margin-bottom: 20px;
            text-align: center;
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .addNews {
        .fileInput,
        .selectComponent {
            width: 100%;
        }

        .sectionsDragContainer {
            margin-bottom: 35px;
            padding: 10px;
        }

        .submitGroupBtn {
            flex-direction: column-reverse;

            .addArticleBtn {
                width: 100%;
            }

            .cancelArticleBtn {
                width: 100%;
            }
        }
    }
}
