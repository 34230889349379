.tfbCurrencyInputGroup {
    width: 100%;

    .tfbCurrencyInputLabel {
        color: #b2b2b2;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
    }

    .tfbCurrencyInput {
        display: flex;
        gap: 10px;

        .tfbCurrencyInputValue {
            width: 80%;
        }

        .tfbCurrencyInputCurrency {
            width: calc(20% - 10px);
        }
    }
}
