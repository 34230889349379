.add-size-container {
    .size-description-row {
      margin-bottom: 15px;
    }
    .size-sizes-row {
      margin-bottom: 15px;
    }
    .add-file-form-row {
      .add-size-input {
        width: 100%;
        height: 50px;
      }
    }
  }
  