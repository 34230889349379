.tfbInput {
  fieldset {
    border: none;
  }
  input {
    font-size: 14px !important;
    padding: 15.5px 8px 15.5px 22px !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
  }
  input:hover {
    // pointer-events: none;
  }
}

.inputLabel {
  color: #b2b2b2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
}

.inputGroup {
  width: 100%;
}
