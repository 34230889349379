.campaigns-wrapper {
    padding: 50px;
    background: white;
}

.campaigns-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 700;
}

.campaigns-second-header {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.action-group {
    display: flex;
    justify-content: center;
    align-items: center;

    .action-button {
        background-color: #666666;
        width: 35px;
        height: 35px;
        padding: 7px;
        border-radius: 5px;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 10px;
        }

        ::before {
            color: white;
        }
    }
}

.close-dialog {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
    .close-dialog-icon {
        color: black;
    }
}

.dialog-title {
    font-size: 20px;
    line-height: 32px;
    color: black;
    font-weight: 700;
    margin-bottom: 60px;
}

.dialog-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;

    .ui.button {
        color: white;
        font-size: 14px;
        line-height: 32px;
        color: #ffffff;
        font-weight: 700;
        margin: 0;
        display: flex;
        justify-content: space-between;
    }
    i {
        color: white;
    }
}

.dialog-input {
    width: 475px;
    height: 50px;
    border-radius: 3px;
    background-color: #fafafa;
    /* Text */
    font-size: 14px;
    line-height: 32px;
    color: #666666;
    font-weight: 700;
    outline: none;
    border: none;
    padding: 0px 20px;

    &::placeholder {
        font-size: 14px;
        line-height: 32px;
        color: #666666;
        font-weight: 700;
    }
}

.open-dialog {
    position: relative;
    padding: 60px 50px !important;
}

.editEmailCampaign-wrapper {
    .MuiPaper-root {
        max-width: 90vw;
    }
}

.editEmailCampaign {
    padding: 65px !important;
    .campaign-name {
        font-size: 25px;
        line-height: 32px;
        color: #000000;
        font-weight: 700;
        text-align: center;
        margin-bottom: 60px;
    }

    .popup-body {
        .subtitle {
            font-size: 20px;
            line-height: 32px;
            color: #000000;
            font-weight: 700;
            margin-top: 45px;
            margin-bottom: 35px;
        }
        .section {
            font-size: 16px;
            line-height: 32px;
            color: #000000;
            font-weight: 700;
            margin-bottom: 25px;
        }

        .group {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;

            &.extend {
                justify-content: space-between;
            }
        }
        
        .description {
            font-size: 13px;
            line-height: 28px;
            color: #b2b2b2;
            font-weight: 700;
        }

        .segment-controls .ui.button {
            font-family: "Poppins";
            height: 50px;
            color: white;
            font-size: 14px;
            line-height: 32px;
            color: white;
            font-weight: 700;
            display: flex;
            justify-content: space-between;

            i {
                color: white;
            }
        }
    }

    .ui.dropdown {
        width: 340px;
        background: #fafafa;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;
        border-radius: 3px;
        margin-right: 10px;
    }

    input {
        margin-right: 10px;
    }

    .email-campaign-final-controls {
        .ui.button {
            height: 50px;
            font-size: 14px;
            line-height: 32px;
            color: #ffffff;
            font-weight: 700;
            font-family: "Poppins";

            &:last-child {
                color: #666666;

                i {
                    color: #666666;
                }
            }
        }

        i {
            color: white;
        }
    }
}

.reportCampaign-wrapper .MuiPaper-root {
    max-width: 70vw;
    width: 100%;
}

.report-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .report-title {
        font-size: 25px;
        line-height: 32px;
        color: #000000;
        font-weight: 700;
    }

    .dialog-main-controls {
        display: flex;
        justify-content: center;
        align-items: center;
        .ui.button {
            font-family: "Poppins";
            color: white;
            background: #666666;
            height: 50px;
            &:not(:last-child) {
                margin-right: 20px;
            }
            i {
                color: white;
                margin-left: 10px !important;
            }

            .eye-icon {
                width: 35px;
                height: 35px;
                padding: 7px;
                border-radius: 5px;
                cursor: pointer;
            }
        }
    }
}

.report-body {
    margin-top: 50px;
    .ui.button {
        font-family: "Poppins";

        &.view-links {
            background: #666666;
            color: white;
            height: 50px;
            width: fit-content;
            align-self: flex-end;
        }
    }
    .card-faded {
        background: #fafafa;
        padding: 50px;
        border: none;
        border-radius: 0px;
        height: 100%;

        .section {
            font-size: 16px;
            line-height: 32px;
            color: #000000;
            font-weight: 700;
            margin-bottom: 25px;
        }
    }

    .top-links-wrapper {
        margin-top: 50px;
        margin-bottom: 20px;

        .top-links-item {
            font-size: 14px;
            line-height: 32px;
            color: #666666;
            font-weight: 700;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 30px;

            &:nth-child(even) {
                background: white;
            }

            &:nth-child(odd) {
                background: #f5f5f5;
            }

            span {
                display: inline-block;
                margin-right: 30px;
                min-width: 15px;
                color: black;
            }
        }
    }

    .recipients-header {
        font-family: "Poppins";
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        
        .recipients-summary {
            margin-left: 30px;
            font-size: 75px;
            line-height: 53px;
            color: #00d948;
            font-weight: 700;
            .description {
                font-size: 14px;
                line-height: initial;
                color: #b2b2b2;
                font-weight: 700;
                margin-bottom: 6px;
            }
        }
    }
    .spacer {
        width: 100%;
        height: 1px;
        background: white;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .description {
        font-size: 14px;
        line-height: 28px;
        color: #b2b2b2;
        font-weight: 700;
    }

    .subtitle {
        font-size: 16px;
        line-height: 32px;
        color: #000000;
        font-weight: 700;
        margin-bottom: 25px;
        &.last {
            margin-bottom: 0px;
        }
    }

    .campaign-number {
        font-size: 50px;
        line-height: 32px;
        color: #00d948;
        font-weight: 700;
        margin-bottom: 30px;
    }

    .quick-stats {
        margin-top: 25px;
        .card {
            display: grid;
            place-content: center;
            justify-items: center;
        }
    }

    .detailed-stats {
        margin-top: 25px;

        .subtitle {
            font-size: 30px;
        }
    }
}