.labelsDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  //   width: 80%;
  .numberInputFilters {
    display: flex;
    gap: 10px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;

  .fileInputs {
    display: flex;
    gap: 9%;
    justify-content: center;

    input {
      height: 96px !important;
    }
    .imgDiv {
      position: relative;

      height: 150px;

      img:hover {
        cursor: pointer;
      }
    }
  }

  .subtitle {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 11px; /* 68.75% */
  }
}
