.headerCell {
  display: flex;
  gap: 10px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  .headerFilter {
    display: flex;
    flex-direction: column;
    gap: 2.5px;
    align-items: center;
    justify-content: center;
  }
}
