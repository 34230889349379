.tfbCheckboxWrapper {
  display: flex;
  gap: 5px;

  input[type="checkbox"] {
    accent-color: var(--primaryColor);
    width: 20px;
    height: 20px;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 2.4px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: #F2F2F2;
    border: 1px solid #F2F2F2 !important;
    padding: 0 !important;
  }

  .light {
    background-color: white;
  }

  .dark {
    background-color: #fafafa;
  }

  input[type="checkbox"]:after {
    content: "\f00c";
    color: white;
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    display: none;
    font-size: 12px !important;
  }

  input[type="checkbox"]:checked {
    background-color: var(--primaryColor) !important;
  }

  input[type="checkbox"]:checked:after {
    display: block;
  }

  input[type="checkbox"]:disabled {
    background-color: #d0d0d0 !important;
  }
}
