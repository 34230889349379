@media only screen and (max-width: 991px) {
  .row-widget {
    margin-bottom: 0px !important;
  }
  .no-margin-bottom {
    margin-bottom: 0px !important;
  }

  .match-widget {
    font-weight: 800;
    padding-left: 10px;
    background-color: white;

    .match-content {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: center;

      .match-home {
        text-align: center;
        width: 40%;
        img {
          height: 60px;
        }
      }

      .match-vs {
        font-size: 20px;
        color: #bcbcbc;
        line-height: 4.6875vw;
        width: 20%;
        text-align: center;
      }

      .match-away {
        text-align: center;
        width: 40%;
        img {
          height: 60px;
        }
      }

      .team-name {
        font-size: 16px;
        color: #727272;
        font-weight: 600;
        padding-top: 0.4vw;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .match-round {
      color: black;
      font-size: 20px;
      margin-bottom: 2vh;
      margin-top: 2vh;
      text-align: center;
    }

    .match-date {
      padding: 1.5vw;
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;
      color: #d3d3d3;
      font-weight: 800;
    }
  }

  .placeholder-round {
    display: flex;
    place-content: center;
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .placeholder-date {
    display: flex;
    place-content: center;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .placeholder-teams {
    display: flex;
    justify-content: space-between;
    padding-left: 27px;
    padding-right: 27px;
    align-items: center;
  }

  .cancelmargins {
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: flex;
    justify-content: space-between;
    background-color: #fafafa !important;
  }
  .dash-next-matches .cancelmargins {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1400px) {
  .dashboard-next-matches-placeholders {
    flex-direction: row !important;
  }
}

@media only screen and (max-width: 991px) {
  .dashboard-next-matches-placeholders {
    flex-direction: column;
  }
}

@media only screen and (min-width: 991px) {
  .next-matches-widget {
    flex-direction: row !important;
  }
  .match-widget {
    font-weight: 800;
    padding-left: 10px;
    width: 32.5%;
    background-color: white;

    .match-content {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: center;

      .match-home {
        text-align: center;
        width: 40%;
        img {
          height: 50px;
        }
      }

      .match-vs {
        font-size: 1.675vw;
        color: #bcbcbc;
        line-height: 4.6875vw;
        width: 20%;
        text-align: center;
      }

      .match-away {
        text-align: center;
        width: 40%;
        img {
          height: 50px;
        }
      }

      .team-name {
        // font-size: 0.8vw;
        color: #727272;
        font-weight: 600;
        padding-top: 0.4vw;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .match-round {
      color: black;
      font-size: 16px;
      margin-bottom: 2vh;
      margin-top: 2vh;
      text-align: center;
    }

    .match-date {
      padding: 1.5vw;
      // font-size: 0.8vw;
      text-transform: uppercase;
      text-align: center;
      color: #d3d3d3;
      font-weight: 800;
    }
  }

  .placeholder-round {
    display: flex;
    place-content: center;
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .placeholder-date {
    display: flex;
    place-content: center;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .placeholder-teams {
    display: flex;
    justify-content: space-between;
    padding-left: 27px;
    padding-right: 27px;
    align-items: center;
  }

  .cancelmargins {
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: flex;
    justify-content: space-between;
    background-color: #fafafa !important;
  }
  .dash-next-matches .cancelmargins {
    justify-content: flex-start;
  }
}
