@media (max-width: 900px) {
  .add-contract-container {
    width: 100%;
  }
}

@media (min-width: 900px) {
  .add-contract-container {
    width: 90%;
  }
}

@media (max-width: 991px) {
  .bonus-options-list-popup-header {
    padding-top: 15px !important;
    margin-bottom: 10px !important;
    padding-left: 25px !important;
    padding-right: 10px !important;
    // .bonus-options-list-title{
    //   font-size: 19px !important;
    // }
  }

  .documents-page-container {
    padding: 0px !important;
    .documents-page-header {
      flex-direction: column;
      padding: 20px 10px;
      padding-bottom: 10px !important;
      .searchbar {
        align-self: flex-start !important;
        margin-bottom: 20px;
        width: 100%;
        .search-field {
          width: 100%;
        }
      }

      .buttons {
        .documents-header-button {
          width: 100%;
        }
      }
    }
  }

  .add-file-container-all {
    width: 100% !important;
    .add-file-form-row {
      justify-content: space-between !important;
      .add-file-form-button {
        margin-left: 0px !important;
        width: 34px;
      }

      .add-file-form-input {
        width: calc(100% - 95px) !important;
      }
    }
  }

  .add-file-container {
    .add-file-buttons-container {
      .add-file-button {
        width: 100%;
      }
    }
  }

  .documents-page-list > .columns-names {
    display: none !important;
  }

  .documents-table-container .documents-table-content .document-row.columns-names {
    display: flex !important;
    flex-direction: column;
    width: 40%;
  }

  .documents-table-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .documents-table-container .documents-table-content {
    display: flex;
    flex-direction: row;
  }

  .documents-table-container .documents-table-content .document-row {
    flex-direction: column;
    width: 60%;
    height: 100% !important;
  }

  .documents-table-container {
    .document-name {
      width: 100% !important;
      height: 40px;
      padding-left: 0px !important;
    }

    .document-category,
    .document-date-add,
    .document-added-by {
      width: 100% !important;
      text-align: left !important;
    }

    .document-options {
      height: 48px !important;
      width: 100% !important;
      display: flex;
      justify-content: flex-end !important;
      margin-right: 20px;
    }
  }

  .documents-page-list {
    .documents-table-container {
      border-top: 1px solid #d2d2d2;
      // border-bottom: 1px solid #d2d2d2;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      // margin-bottom: 20px;
    }

    .document-row,
    .columns-names {
      padding: 10px 10px;
    }
  }

  //Player Contracts
  .contracts-section {
    padding: 0px !important;
    .contracts-table-header {
      flex-direction: column;
      padding: 20px;
      align-items: flex-start !important;

      .contracts-section-buttons {
        width: 100%;
        flex-direction: column;
        .bonus-list-button {
          margin-bottom: 10px;
        }

        .contracts-teams-dropdown {
          margin-left: 0px !important;
        }
      }

      //Staff

      .staff-header-buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }

  .contracts-list-container > .contracts-row-table-column-names {
    display: none !important;
  }

  .contracts-table-container .contracts-table-content .contracts-row-table-column-names {
    display: flex !important;
    flex-direction: column;
    width: 40%;
  }

  .contracts-table-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contracts-table-container .contracts-table-content {
    display: flex;
    flex-direction: row;
  }

  .contracts-table-container .contracts-table-content .contracts-row {
    flex-direction: column !important;
    width: 60% !important;
    height: 100% !important;
  }

  .contracts-table-container {
    .contract-player-name {
      width: 100% !important;
      height: 45px;
      padding-left: 0px !important;
      justify-content: flex-start !important;
      // display: unset !important;
    }

    .contract-player-team {
      width: 100% !important;
      padding-left: 0px !important;
      justify-content: flex-start !important;
      height: 23px !important;
    }

    .contract-date-start,
    .contract-date-end,
    .contract-wage {
      width: 100% !important;
      text-align: left !important;
    }

    .contract-options {
      height: 48px !important;
      width: 100% !important;
      display: flex;
      justify-content: flex-end !important;
      margin-right: 20px;
    }
  }

  .contracts-list-container {
    .contracts-table-container {
      border-top: 1px solid #d2d2d2;
      border-bottom: 1px solid #d2d2d2;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      margin-bottom: 20px;
    }

    .contracts-row,
    .contracts-row-table-column-names {
      padding: 10px 10px;
    }
  }
}

@media (max-width: 1224px) {
  .contracts-section {
    padding: 0 30px 30px 30px !important;

    .contracts-list-container {
      .contracts-table-container {
        .contracts-table-content {
          .contracts-row {
            .contract-player-name {
              padding-left: 15px;
            }
            .contract-options {
              .contract-see-more-button {
                margin-right: 15px;
              }
              .expanded-arrow-button {
                img {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
      .contracts-row {
        .contract-player-name {
          padding-left: 15px;
        }
        .contract-options {
          .contract-see-more-button {
            margin-right: 15px;
          }
          .expanded-arrow-button {
            img {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

.documents-table-container .columns-names {
  display: flex;
}

.documents-table-container .columns-names {
  display: none !important;
}

.contracts-table-container .contracts-row-table-column-names {
  display: flex;
}

.contracts-table-container .contracts-row-table-column-names {
  display: none !important;
}

.placeholder-files {
  text-align: center;
  padding-top: 20px;
  color: #666666;
  font-weight: 700;
}

.placeholder-text {
  padding-top: 15px;
}

.view-options {
  align-self: center;
}

.file-type-svg {
  height: 40px;
  width: 40px;
}

.buttons-container {
  display: flex;
  width: 100%;
  place-content: center;
  margin-top: 15px;

  .button-content {
    display: flex;
    justify-content: space-between;
    font-size: 14px !important;
  }
}

.text-area-file-upload {
  margin-top: 25px;
  width: 100%;
}

.new-category-input {
  width: 100%;
  margin-top: 15px;
}

.compare-dropdown {
  margin-left: 10px;
  align-self: center;
  height: 25px;
}

.bonus-value-input {
  width: 80px;
  margin-left: 10px;
  align-self: center;
  margin-bottom: 0 !important;
}

.search-field {
  margin-right: 15px;
  width: 250px;
}

.icon-extra-notes {
  margin-left: 10px !important;
}

.font-weight-600 {
  font-weight: 600;
}

.mr-10 {
  margin-right: 10px;
}

.end-row-options-container {
  display: flex;
}

.add-contract-container {
  margin-left: auto;
  margin-right: auto;
}

//Contracts page

.contracts-section {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  .contracts-table-header {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 45px;
    align-items: center;

    .contracts-section-title {
      font-size: 20px;
      font-weight: 700;
    }

    .bonus-list-button {
      background-color: #666666;
      color: white !important;
      height: 50px;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      // margin-right: 10px;
      font-weight: 700;
      font-family: "Poppins", sans-serif !important;
    }

    .contracts-section-buttons {
      display: flex;

      .contracts-teams-dropdown {
        background-color: var(--primaryColor);
        display: flex;
        align-items: center;
        font-family: "Poppins", sans-serif !important;
        font-weight: 700;
        margin-left: 10px;
        margin-right: 10px;
        height: 50px;

        .divider.default.text {
          color: white;
        }

        i {
          top: 33% !important;
          color: white;
        }

        .divider.text {
          color: white;
        }
      }
    }
  }

  .contracts-list-container {
    .contracts-row-table-column-names {
      color: #b2b2b2;
      font-size: 13px;
      padding-bottom: 15px;
      display: flex;
      font-weight: 700;
    }
    .contracts-row {
      display: flex;
      font-weight: 700;
      height: 80px;
      color: #666666;
      font-size: 14px;
      align-items: center;
    }
    .contract-player-name {
      width: 24%;
      display: flex;
      align-items: center;
      padding-left: 25px;

      .name {
        padding-left: 15px;
      }
    }
    .contract-player-team {
      width: 20%;
      justify-content: center;
      display: flex;

      .team {
        padding-left: 10px;
      }
    }
    .contract-date-start {
      width: 12%;
      text-align: center;
    }
    .contract-date-end {
      width: 12%;
      text-align: center;
    }
    .contract-wage {
      width: 12%;
      text-align: center;
    }
    .contract-options {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .contract-see-more-button {
        background-color: #666666;
        width: 35px;
        height: 35px;
        padding: 7px;
        border-radius: 5px;
        cursor: pointer;
        margin-right: 35px;
        margin-left: 15px;
        font-family: "Poppins", sans-serif !important;
        display: flex;
        justify-content: center;
        .edit-contract-icon {
          color: white !important;
          margin: 0 !important;
        }
      }

      img {
        cursor: pointer;
        height: 10px;
        width: 10px;
        margin-right: 20px;
      }

      .delete-contract {
        width: 35px;
        height: 35px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #b2b2b2;
        margin-left: 15px;
        .delete-contract-icon {
          cursor: pointer;
          color: #fafafa;
          margin: 0 !important;
          height: initial !important;
        }
        .delete-contract-icon:hover {
          color: #d91200;
        }
      }
    }
  }
}

.expanded-row-container {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  .expanded-row-header {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    align-items: center;
    height: 90px;

    .expanded-row-title {
      font-size: 16px;
      color: black;
    }

    .expanded-row-icon {
      padding-left: 5px;
      padding-top: 3px;
      color: var(--primaryColor);
      cursor: pointer;
    }

    .add-condition-expaneded-button {
      background-color: var(--primaryColor) !important;
      font-size: 14px;
      color: white !important;
      font-weight: 700;
      width: 170px;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
    }
  }

  .bonus-condition-row {
    font-weight: 700;
    font-size: 13px;
    color: #b2b2b2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 25px;
    padding-left: 25px;
    height: 55px;
    .amount-container {
      display: flex;
      gap: 25px;
      .amount {
        color: var(--primaryColor);
      }
      .deleted-icon {
        cursor: pointer;
        .trash.icon {
          // color: #d91200;
        }
      }
    }
    .amount-total {
      color: var(--primaryColor);
      padding-right: 45px;
    }
  }
}

.bonus-options-list-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  margin-bottom: 10px;

  .bonus-options-list-title {
    font-size: 20px;
    font-weight: 700;
  }
}

.options-container {
  margin-bottom: 30px;
  .options-list {
    max-height: 290px;
    overflow-y: auto;
    margin-bottom: 25px;
  }

  .options-list::-webkit-scrollbar {
    width: 5px;
  }

  .options-list::-webkit-scrollbar-track {
    background-color: white;
  }

  .options-list::-webkit-scrollbar-thumb {
    background-color: #666666;
    height: 50px;
  }

  .option-row {
    min-height: 55px;
    align-items: center;
    color: #666666;
    display: flex;
    font-weight: 700;
    padding-left: 25px;
  }
}

.add-option-container {
  display: flex;
  justify-content: flex-end;

  .add-option-input {
    width: 280px;
    margin-right: 15px;
  }

  .add-option-button {
    background-color: var(--primaryColor) !important;
    color: white !important;
    height: 50px;
    width: 45%;
    font-size: 13px !important;
    font-weight: 700;
    font-family: "Poppins", sans-serif !important;
  }
}

.add-option-container-opened {
  background-color: #fafafa;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;

  .text-area-bonus-cond {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding: 10px;
  }
  .add-option-container-opened-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;

    .cancel-bonus-button {
      background-color: #666666;
      height: 50px;
      width: 48%;
      color: white;
      font-size: 13px;
      font-weight: 700;
      font-family: "Poppins", sans-serif !important;
    }

    .add-bonus-button {
      background-color: var(--primaryColor);
      height: 50px;
      width: 48%;
      color: white;
      font-size: 13px;
      font-weight: 700;
      font-family: "Poppins", sans-serif !important;
    }
  }
}

// End contracts page

// ADD BONUS FORM

.add-bonus-container {
  min-width: 550px;
  padding-left: 26px;
  padding-right: 26px;
  .add-bonus-row {
    display: flex;
    margin-bottom: 15px;
    height: 50px;

    .condition-name {
      background-color: #fafafa !important;
      border: 0 !important;
      display: flex !important;
      align-items: center;
      padding-left: 20px !important;
      font-weight: 700;
      height: 50px;
      width: 100%;

      div {
        color: #b2b2b2 !important;
        font-size: 14px !important;
      }

      i {
        margin: 0 !important;
      }
    }

    .condition-value {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #b2b2b2;
    }

    .condition-amount {
      width: 70%;

      .euro-icon {
        color: var(--primaryColor);
      }
    }
  }

  .add-bonus-buttons-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    height: 50px;

    .custom-button-add-bonus {
      height: 50px;
      width: 48%;
      font-family: "Poppins", sans-serif !important;
      font-size: 14px !important;
      font-weight: 700;
    }

    .cancel-button {
      background-color: #d4d4d4 !important;
      color: #666666;
    }
    .add-button {
      background-color: #666666 !important;
      color: white;
    }

    .green-button {
      background-color: #00d948 !important;
      color: white;
    }
  }

  .active-cond-number {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 25px;
  }
}

.darkgrey-icon {
  color: #666666;
}
.conditions-container {
  margin-bottom: 40px;
  .condition-container {
    display: flex;
    height: 55px;
    align-items: center;
    padding-left: 25px;
    font-weight: 700;
    font-size: 14px;

    .condition-name {
      color: #666666;
      display: flex;
    }

    .condition-value {
      color: var(--primaryColor);
      margin-left: 10px;
    }
  }
}

.currency-toggler {
  height: 35px;
}

// END ADD BONUS FORM

.active-contracts-placeholder {
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.euro-icon {
  color: var(--primaryColor);
}

.button-flex-content {
  display: flex;
  justify-content: space-between;

  img {
    height: 12px;
  }

  .add-white-icon {
    color: white;
  }
}

// DOCUMENTS PAGE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.documents-page-container {
  padding: 50px;
  .documents-page-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;

    .searchbar {
      align-self: center;
    }

    .buttons {
      display: flex;
      align-items: center;

      .documents-header-button {
        background-color: var(--primaryColor) !important;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        height: 50px;
        margin-bottom: 10px;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        min-width: 200px;
      }

      .documents-icon {
        opacity: 1 !important;
        color: white;
      }
    }
  }

  .documents-page-list {
    .columns-names {
      color: #b2b2b2 !important;
    }
    .document-row {
      display: flex;
      align-items: center;
      min-height: 60px;
      padding: 10px 0;
      font-size: 13px;
      font-weight: 700;
      color: #666666;

      .document-name {
        width: 30%;
        display: flex;
        align-items: center;
        padding-left: 10px;

        img {
          margin-right: 15px;
        }
      }
      .document-category {
        text-align: center;
        width: 20%;
      }
      .document-date-add {
        text-align: center;
        width: 20%;
      }
      .document-added-by {
        text-align: center;
        width: 20%;
      }
      .document-options {
        width: 10%;

        .delete-icon-documents {
          color: black;
          cursor: pointer;
        }
      }
    }
  }
}

// STAFF MEMBERS PAGE =====================================

.staff-header-buttons {
  display: flex;
}

//END STAFF MEMBERS PAGE =====================================

//ADD FILE FORM ============================================

.add-file-container-all {
  width: 570px;
}
.add-file-form-row {
  display: flex;
  height: 50px;
  margin-bottom: 15px;
  align-items: center;

  .add-file-form-dropdown {
    background-color: #fafafa !important;
    height: 100%;
    border: 0 !important;
    font-size: 14px !important;
    font-weight: 700;
    color: #666666 !important;
    padding-left: 20px;
    margin-right: 15px;
    width: calc(100% - 60px);
    display: flex !important;
    align-items: center;

    .default.text {
      color: #666666 !important;
      padding-left: 5px !important;
    }
  }

  .add-file-form-input {
    background-color: #fafafa;
    height: 100%;
    border: 0;
    font-size: 14px !important;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding-left: 20px;
    width: calc(100% - 130px);
    border-radius: 3px;
  }

  .add-file-form-file-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .add-file-form-file-label {
    font-weight: 700;
    font-size: 14px;
    color: #666666;
    padding-left: 20px;
    cursor: pointer;
  }
}

.add-file-form-button {
  height: 100%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-left: 15px;
  cursor: pointer;
}

.dark-grey-button {
  background-color: #666666 !important;
}

.light-grey-button {
  background-color: #b2b2b2 !important;
}

.white-button-icon {
  color: white !important;
  margin: 0 !important;
}
.green-button {
  background-color: #00d948 !important;
}

.grey-button-icon {
  color: #666666 !important;
  margin: 0 !important;
}

.text-area-file-form {
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: #b2b2b2 !important;
  padding: 10px;
  padding-left: 20px;
  border: 0;
  background-color: #fafafa;
}

.add-file-buttons-container {
  display: flex;
  height: 50px;
  margin-bottom: 25px;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;

  .add-file-button {
    background-color: var(--primaryColor) !important;
    width: 47%;
    font-family: "Poppins", sans-serif !important;
    color: white !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 700;
    height: 100%;

    .add-file-button-icon {
      opacity: 1 !important;
      color: white;
    }
  }

  .cancel-button {
    background-color: #666666 !important;
  }
}

//END ADD FILE FORM =========================================

//ADD PLAYER CONTRACT FORM ==================================

.add-contract-container {
  .add-contract-row {
    display: flex;
    height: 50px;
    margin-bottom: 15px;
    align-items: center;
    background-color: #fafafa;
    position: relative;

    .calendar-icon {
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    .add-contract-form-dropdown {
      background-color: #fafafa;
      height: 100%;
      border: 0;
      font-size: 14px !important;
      font-weight: 700;
      color: #b2b2b2;
      padding-left: 20px;
      border-radius: 3px;
      width: 100%;
    }

    .add-contract-form-input {
      background-color: #fafafa;
      height: 100%;
      border: 0;
      font-size: 14px !important;
      font-weight: 700;
      color: #666666 !important;
      padding-left: 20px;
      width: calc(100% - 130px);
      border-radius: 3px;
    }

    .player-name-dropdown {
      width: calc(100% - 60px);
    }

    .add-contract-file-input {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    .add-contract-file-label {
      font-weight: 700;
      font-size: 14px;
      color: #b2b2b2;
      padding-left: 20px;
      cursor: pointer;
    }
  }

  .add-contract-row-extra-text {
    font-size: 13px;
    color: #b2b2b2;
    margin-bottom: 50px;
    font-weight: 600;
  }

  .contract-date-input {
    width: 100%;
    height: 50px;
    background-color: #fafafa;

    div {
      color: #b2b2b2;
      font-weight: 700;
      padding-left: 5px;
    }

    fieldset {
      border-color: #fafafa;
    }
  }
}

.currency-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  .currency-checkbox {
    height: 10px;
    width: 10px;
    border-radius: 3px;
    margin-right: 15px;
  }

  .currency-checkbox-selected {
    background-color: #666666;
  }

  .currency-name {
    font-size: 14px;
    font-weight: 700;
    color: #b2b2b2;
  }
}

//END ADD PLAYER CONTRACT FORM ==============================

.remove-contract-container {
  // min-width: 350px;
  width: 100%;

  .remove-contract-row {
    height: 50px;
    margin-bottom: 20px;
    position: relative;
  }

  .remove-contract-dropdown {
    background-color: #fafafa !important;
    display: flex !important;
    align-items: center !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    height: 50px;
    margin-right: 20px;
    width: 100%;
    border: 0 !important;

    .divider.default.text {
      color: #666666 !important;
    }

    i {
      color: #666666;
    }

    .divider.text {
      color: #666666 !important;
    }
  }
}

.remove-contract-date-input {
  width: 100%;
  height: 50px;
  background-color: #fafafa;
  div {
    color: #666666;
    font-weight: 700;
    padding-left: 5px;
  }

  fieldset {
    border-color: white;
  }
}

.calendar-icon {
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.remove-contract-buttons {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  .remove-contract-cancel-button {
    background-color: #666666 !important;
    color: white !important;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-right: 10px;
    font-weight: 700;
    font-family: "Poppins", sans-serif !important;
    width: 47%;
  }

  .remove-contract-confirm-button {
    background-color: #00d948 !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 12px !important;
    color: white !important;
    height: 50px;
    margin-bottom: 10px;
    width: 47%;
  }
}

video {
  max-width: 100%;
  height: auto;
}
.close-video {
  padding: 10px 4px 10px 0px;
}
.document-name[type="video.svg"] {
  cursor: pointer;
}

.delete-bonus-query {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 25px;
  color: #666666;
}

.delete-bonus-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  .delete-bonus-button {
    width: 48%;
    height: 50px;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    color: white !important;
    font-weight: 700;
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;

    .delete-player-icon {
      opacity: 1 !important;
      color: white;
    }
  }

  .grey-btn {
    background-color: #666666 !important;
  }
}
