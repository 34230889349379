.rpe-filter-trainings-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .rpe-select-team-wrapper {
    // height: 50px;
  }

  .rpe-training-date-pickers {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;

    .rpe-training-date-input {
      width: 48%;
      background-color: #fafafa;

      .MuiOutlinedInput-root {
        position: relative;
        border-radius: 4px;
      }
      .MuiOutlinedInput-input {
        padding: 18.5px 14px;
      }

      div {
        color: #b2b2b2;
        font-size: 14px;
        font-weight: 700;
        padding-left: 5px;
      }
    }
  }

  .rpe-view-selected-training-wrapper {
    display: flex;
    height: 50px;
    align-items: center;
    margin-bottom: 20px;

    .selected-training-button {
      background-color: var(--primaryColor) !important;
      color: white !important;
      font-family: "Poppins", sans-serif !important;
      font-weight: 700;
      height: 50px;
      margin-right: 20px;

      .button-content {
        align-items: center;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px;
      }
    }
  }
}

.rpe-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  .rpe-page-title {
    font-weight: 700;
    color: black;
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  .rpe-page-header-right {
    display: flex;
    align-items: center;
    height: 50px;

    .add-player-button {
      background-color: var(--primaryColor) !important;
      color: white !important;
      font-family: "Poppins", sans-serif !important;
      font-weight: 700;
      height: 50px;

      .button-content {
        display: flex;
        align-items: center;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px;
      }
    }

    .present-list-button {
      background-color: #666666 !important;
      color: white !important;
      font-family: "Poppins", sans-serif !important;
      font-weight: 700;
      height: 50px;

      .button-content {
        display: flex;
        align-items: center;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px;
      }
    }
  }
}

.overall-stats-training-rpe {
  display: flex;
  width: 100%;
  gap: 26px;
  margin-top: 20px;
  margin-bottom: 50px;

  .rpe-duration,
  .rpe-before,
  .rpe-after,
  .rpe-impact,
  .rpe-global-acumulation {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fafafa;
    padding: 12px 20px;
    width: 100%;

    .title {
      font-size: 13px;
      line-height: 32px;
      color: #b3b3b3;
      font-weight: 700;
      font-family: "Poppins";
    }

    .text {
      font-size: 16px;
      line-height: 32px;
      font-weight: 700;
      font-family: "Poppins";
    }
  }

  .rpe-duration {
    color: #aa00ff;
    .wrapper-duration-edit {
      display: flex;
      justify-content: space-between;

      .edit-duration-rpe {
        background-color: #666;
        width: 30px;
        height: 30px;
        padding: 7px;
        border-radius: 5px;
        cursor: pointer;

        .edit-icon {
          color: #fafafa;
          position: relative;
          left: 2px;
          bottom: 1px;
        }
      }
    }
  }

  .rpe-before {
    color: #ff9500;
  }
  .rpe-after {
    color: #00aaff;
  }
  .rpe-impact {
    color: #ff4de1;
  }
  .rpe-global-acumulation {
    color: #ffc300;
  }
}

.rpe-players-table {
  .rpe-players-table-header {
    font-size: 13px;
    font-weight: 700;
    color: #b2b2b2;
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    .player-name {
      width: 16%;
      display: flex;
      align-items: center;
    }

    .was-present-column {
      width: 5%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rpe-column {
      width: 10%;
      display: flex;
      justify-content: center;
    }

    .player-options {
      width: 3%;
      display: flex;
      justify-content: center;
    }

    .variability {
      width: 11%;
      display: flex;
      justify-content: center;
      text-align: center;
    }

    .injury {
      width: 16%;
      display: flex;
      justify-content: center;
    }
  }

  .rpe-players-table-list {
    .rpe-players-container {
      .rpe-players-content {
        .club-players-table-header {
          display: none;
        }
        .rpe-players-table-row {
          display: flex;
          align-items: center;
          min-height: 80px;
          font-weight: 700;
          font-size: 14px;
          color: #666666;
          padding-left: 20px;
          padding-right: 20px;

          .rpe-input {
            width: 70px;
            input {
              font-size: 14px;
              font-weight: 700;
              font-family: "Poppins";
              text-align: center;
            }
          }

          .add-value-input-rpe1 {
            input {
              color: #ff9500 !important;
            }
          }

          .add-value-input-time {
            input {
              color: #aa00ff !important;
            }
          }

          .add-value-input-rpe2 {
            input {
              color: #00aaff !important;
            }
          }

          .add-value-input-weight {
            input {
              color: #5639e6 !important;
            }
          }

          .player-name {
            width: 16%;
            display: flex;
            align-items: center;
            gap: 5px;
          }

          .was-present-column {
            width: 5%;
            display: flex;
            align-items: center;
            justify-content: center;

            .red-icon {
              color: #d91200;
            }

            .green-icon {
              color: #00d948;
            }
          }

          .injury {
            width: 14%;
            display: flex;
            justify-content: center;
          }

          .rpe-column {
            width: 10%;
            display: flex;
            justify-content: center;
          }

          .variability {
            width: 11%;
            display: flex;
            justify-content: center;
          }

          .ua {
            .ua-value {
              width: 70px;
              text-align: center;
              font-size: 14px;
              font-weight: 700;
              font-family: "Poppins";
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            
            .ua-optimal {
              color: white;
              background-color: var(--primaryColor);
              border: 1px solid rgba(34, 36, 38, 0.15);
              border-radius: .28571429rem;
            }

            .ua-low {
              color: white;
              background-color: #80d4ff;
              border: 1px solid rgba(34, 36, 38, 0.15);
              border-radius: .28571429rem;
            }

            .ua-high {
              color: white;
              background-color: #d91200;
              border: 1px solid rgba(34, 36, 38, 0.15);
              border-radius: .28571429rem;
            }
          }

          .injury {
            width: 16%;
            display: flex;
            justify-content: center;
          }
          .player-rpe-options {
            width: 3%;
            display: flex;
            justify-content: center;

            .delete-rpe-icon-container {
              background-color: #666;
              width: 35px;
              height: 35px;
              padding: 7px;
              border-radius: 5px;
              cursor: pointer;

              .delete-icon {
                color: white;
                position: relative;
                top: 0px;
                left: 2px;
              }
            }

            .trash-hover:hover {
              background-color: #d91200;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.button-wrapper-save-data {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  .add-player-button {
    background-color: var(--primaryColor) !important;
    color: white !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    height: 50px;

    .button-content {
      display: flex;
      align-items: center;
      font-family: "Poppins", sans-serif !important;
      gap: 15px;
    }
  }
}
