@media (min-width: 991px) {
  .add-report-container {
    padding: 50px;

    .add-report-header {
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .add-report-title {
        font-size: 20px;
        font-weight: 700;
      }

      .add-player-button {
        background-color: var(--primaryColor);
        height: 50px;
        width: 185px;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;

        .add-player-button-icon {
          color: white;
          opacity: 1 !important;
        }
      }

      .add-player-button:hover {
        background-color: var(--primaryColor) !important;
      }

      .add-player-button:active {
        background-color: var(--primaryColor) !important;
      }

      .add-player-button:focus {
        background-color: var(--primaryColor) !important;
      }
    }

    .add-report-form {
      margin-bottom: 50px;
      .add-report-row {
        height: 50px;
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;

        .add-report-select {
          width: 100%;
        }

        .add-report-input {
          width: 100%;
          height: 100%;
          border: 0;
          font-size: 14px;
          font-weight: 700;
          padding-left: 20px;
          background-color: #fafafa;
          color: #b2b2b2;
        }

        select {
          width: 47%;
          height: 100%;
          border: 0;
          font-size: 14px;
          font-weight: 700;
          padding-left: 20px;
          background-color: #fafafa;
          color: #b2b2b2;

          option {
            padding-left: 25px;
            background-color: #fafafa;
            color: #b2b2b2;
            font-size: 13px;
          }
        }
      }

      .add-report-text-area {
        width: 100%;
        height: 75px;
        font-size: 14px;
        font-weight: 700;
        color: #666666 !important;
        padding: 10px;
        padding-left: 20px;
        border: 0;
        background-color: #fafafa;
        margin-bottom: 15px;
      }

      .add-report-date-group {
        width: 47%;
        position: relative;
        height: 50px;

        .report-date-input {
          width: 100%;
          height: 50px;
          background-color: #fafafa;

          div {
            color: #b2b2b2;
            font-size: 14px;
            font-weight: 700;
            padding-left: 5px;
          }

          fieldset {
            border-color: #fafafa;
          }
        }

        .calendar-icon {
          position: absolute;
          right: 15px;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }

    .parameters-subtitle {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 25px;
      .add-report-header {
        .add-physical-category-button {
          background-color: var(--primaryColor);
          height: 50px;
          width: 185px;
          font-family: "Poppins", sans-serif !important;
          font-size: 13px !important;
          color: white !important;
          display: flex !important;
          justify-content: space-between;
          align-items: center;

          i {
            color: white;
          }

          .add-physical-category-button-icon {
            color: white;
            opacity: 1 !important;
          }
        }

        .edit-physical-category-button {
          background-color: var(--primaryColor);
          height: 50px;
          width: 185px;
          font-family: "Poppins", sans-serif !important;
          font-size: 13px !important;
          color: white !important;
          display: flex !important;
          justify-content: space-between;
          align-items: center;

          i {
            color: white;
          }

          .add-physical-category-button-icon {
            color: white;
            opacity: 1 !important;
          }
        }
      }
    }

    .added-parameters-list {
      margin-bottom: 30px;
      .added-parameter-row {
        height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-right: 20px;

        .parameter-name {
          font-size: 14px;
          color: #666666;
          font-weight: 700;
        }

        .test-parameter-delete-button {
          background-color: #b9b9b9b9;
          width: 35px;
          height: 35px;
          padding: 7px;
          border-radius: 5px;
          cursor: pointer;

          .test-trash-img {
            position: relative;
            left: 4px;
            color: white;
          }
        }
      }
    }

    .add-player-container {
      margin-bottom: 65px;
      select {
        width: 100%;
        height: 50px;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 20px;
        background-color: #fafafa;
        color: #b2b2b2;
        margin-bottom: 15px;

        option {
          padding-left: 25px;
          background-color: #fafafa;
          color: #b2b2b2;
          font-size: 13px;
        }
      }

      .params-physical-test-list {
        margin-top: 12px;

        .category-name-player {
          font-size: 14px;
          font-weight: 700;
          margin-top: 25px;
        }

        .add-player-param-row {
          height: 55px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 20px;
          padding-right: 10px;

          .physical-test-input-buttons {
            display: flex;
            gap: 5px;
          }

          .parameter-name {
            font-size: 14px;
            color: #666666;
            font-weight: 700;
          }

          .add-player-value-input {
            font-size: 13px;
            font-weight: 700;
            color: #b2b2b2;
            width: 130px;
            text-align: center;
            background-color: #f0f0f0;
            border-radius: 3px;
            border: none;
            height: 35px;
          }
        }
      }
    }

    .add-player-buttons-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 50px;
      margin-top: 25px;

      .add-report-button {
        background-color: var(--primaryColor);
        height: 50px;
        width: 245px;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        margin-left: 15px;
      }

      .cancel-button {
        background-color: #666666 !important;
      }

      .add-report-button-icon {
        color: white;
        opacity: 1 !important;
      }
    }

    .players-list {
      .players-list-table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        font-weight: 700;
        color: #b2b2b2;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 13px;
      }

      .no-evaluation-test-physical-message {
        text-align: center;
        .no-evaluation-test-physical-message-text {
          font-size: 15px;
          font-weight: 600;
          padding-top: 5px;
        }
      }

      .player-row {
        height: 80px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #666666;
        font-weight: 700;

        .player-name {
          display: flex;
          align-items: center;

          .player-avatar {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            margin-right: 15px;
            object-fit: cover;
          }
        }

        img {
          width: 10px;
        }

        .list-test-player-icons {
          display: flex;
          gap: 8px;
          .test-parameter-delete-button,
          .test-parameter-expand-button,
          .test-parameter-edit-button {
            background-color: #666666;
            width: 35px;
            height: 35px;
            padding: 7px;
            border-radius: 5px;
            cursor: pointer;

            img {
              position: relative;
              left: 5px;
            }

            .edit-icon {
              position: relative;
              left: 4px;
            }

            .black-icon {
              color: #fafafa;
            }
          }
          .test-parameter-expand-button {
            i {
              position: relative;
              left: 1px;
              color: #fafafa;
            }
          }

          .test-parameter-delete-button {
            .test-trash-img {
              position: relative;
              left: 2px;
              color: #fafafa;
            }
          }

          .test-parameter-delete-button:hover {
            background-color: #d91200;
          }
        }
      }
    }

    .add-param-dropdown {
      width: 100%;
      height: 50px;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 20px;
      background-color: #fafafa;
      color: #b2b2b2;

      option {
        padding-left: 25px;
        background-color: #fafafa;
        color: #b2b2b2;
        font-size: 13px;
      }
    }

    .add-new-param-row {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      .add-new-param-input {
        width: 65%;
        height: 100%;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 20px;
        background-color: #fafafa;
        color: #b2b2b2;
      }

      .add-new-param-input-um {
        width: 30%;
        min-width: 190px;
        height: 100%;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 20px;
        background-color: #fafafa;
        color: #b2b2b2;
        margin-left: 15px;
      }

      .light-grey-button {
        background-color: #d4d4d4;
      }

      .add-param-buttons {
        display: flex;
        .add-param-button {
          margin-left: 10px;
          width: 50px;
          height: 50px;
          background-color: #666666;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          cursor: pointer;

          .add-param-white-icon {
            color: white;
            opacity: 1 !important;
            margin: 0 !important;
          }
          .add-param-grey-icon {
            color: #666666;
            opacity: 1 !important;
            margin: 0 !important;
          }
        }
      }
    }

    .add-param-button-row {
      margin-top: 25px;
      display: flex;
      justify-content: flex-end;
      .add-param-final-button {
        background-color: var(--primaryColor);
        height: 50px;
        width: 245px;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;

        .add-param-button-icon {
          color: white;
          opacity: 1 !important;
        }
      }
    }

    .player-expanded-row {
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(3, 1fr);
      padding-top: 25px;
      padding-left: 20px;
      padding-right: 20px;

      .param-container {
        width: 90%;
        height: 50px;
        margin-bottom: 25px;
        .param-name {
          font-size: 13px;
          color: #b2b2b2;
          font-weight: 700;
        }
        .param-value {
          font-size: 20px;
          color: var(--primaryColor);
          font-weight: 700;
        }
      }
    }

    .final-report-submit-button-row {
      margin-top: 25px;
      display: flex;
      justify-content: flex-end;
      height: 50px;

      .final-report-submit-button {
        background-color: #00d948;
        height: 50px;
        width: 185px;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;

        .final-report-submit-button-icon {
          color: white;
          opacity: 1 !important;
        }
      }
    }
  }

  .add-player-form {
    .params-physical-test-list {
      margin-top: 12px;

      .add-player-param-row {
        height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 10px;

        .parameter-name {
          font-size: 14px;
          color: #666666;
          width: 50%;
          font-weight: 700;
        }

        .add-player-value-input {
          font-size: 13px;
          font-weight: 700;
          color: #b2b2b2;
          width: 50%;
          text-align: center;
          background-color: #f0f0f0;
          border-radius: 3px;
          border: none;
          height: 35px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .add-report-container {
    padding: 15px;

    .add-report-header {
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;

      .add-report-title {
        font-size: 20px;
        font-weight: 700;
      }

      .add-player-button {
        background-color: var(--primaryColor);
        height: 50px;
        width: 100%;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;

        .add-player-button-icon {
          color: white;
          opacity: 1 !important;
        }
      }
    }

    .add-report-form {
      margin-bottom: 50px;
      .add-report-row {
        height: 50px;
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;

        .add-report-input {
          width: 100%;
          height: 100%;
          border: 0;
          font-size: 14px;
          font-weight: 700;
          padding-left: 20px;
          background-color: #fafafa;
          color: #b2b2b2;
        }

        select {
          width: 47%;
          height: 100%;
          border: 0;
          font-size: 14px;
          font-weight: 700;
          padding-left: 20px;
          background-color: #fafafa;
          color: #b2b2b2;

          option {
            padding-left: 25px;
            background-color: #fafafa;
            color: #b2b2b2;
            font-size: 13px;
          }
        }
      }

      .add-report-text-area {
        width: 100%;
        height: 75px;
        font-size: 14px;
        font-weight: 700;
        color: #666666 !important;
        padding: 10px;
        padding-left: 20px;
        border: 0;
        background-color: #fafafa;
        margin-bottom: 15px;
      }

      .add-report-date-group {
        width: 47%;
        position: relative;
        height: 50px;

        .MuiInputBase-input {
          font-size: 12px !important;
        }

        .report-date-input {
          width: 100%;
          height: 50px;
          background-color: #fafafa;

          div {
            color: #b2b2b2;
            font-size: 14px;
            font-weight: 700;
            padding-left: 5px;
          }

          fieldset {
            border-color: #fafafa;
          }
        }

        .calendar-icon {
          position: absolute;
          right: 15px;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }

    .parameters-subtitle {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 25px;
    }

    .added-parameters-list {
      margin-bottom: 30px;
      .added-parameter-row {
        height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-right: 20px;

        .parameter-name {
          font-size: 14px;
          color: #666666;
          font-weight: 700;
        }
      }
    }

    .add-player-container {
      margin-bottom: 65px;
      select {
        width: 100%;
        height: 50px;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 20px;
        background-color: #fafafa;
        color: #b2b2b2;
        margin-bottom: 15px;

        option {
          padding-left: 25px;
          background-color: #fafafa;
          color: #b2b2b2;
          font-size: 13px;
        }
      }

      .add-player-param-row {
        height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 10px;

        .parameter-name {
          font-size: 14px;
          color: #666666;
          font-weight: 700;
        }

        .add-player-value-input {
          font-size: 13px;
          font-weight: 700;
          color: #b2b2b2;
          width: 130px;
          text-align: center;
          background-color: #f0f0f0;
          border-radius: 3px;
          border: none;
          height: 35px;
        }
      }
    }

    .add-player-buttons-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      height: 50px;
      margin-top: 25px;
      gap: 15px;
      margin-top: 70px;

      .add-report-button {
        background-color: var(--primaryColor);
        min-height: 50px;
        width: 100%;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        margin-left: 15px;
      }

      .cancel-button {
        background-color: #666666 !important;
      }

      .add-report-button-icon {
        color: white;
        opacity: 1 !important;
      }
    }

    .players-list {
      .players-list-table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        font-weight: 700;
        color: #b2b2b2;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 13px;
      }

      .player-row {
        height: 80px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #666666;
        font-weight: 700;

        .player-name {
          display: flex;
          align-items: center;

          .player-avatar {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            margin-right: 15px;
            object-fit: cover;
          }
        }

        img {
          width: 10px;
        }
      }
    }

    .add-param-dropdown {
      width: 100%;
      height: 50px;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 20px;
      background-color: #fafafa;
      color: #b2b2b2;

      option {
        padding-left: 25px;
        background-color: #fafafa;
        color: #b2b2b2;
        font-size: 13px;
      }
    }

    .add-new-param-row {
      // height: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;

      .param-measurement-container {
        display: flex;
        flex-direction: column;
      }

      .add-new-param-input {
        // width: 65%;
        height: 50px;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 20px;
        margin-bottom: 10px;
        background-color: #fafafa;
        color: #b2b2b2;
      }

      .add-new-param-input-um {
        // width: 30%;
        // min-width: 190px;
        height: 50px;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 20px;
        background-color: #fafafa;
        color: #b2b2b2;
        // margin-left: 15px;
        margin-bottom: 10px;
      }

      .light-grey-button {
        background-color: #d4d4d4;
      }

      .add-param-buttons {
        display: flex;
        margin-top: 20px;

        .add-param-button {
          margin-left: 10px;
          width: 50px;
          height: 50px;
          background-color: #666666;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          cursor: pointer;

          .add-param-white-icon {
            color: white;
            opacity: 1 !important;
            margin: 0 !important;
          }
          .add-param-grey-icon {
            color: #666666;
            opacity: 1 !important;
            margin: 0 !important;
          }
        }
      }
    }

    .add-param-button-row {
      margin-top: 25px;
      display: flex;
      justify-content: flex-end;
      .add-param-final-button {
        background-color: var(--primaryColor);
        height: 50px;
        width: 100%;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;

        .add-param-button-icon {
          color: white;
          opacity: 1 !important;
        }
      }
    }

    .player-expanded-row {
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(3, 1fr);
      padding-top: 25px;
      padding-left: 20px;
      padding-right: 20px;

      .param-container {
        width: 33%;
        height: 50px;
        margin-bottom: 25px;
        .param-name {
          font-size: 13px;
          color: #b2b2b2;
          font-weight: 700;
        }
        .param-value {
          font-size: 20px;
          color: var(--primaryColor);
          font-weight: 700;
        }
      }
    }

    .final-report-submit-button-row {
      margin-top: 25px;
      display: flex;
      justify-content: flex-end;
      height: 50px;

      .final-report-submit-button {
        background-color: #00d948;
        height: 50px;
        width: 185px;
        font-family: "Poppins", sans-serif !important;
        font-size: 13px !important;
        color: white !important;
        display: flex !important;
        justify-content: space-between;
        align-items: center;

        .final-report-submit-button-icon {
          color: white;
          opacity: 1 !important;
        }
      }
    }
  }
}
