.delete-match-program-popup-container {
  .delete-match-program-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .delete-match-program-title {
      font-size: 20px;
      font-weight: 700;
    }

    .close {
      cursor: pointer;
    }
  }

  .delete-match-program-text {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  .delete-match-program-buttons {
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 20px;

    .cancel-button {
      color: #fafafa !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      background-color: #666666 !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 47%;
    }

    .delete-match-program-button {
      background-color: #db2828 !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: #fafafa !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 47%;
    }

    .add-file-button {
      background-color: var(--primaryColor) !important;
      width: 47%;
      font-family: "Poppins", sans-serif !important;
      color: white !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 700;
      height: 100%;

      .add-file-button-icon {
        opacity: 1 !important;
        color: white;
      }
    }

    .dark-icon {
      color: #666666;
    }

    .white-icon {
      color: #fafafa;
    }
  }
}
