@media (max-width: 991px) {
  .edit-staff-deteails-container {
    width: 100% !important;
  }

  .staff-members-container {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 10px !important;
  }

  .staff-members-container {
    .staff-members-buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

.MuiDialogContent-root {
  // min-width: 400px !important;
}

.edit-staff-deteails-container {
  // min-width: 400px !important;
}

.staff-members-container {
  padding-top: 40px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 10px;
  .staff-members-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    align-content: flex-start;
    .staff-members-title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .staff-members-buttons {
      .members-list-button {
        background-color: var(--primaryColor);
        font-family: "Poppins", sans-serif !important;
        font-size: 12px !important;
        color: white !important;
        height: 50px;
        margin-bottom: 10px;

        .add-member-button-icon {
          color: white;
          padding-left: 20px;
          opacity: 1 !important;
        }
      }

      .dark-background {
        background-color: #666666 !important;
      }
    }
  }

  .staff-members-list {
    .staff-members-table-columns {
      color: #b3b3b3 !important;
      font-weight: 600;
      padding-left: 50px;
    }

    .staff-member-row {
      display: flex;
      height: 65px;
      align-items: center;
      font-weight: 700;
      color: #666666;
      .staff-member-name {
        width: 54%;
        display: flex;
        align-items: center;
        padding-left: 15px;
      }

      .staff-member-license {
        width: 30%;
        text-align: center;
      }

      .staff-member-role {
        width: 50%;
        margin-left: 6px;
        margin-right: 6px;
        text-align: center;
      }
      .staff-member-see-more {
        width: 20%;
        text-align: center;
        .member-see-more-button {
          background-color: #666666;
          width: 35px;
          height: 35px;
          padding: 7px !important;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
  }
}

.add-staff-container,
.edit-staff-deteails-container {
  padding-left: 26px;
  padding-right: 26px;

  .license-date-end {
    .MuiInputBase-root,
    .MuiOutlinedInput-root,
    .MuiInputBase-formControl {
      width: 100%;
    }
  }

  .add-staff-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .add-staff-title {
      font-size: 20px;
      font-weight: 700;
    }
  }
  .add-staff-row {
    height: 50px;
    margin-bottom: 15px;

    input {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666666;
    }

    select {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666666;

      option {
        padding-left: 25px;
        background-color: #fafafa;
        color: #666666;
        font-size: 13px;
      }
    }
  }

  .add-staff-row-date {
    height: 50px;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    position: relative;

    .add-player-date-input {
      width: 100%;
      height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-weight: 700;
        padding-left: 20px;
        height: 100%;
      }
      ::before {
        border-bottom: 0 !important;
      }

      ::after {
        border-bottom: 0;
      }

      fieldset {
        border-color: #fafafa;
      }
    }
  }

  .edit-staff-row-date {
    height: 50px;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    position: relative;

    .edit-player-date-input {
      width: 100%;
      height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-weight: 700;
        padding-left: 20px;
        height: 100%;
      }
      ::before {
        border-bottom: 0 !important;
      }

      ::after {
        border-bottom: 0;
      }

      fieldset {
        border-color: #fafafa;
      }
    }
  }

  .add-staff-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    height: 50px;

    .custom-button-dual {
      width: 47%;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 12px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .cancel-button {
      background-color: #666666 !important;
      color: white !important;
      height: 100%;
    }

    .add-button {
      background-color: var(--primaryColor) !important;
      color: white !important;
      height: 100%;
    }

    .white-icon {
      color: white;
      padding-left: 20px;
      opacity: 1 !important;
    }
  }
}

.add-staff-wrapper {
  width: 450px;
}

.roles-list-wrapper {
  width: 400px;
}

.close-icon-popup {
  cursor: pointer;
  font-size: 20px !important;
}

.edit-staff-deteails-container {
  width: 25vw;
  .edit-staff-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    .edit-staff-title {
      font-size: 20px;
      font-weight: 700;
      i {
        color: red;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

  .edit-staff-row {
    height: 50px;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fafafa;

    select {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666666;

      option {
        padding-left: 25px;
        background-color: #fafafa;
        color: #666666;
        font-size: 13px;
      }
    }

    .edit-staff-input {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666666;
    }

    .edit-details-file-input {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    .edit-details-file-label {
      font-weight: 700;
      font-size: 14px;
      color: #b2b2b2;
      padding-left: 20px;
      cursor: pointer;
    }
  }

  .edit-staff-text-area {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding: 10px;
    padding-left: 20px;
    border: 0;
    background-color: #fafafa;
    margin-bottom: 15px;
  }

  .edit-staff-editor {
    width: 100%;
    margin-bottom: 15px;
    color: #b2b2b2 !important;

    i {
      font-size: 1rem;
    }
  }

  .edit-details-button {
    width: 48%;
    height: 50px;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primaryColor);
    color: white !important;
    font-weight: 700;
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;

    .edit-details-icon {
      opacity: 1 !important;
      color: white;
    }
  }
}

@media (max-width: 1520px) {
  .staff-members-container {
    .staff-members-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}
