.tabContainer {
  display: flex;
  font-family: "Poppins", sans-serif !important;
  color: #3f4254;
  font-weight: 700;

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 45px;
  }
  .tab:hover {
    cursor: pointer;
  }
}

.topDiv {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.tableChartContainer {
  padding: 50px;
  background-color: #fafafa;
}
