.site-social-media-container {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 70px;
    padding-bottom: 60px;

    .site-social-media-title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 50px;
    }
    .site-social-media-section {
        .site-social-media-section-title {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 20px;
        }
        .site-social-media-row {
            // display: flex;
            width: 100%;
            height: 70px;
            // justify-content: space-between;
            margin-bottom: 25px;

            .input-group {
                // width: 40%;
                height: 100%;

                .input-label {
                    padding-left: 15px;
                    font-size: 13px;
                    color: #666;
                    font-weight: 700;
                    padding-bottom: 5px;
                    // width: 500px;
                }

                .input-check {
                    display: flex;
                    gap: 15px;

                    .social-media-input {
                        height: 50px;
                        width: 500px;
                        border: 0;
                        font-size: 14px;
                        font-weight: 700;
                        padding-left: 15px;
                        background-color: #fafafa;
                        color: #666;
                    }
                }
            }
        }
    }
    .site-social-media-button-row {
        display: flex;
        // justify-content: flex-end;
        height: 50px;

        .site-social-media-details-button {
            // background-color: var(--primaryColor) !important;
            height: 50px;
            // min-width: 250px;
            padding-left: 20px;
            padding-right: 20px;
            font-family: "Poppins", sans-serif !important;
            font-size: 13px !important;
            color: white !important;
            display: flex !important;
            justify-content: space-between;
            align-items: center;
        }

        .site-details-icon {
            opacity: 1 !important;
            color: white;
        }
    }
}

.button-save {
    background-color: var(--primaryColor) !important;
}
::placeholder {
    color: #b2b2b2;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b2b2b2;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b2b2b2;
}

.social-media-input {
    height: 50px;
    // width: 500px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    padding-left: 15px;
    background-color: #fafafa;
    color: #666;
}

.ui.button:not(.icon) > .icon:not(.button):not(.dropdown) {
    margin: 0px 0.428571em 0 0.785714em !important;
}

@media (max-width: 1100px) {
    .social-media-input {
        max-width: 400px !important;
    }
    .site-social-media-container {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

@media (max-width: 550px) {
    .social-media-input {
        max-width: 300px !important;
    }
}

@media (max-width: 380px) {
    .social-media-input {
        max-width: 200px !important;
    }
}
//     .site-social-media-details-button {
//         min-width: 180px !important;
//     }
// }

// @media (max-width: 500px) {
//     .social-media-input {
//         max-width: 400px !important;
//     }

//     .site-social-media-details-button {
//         min-width: 180px !important;
//     }
// }
