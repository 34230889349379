.edit-request-container {
  .pick-birthday {
    label {
      font-weight: 900;
      font-size: 16px;
      margin: 0px;
    }

    .birthday-date-input {
      margin-bottom: 20px;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    .form-group-title {
      font-weight: 900;
      font-size: 16px;
      margin-top: 10px;
    }

    &:first-child {
      .form-group-title {
        margin-top: 0px;
      }
    }

    .add-input {
      height: 50px;
      font-size: 14px !important;
      font-weight: 700;
      color: #666666 !important;
      width: 100%;
    }

    .input-wrapper {
      .PhoneInputInput {
        height: 50px !important;
      }
    }
  }

  .edit-request-footer-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 34px;
    gap: 10px;
  }
}
