.accept-request-popup-content {
  @mixin styleRow {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }

  .enrolment-accept-row {
    @include styleRow;
  }

  .enrolment-accept-row-align {
    @include styleRow;
    align-items: end;
  }

  .tfb-dropdown > div:first-of-type {
    display: flex;
    gap: 10px;
  }
}

@media only screen and (max-width: 484px) {
  .accept-request-popup-content {
    .enrolment-accept-row {
      flex-direction: column;
    }

    .enrolment-accept-row-align {
      flex-direction: column;
    }
  }
}
