.wrapper {
  width: 100%;
  height: 100%;
  max-height: 75vh;
  overflow-y: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2vw;

  .chatHistoryColumn {
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;
    width: 14vw;
    overflow-y: auto;
    scroll-behavior: smooth;
    max-height: 100%;

    .historyTime {
      font-size: 14px;
      font-weight: 700;
      color: #000;
      margin: 6px 8px 12px 8px;
    }
    .historyTitle {
      font-size: 14px;
      font-weight: 700;
      color: #000;
      margin: 6px 8px 12px 8px;
    }
    .historyItem {
      border-radius: 8px;
      padding: 8px;
      font-weight: 600;
      font-size: 14px;
      font-family: "Poppins", sans-serif !important;
      color: #666;
      &:hover {
        background-color: #f2f2f2;
        cursor: pointer;
      }
    }
  }
  .conversationColumn {
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    // height: 85%;
    max-width: 780px;

    .aiPrompt {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      .aiPromptTitle {
        font-weight: 800;
        font-family: "Poppins", sans-serif !important;
        align-self: center;
        font-size: 24px;
        color: #000;
      }
      .aiPromptDescription {
        font-weight: 600;
        font-family: "Poppins", sans-serif !important;
        align-self: center;
        text-align: center;
        font-size: 12px;
        color: #666;
      }
    }

    .conversation {
      display: flex;
      flex-direction: column;
      gap: 12px;
      max-height: 85%;
      overflow-y: auto;
      padding-right: 8px;
      scroll-behavior: smooth;

      // justify-content: flex-end;
      min-height: 0;
    }

    .userMessage {
      background-color: #f2f2f2;
      border-radius: 24px;
      padding: 12px 24px;
      align-self: flex-end;
      font-weight: 400;
      font-size: 14px;
      color: #666;
    }

    .aiMessage {
      font-weight: 400;
      font-size: 14px;
      color: #666;
      background-color: #fff;
      border-radius: 24px;
      padding: 16px 16px 16px 12px;
      align-self: first baseline;
      display: flex;
      gap: 8px;
      margin-bottom: 48px;

      .greenSpot {
        width: 6px;
        height: 6px;
        margin-top: 6px;
        background-color: #00ff00;
        border-radius: 50%;
      }
      .aiMessageHeader {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .aiLoadingText {
        font-weight: 600;
        font-size: 14px;
        color: #999;
        font-style: italic;
        opacity: 0.7;
      }

      .aiMessageContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;

        .aiMessageFeedback {
          position: relative;
          display: flex;
          gap: 12px;

          .aiMessageFeedbackInput {
            background-color: #fff;
            border-radius: 8px;
            padding: 8px 12px;
            border: 1px solid #e6e6e6;

            position: absolute;
            top: 40px;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            input {
              border: none;
              outline: none;
              width: 360px;
              ::placeholder {
                color: #999999b9;
              }
            }
          }
          .aiMessageFeedbackIcon {
            cursor: pointer;
            opacity: 0.7;
            &:hover {
              opacity: 1;
            }
          }
        }

        p {
          margin: 0.5rem 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 1rem 0 0.5rem;
        }

        a {
          color: #00d948;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }

        code {
          background-color: #f5f5f5;
          padding: 0.2rem 0.4rem;
          border-radius: 4px;
          font-family: monospace;
        }

        ul,
        ol {
          margin: 0.5rem 0;
          padding-left: 1.5rem;
        }

        strong {
          font-weight: 700;
          color: #333;
        }

        em {
          font-style: italic;
        }
      }
    }
  }
  .referenceColumn {
    background-color: #fafafa;
    padding: 16px;
    border-radius: 8px;
    width: 14vw;
    .referenceTitle {
      font-size: 14px;
      font-weight: 700;
      color: #000;
      margin: 6px 8px 12px 8px;
    }
  }
}
