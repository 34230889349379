.background {
    margin: 0;
    background-image: linear-gradient(to bottom, transparent 50%, #ffffff 50%),
        linear-gradient(to right, #f5f5f5 50%, #ffffff 50%);
    background-size:
        8px 8px,
        8px 8px;
    border: 1px solid #e5e5e5;
    border-left-width: 0px;
}

.pick-pitch-container {
    background-color: rgba(47, 49, 60, 0.9);
    width: 100%;
    min-height: 100vh;
    padding: 20px;
    padding-bottom: 0;

    .pick-pitch-title {
        color: white;
        font-size: 25px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 25px;
    }

    .pitch-list {
        display: flex;
        flex-wrap: wrap;
    }

    .item-container {
        transition: all 0.2s ease;
        background-color: white;
        margin-bottom: 30px;
        box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        cursor: pointer;

        .image-container {
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 200px;
            background-image: linear-gradient(to bottom, transparent 50%, #ffffff 50%),
                linear-gradient(to right, #f5f5f5 50%, #ffffff 50%);
            background-size:
                6px 6px,
                6px 6px;

            .item-image {
                height: 100%;
                width: auto;
                max-width: 100%;
            }
        }

        .field-name {
            border-top: 1px solid #e5e5e5;
            padding: 30px;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .item-container:hover {
        transform: scale3d(1.03, 1.03, 1);
        .field-name {
            color: var(--primaryColor);
        }
    }
}

.content-creation-header {
    background-color: #fff;
    height: 60px;
    left: 0;
    right: 0;
    z-index: 3;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;

    .diagram-creator-contextual-btns {
        display: flex;
        gap: 10px;
        justify-content: center;
    }

    .font-size-dropdown {
        height: 53px;
        width: 30px !important;
        min-width: fit-content !important;
        border-color: #dddddd !important;
    }
}

.diagram-creator-sidebar {
    background-color: #fff;
    width: 300px;
    float: left;
    border-right: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    position: relative;

    .category-name {
        background-color: #fafafa;
        padding: 5px 35px 5px 10px;
        border-bottom: 1px solid #e5e5e5;
        font-weight: 500;

        .grey-text {
            color: #999;
        }
    }

    .category-container {
        padding: 10px;
        border-bottom: 1px solid #e5e5e5;

        .colors-select {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .color-btn {
                border: none;
                border-radius: 2px;
                height: 20px;
                width: 28px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .shapes-select {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 6px;

            .shape-btn {
                height: 36px;
                width: 65px;
                border: 1px solid #ddd;
                border-radius: 2px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;
                padding: 0;

                .drag-container {
                    width: inherit;
                    height: inherit;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: move;
                }
            }

            .selected {
                background-color: rgb(69 214 149);
            }
        }

        .shapes-select-3-per-row {
            .shape-btn {
                width: 87px;
            }
        }
        .shapes-select-2-per-row {
            .shape-btn {
                width: 130px;
            }
        }
    }
}

.diagram-creator-canvas-outer {
    padding: 30px;
    overflow: scroll;
    position: relative;
    display: block;
    overflow: hidden;
    overflow-x: overlay;

    .diagram-creator-canvas-container {
        min-width: fit-content;

        .diagram-creator-canvas {
            transition: border-color 0.4s ease;
            margin: 0 auto;
            padding: 0;
            max-width: fit-content;

            .canvas-area {
                width: 1000px;
                height: 563px;
            }
        }
    }
}
