.view-order-container {
  .view-order-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 12px 8px;
    font-size: 14px;
    .span-order-data {
      font-weight: 700 !important;
      padding-left: 5px;
    }

    .order-lines {
      tbody {
        tr {
          td:not(:first-child) {
            text-align: center;
          }
          td {
            .size {
              font-size: smaller;
            }
          }
        }
      }
    }

    .order-totals {
      text-align: right;

      .order-delivery,
      .order-discount,
      .order-total-price {
        font-weight: 700;
      }

      .order-total-price {
        font-size: 18px;
      }
    }
  }
}
