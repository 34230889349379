.site-contact-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 70px;
  padding-bottom: 60px;

  .site-contact-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 50px;
  }

  .site-contact-row {
    display: flex;
    width: 100%;
    height: 70px;
    margin-bottom: 25px;
    gap: 25px;

    .input-group {
      width: 32%;
      height: 100%;

      .input-label {
        padding-left: 15px;
        font-size: 13px;
        color: #b2b2b2;
        font-weight: 700;
        padding-bottom: 5px;
      }

      .contact-info-input {
        height: 50px;
        width: 100%;
        border: 0;
        font-size: 14px;
        font-weight: 700;
        padding-left: 15px;
        background-color: #fafafa;
        color: #666;
      }
    }
  }

  .site-contact-button-row {
    display: flex;
    justify-content: flex-end;
    height: 50px;

    .site-contact-details-button {
      background-color: var(--primaryColor) !important;
      height: 50px;
      min-width: 250px;
      padding-left: 20px;
      padding-right: 20px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: white !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
    }

    .site-details-icon {
      opacity: 1 !important;
      color: white;
    }
  }
}
