@media only screen and (max-width: 1652px) {
  .card-ranking {
    display: block;
    .ranking-row-header {
      display: flex;
      flex-direction: row !important;
      flex-wrap: nowrap;
      align-items: center;
      .ranking-row {
        padding-top: 0px;
      }
      .standings-team-name-2 {
        width: 22%;
        padding: 3px 0px;
      }
      .standings-team-stat-2 {
        width: 13%;
      }
    }
    .hoverable-row {
      .standings-header {
        flex-direction: row;
        flex-wrap: nowrap;
        .text-ctr {
          margin-bottom: 0px;
          padding-top: 0px;
        }
        .standings-team-name-2 {
          width: 22%;
          padding: 5px 0px;
        }
        .standings-team-stat-2 {
          width: 13%;
          white-space: nowrap;
        }
      }
    }
  }

  .ranking-row {
    margin-bottom: 10px !important;
  }

  .ranking-row-header {
    display: flex;
    flex-direction: row;
  }

  .ranking-title {
    padding-left: 30px;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 25px;
    color: black;
  }

  .standings-header {
    text-align: center;
    flex-direction: row;
    font-size: 14px;
    color: #666666;
    min-height: 45px;
    font-weight: 700;
    align-items: center;

    .standings-team-name {
      text-align: start;
    }

    .standings-team-stat {
      white-space: wrap;
    }
  }

  .light-bg {
    background-color: #fafafa;
  }

  .dark-bg {
    background-color: none;
  }

  .own-team {
    background-color: var(--primaryColor);
  }

  .ranking-titles {
    font-weight: 600;
    color: #b3b3b3;
    font-size: 14px;
  }

  .text-ctr {
    align-self: center;
  }

  .standings-header-placeholder {
    // font-size: 0.8vw;
    color: white;
    margin-top: 1.3vw;
    margin-bottom: 1.3vw;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
  }
}

@media only screen and (min-width: 1652px) {
  .ranking-title {
    padding-left: 30px;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 25px;
    color: black;
  }

  .standings-header {
    text-align: center;
    // font-size: 0.8vw;
    color: #666666;
    min-height: 45px;
    font-weight: 700;
    align-items: center;

    .standings-team-name {
      text-align: start;
    }

    .standings-team-stat {
      white-space: nowrap;
    }
  }

  .light-bg {
    background-color: #fafafa;
  }

  .dark-bg {
    background-color: none;
  }

  .own-team {
    background-color: var(--primaryColor);
  }

  .ranking-titles {
    font-weight: 600;
    color: #b3b3b3;
    font-size: 14px;
  }

  .text-ctr {
    align-self: center;
  }

  .standings-header-placeholder {
    // font-size: 0.8vw;
    color: white;
    margin-top: 1.3vw;
    margin-bottom: 1.3vw;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
  }
}

.card-ranking {
  .ranking-row-header {
    display: flex;
    align-items: center;
    .row-position {
      width: 13%;
    }
    .row-team-name {
      width: 22%;
    }
    .row-matches-played {
      width: 13%;
    }
  }
  .ranking-table {
    .ranking-row {
      display: flex;
      align-items: center;
      .row-line {
        font-size: 14px;
        color: #666666;
        min-height: 45px;
        font-weight: 700;
        display: flex;
        align-items: center;
        word-break: break-word;
      }
      .row-position {
        width: 13%;
        justify-content: center;
      }
      .row-team-name {
        width: 22%;
      }
      .row-matches-played {
        width: 13%;
        justify-content: center;
      }
    }
  }
}
