.news-functionalities-wrapper {
  .functionality-request-container {
    display: flex;
    flex-direction: column;

    .title {
      margin-bottom: 40px;
    }

    .request-buttons {
      display: flex;
      gap: 20px;
      margin-top: 30px;

      .tfb-button-custom {
        flex: 1 1 0;
      }
    }
  }
}

@media only screen and (max-width: 780px) {
  .news-functionalities-wrapper {
    .functionality-request-container {
      .request-buttons {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 30px;

        .tfb-button-custom {
          flex: 1 1 0;
        }
      }
    }
  }
}
