.site-sponsors-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 70px;
  padding-bottom: 60px;

  .site-sponsors-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    .site-sponsors-title {
      font-weight: 700;
      font-size: 20px;
    }
  }

  .add-sponsor-button {
    background-color: var(--primaryColor) !important;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "Poppins", sans-serif !important;
    font-size: 13px !important;
    color: white !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }

  .add-sponsor-icon {
    color: white;
    opacity: 1 !important;
    padding-left: 20px;
  }

  .site-sponsors-list {
    .site-sponsors-list-header {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 15px;
    }
    .site-sponsor-row {
      display: flex;
      height: 80px;
      align-items: center;
      font-weight: 700;
      color: #666666;
      font-size: 14px;
      padding-left: 25px;
      padding-right: 25px;
    }

    .sponsor-name {
      display: flex;
      align-items: center;
      width: 30%;
      img {
        width: 50px;
        max-height: 60px;
        margin-right: 25px;
      }
    }
    .sponsor-date-add {
      width: 50%;
      text-align: center;
    }
    .sponsor-options {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 12px;
        cursor: pointer;
      }
    }
  }
}

.add-partner-popup-container {
  width: 25vw;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    margin-bottom: 30px;

    .header-title {
      font-size: 20px;
      font-weight: 700;
    }

    .header-photos-delete-icon {
      font-size: 15px;
      cursor: pointer;
    }
  }
}

.add-partner-site-form {
  .add-partner-row {
    height: 50px;
    margin-bottom: 15px;

    input {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666666;
    }
  }
  .add-partner-form-row {
    display: flex;
    height: 50px;
    margin-bottom: 25px;
    align-items: center;
    background-color: #fafafa;
    justify-content: space-between;
    padding-right: 20px;

    .add-file-form-file-input {
      position: absolute;
      z-index: -1;
    }

    .add-file-form-file-label {
      font-weight: 700;
      font-size: 14px;
      color: #666666;
      padding-left: 25px;
      cursor: pointer;
      margin-bottom: 0 !important;
    }
  }
}

.add-partner-buttons-row {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  .add-partner-button {
    width: 47%;
    height: 50px;
    font-family: "Poppins", sans-serif !important;
    font-size: 13px !important;
    color: white !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;

    .add-partner-icon {
      color: white;
      opacity: 1 !important;
    }
  }
}

.delete-partner-text {
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 25px;
  text-align: center;
}
