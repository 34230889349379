.player-events-pitch-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  width: 840px;
  position: relative;
  height: 50px;

  .player-events-pitch-title {
    font-size: 20px;
    font-weight: 700;
    color: black;
  }

  .match-dropdown {
    background-color: #666666 !important;
    display: flex !important;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    height: 50px;
    min-width: 190px !important;
    right: 0 !important;
    position: absolute !important;

    .divider.default.text {
      color: white !important;
    }

    i {
      top: 36% !important;
      color: white;
    }

    .divider.text {
      color: white;
    }
  }

  .players-dropdown {
    background-color: #666666 !important;
    display: flex !important;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    height: 50px;

    .divider.default.text {
      color: white !important;
    }

    i {
      top: 36% !important;
      color: white;
    }

    .divider.text {
      color: white;
    }
  }
}

.pitch-container {
  width: 840px;
  height: 549px;
  position: relative;
  background: url("../../../../assets/instat-pitch/instat_pitch.png");

  .point {
    width: 14px;
    height: 14px;
    display: block;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .color-point-wrapper {
    width: 14px;
    height: 14px;
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: white;
    .color-point {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
    }
  }
}

.popup-container-event {
  width: auto;
  border-radius: 30px;
  font-size: 11px;
  font-weight: 700;
  z-index: 777777;

  .minute {
    color: black;
    text-align: center;
  }

  .opponent {
    color: #b2b2b2;
    text-align: center;
  }
}

.checkboxes-wrapper {
  margin-left: 50px;
  display: flex;
  width: calc(100% - 890px);

  .legend-point {
    width: 14px;
    height: 14px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 10px;
  }

  .legend-point-color {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50%;
    margin-left: 10px;
  }

  .column {
    width: 33%;
    .checkboxes-subtitle {
      font-size: 16px;
      font-weight: 700;
      color: black;
      margin-bottom: 30px;
    }
    .checkboxes-container {
      .element-container {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 14px;
        height: 25px;
        margin-bottom: 25px;
        color: #b2b2b2;

        .action-checkbox {
          margin-right: 10px;
        }
      }
    }
  }
}
