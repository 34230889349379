.polar-trainings-chart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin-bottom: 40px;

  .polar-trainings-chart-title {
    font-size: 20px;
    font-weight: 700;
    color: black;
  }

  .trainings-chart-dropdown {
    background-color: #666666 !important;
    display: flex !important;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
    height: 50px;
    min-width: 200px !important;

    .divider.default.text {
      color: white;
    }

    i {
      top: 36% !important;
      color: white;
    }

    .divider.text {
      color: white;
    }
  }
}
