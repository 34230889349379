//
// Login 1
// Pages SASS files are compiled into separate css files
//


// Initialization of global variables, mixins and functions
@import "../../../init";
@media(max-width: 767px){
    .login .cotnainer-logo{
        width: 55vw !important;
        margin-top: 0px !important;
    }

    .login .p-10 {
        padding: 100px !important;
    }

    .login .mt-30{
        margin-top: 0px !important;
    }

    .login-form{
        .testclass{
            font-size: 25px !important;
        }
    }

    .login-form .form-group{
        margin-bottom: 10px !important;
    }
}


.login.login-1 {
    // Form modes
    .login-signin,
    .login-signup,
    .login-forgot {
        display: none;
    }

    &.login-signin-on {
        .login-signup {
            display: none;
        }

        .login-signin {
            display: block;
        }

        .login-forgot {
            display: none;
        }
    }

    &.login-signup-on {
        .login-signup {
            display: block;
        }

        .login-signin {
            display: none;
        }

        .login-forgot {
            display: none;
        }
    }

    &.login-forgot-on {
        .login-signup {
            display: none;
        }

        .login-signin {
            display: none;
        }

        .login-forgot {
            display: block;
        }
    }
}

// Desktop view
@include media-breakpoint-up(lg) {
    .login.login-1 {
        .login-aside {
            width: 100%;
            max-width: 600px;
        }

        .login-form {
            width: 100%;
            max-width: 400px;
        }
    }
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
    .login.login-1 {
        .login-aside {
            width: 100%;
            max-width: 400px;
        }
    }
}

// Tablet mode
@include media-breakpoint-down(md) {
    .login.login-1 {
        .login-form {
            width: 100%;
            max-width: 350px;
        }
    }
}

// Mobile mode
@include media-breakpoint-down(xs) {
    .login.login-1 {
        .login-form {
            width: 100%;
            max-width: 100%;
        }
    }
}
