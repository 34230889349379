.playerPhotosContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.photoElemContainer {
    height: 150px;
    width: 150px;
    position: relative;
    background-color: #ebebeb;

    img[alt="gallery-photo"],
    video[alt="gallery-photo"] {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .expandButton {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.3s;
    }

    .deleteBtn {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }

    .loaderContainer {
        margin: auto;
        height: 150px;
        width: 100px;
    }
}

.photoElemContainer:hover {
    cursor: pointer;

    img[alt="gallery-photo"],
    video[alt="gallery-photo"] {
        filter: brightness(60%);
    }

    .expandButton {
        visibility: visible;
        opacity: 1;
    }
}

.photoViewerContainer {
    position: fixed;
    top: 0;
    z-index: 3333;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    left: 0;
    .contentContainer {
        .imgContainer {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            gap: 10px;
            width: 100%;
            padding: 50px 0;
            .image {
                max-width: calc(100% - 100px);
                max-height: 100%;
                height: fit-content;
            }
            .close {
            }
        }
    }
}

.loaderContainer {
    display: flex;
    height: 100px;
    width: 100px;
}

.editBio {
    background-color: #fafafa;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .photoElemContainer {
        height: 100px;
        width: 100px;
    }
}
