.tfb-checkbox {
    .MuiSvgIcon-root {
        background-color: #fafafa !important;
        height: 20px;
        width: 20px;
        border-radius: 3px;
    }
    pointer-events: none !important;
}
.tfb-checkbox:active {
    border-style: outset !important;
}
.prevent-select-text {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
