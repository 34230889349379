.add-news-container {
  padding: 50px;
  .add-news-header {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    .add-news-title {
      font-size: 20px;
      color: black;
      font-weight: 700;
    }
  }

  .add-news-form-container {
    display: flex;
    justify-content: space-between;
    .add-news-form {
      width: 100%;
      .add-news-row {
        justify-content: space-between;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
        height: 50px;

        .title-input {
          width: 100%;
          height: 100%;
          border: 0;
          font-size: 14px;
          font-weight: 700;
          padding-left: 20px;
          background-color: #fafafa;
          color: #b2b2b2 !important;
          font-family: "Poppins", sans-serif !important;
        }

        .tag-dropdown {
          width: 32%;
          color: #b2b2b2;
          font-family: "Poppins", sans-serif !important;
          font-size: 14px;
        }

        select {
          width: 48%;
          height: 100%;
          border: 0;
          font-size: 14px;
          font-weight: 700;
          padding-left: 25px;
          background-color: #fafafa;
          color: #b2b2b2;

          option {
            padding-left: 25px;
            background-color: #fafafa;
            color: #666666;
            font-size: 13px;
          }
        }
      }

      .image-input-container {
        display: flex;
        height: 50px;
        align-items: center;
        background-color: #fafafa;
        justify-content: space-between;
        padding-right: 20px;
        width: 48%;

        input {
          opacity: 0;
          position: absolute;
          z-index: -1;
          overflow: visible;
          height: 50px;
          padding: 0;
        }

        label {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 14px;
          color: #b2b2b2;
          padding-left: 20px;
          padding-right: 20px;
          cursor: pointer;
          margin-bottom: 0 !important;
        }
      }

      .element-to-add-image-input {
        width: 100% !important;
      }

      .add-news-image-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        flex-direction: column;
        height: auto;

        .add-news-image-preview {
          width: 100%;
          // height: 115px;
          background-color: #fafafa;

          img {
            width: 100%;
            object-fit: cover;
            max-height: 100%;
          }
        }

        .add-news-image-right-side {
          width: 100%;
          margin-top: 20px;
        }
      }
    }

    .sections-title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 25px;
    }

    .add-options-buttons {
      display: flex;

      justify-content: space-between;
      flex-flow: row wrap;

      .add-news-button {
        width: 48% !important;
        // font-size: 13px !important;
        height: 50px;

        display: flex;
        justify-content: space-between;
      }

      @media only screen and (min-width: 769px) {
        .add-news-button {
          font-size: 13px !important;
        }
      }
    }

    .add-news-sections-list {
      margin-bottom: 30px;
      .add-news-section {
        display: flex;
        align-items: center;
        height: 50px;
        font-weight: 700;
        font-size: 14px;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
        color: #666666;

        .add-news-section-type {
          width: 25%;
        }
        .add-news-section-preview {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 60%;
        }
        .add-news-section-options {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 15%;

          img {
            width: 12px;
            cursor: pointer;
          }

          .news-option-icon {
            color: #666666;
          }
        }
      }
    }

    .element-to-add-buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      margin-top: 15px;
    }

    .text-area-add-news {
      width: 100%;
      font-size: 13px;
      font-weight: 700;
      color: #b2b2b2 !important;
      padding: 10px;
      padding-left: 20px;
      border: 0;
      background-color: #fafafa;
    }
  }

  .add-news-button {
    background-color: var(--primaryColor) !important;
    height: 50px;
    font-family: "Poppins", sans-serif !important;
    font-size: 13px !important;
    color: white !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;

    .add-news-button-icon {
      color: white;
      opacity: 1 !important;
    }
    .add-news-button-icon-dark {
      color: #666666;
      opacity: 1 !important;
    }
  }
  .dark-background {
    background-color: #666666 !important;
  }

  .light-background {
    background-color: #d4d4d4 !important;
    color: #666666 !important;
  }

  .news-article-final-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
}

.news-article-preview-container {
  padding: 50px;
  height: fit-content;

  .preview-text-container {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .news-article-preview-header {
    font-size: 20px;
    color: black;
    font-weight: 700;
    height: 50px;
    margin-bottom: 50px;
  }

  .preview-main-image-container {
    margin-bottom: 45px;
    img {
      width: 100%;
    }
  }

  .preview-article-title {
    font-size: 30px;
    color: #000;
    font-weight: 700;
    padding-bottom: 35px;
    text-align: center;
  }

  .news-article-preview-content {
    font-family: "HelveticaNeue";
    padding: 30px;
    background-color: #fafafa;
    min-height: 339px;
  }

  .preview-paragraph-container {
    margin-bottom: 25px;
    color: grey;
    font-size: 18px;
    line-height: 1.75;
  }

  .preview-subtitle-container {
    font-size: 25px;
    color: #000;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.143;
  }

  .preview-highlighted-container {
    font-size: 12px;
    color: #000;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 17.8px;
    padding-left: 15px;
    border-left: solid 1px #fbeb24;
  }

  .preview-image-container {
    margin-bottom: 75px;
    img {
      width: 100%;
    }
  }
}

.loader-container {
  text-align: center;
  margin-bottom: 20px;
}

.edit-section-add-news-container {
  width: 25vw;
  .edit-news-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .edit-news-popup-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .edit-section-text-area {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding: 10px;
    padding-left: 20px;
    border: 0;
    background-color: #fafafa;
    margin-bottom: 25px;
  }

  .edit-section-popup-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .edit-section-button {
      height: 50px;
      width: 47%;
      font-family: Poppins, sans-serif !important;
      font-size: 13px !important;
      color: #fff !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;

      .edit-section-icon {
        color: white;
        opacity: 1 !important;
      }
    }
  }
}

.publish-button {
  width: 63%;
}

.cancel-button {
  width: 33%;
}

@media only screen and (min-width: 1526px) and (max-width: 1820px) {
  .publish-button {
    width: 58%;
  }

  .cancel-button {
    width: 38%;
  }

  .add-news-row {

    .image-input-container {
      width: 58% !important;
    }

    select {
      width: 38% !important;
    }
  }
}

@media only screen and (max-width: 1625px) {
  .add-news-container, .news-article-preview-container {
    padding: 25px !important;
  }
}

@media only screen and (max-width: 1525px) {
  .add-news-row {

    .image-input-container {
      width: 48%;
    }

    select {
      width: 48%;
    }
  }

  .publish-button {
    width: 48%;
  }

  .cancel-button {
    width: 48%;
  }
}

@media only screen and (max-width: 1400px) {
  .container-fluid {

    .row {
      flex-direction: column;

      .col-lg-6 {
        max-width: 100%;
        // margin-bottom: 75px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .ui.button {
    font-size: 12px !important;
    padding: 10px !important;
  }

  .add-news-button {
    font-size: 10px !important;
  }

  .preview-title, .add-news-title {
    font-size: 16px !important;
  }

  .sections-title {
    font-size: 13px !important;
  }

  .add-news-container .add-news-form-container .add-news-form .image-input-container label {
    font-size: 11px !important;
  }

  .add-news-section-type, .add-news-section-preview, .text-area-add-news, .title-input, select {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 550px) {
  .custom-row {
    flex-direction: column;
    height: auto !important;
  
    div, select {
      width: 100% !important;
      height: 50px !important;
    }

    div {
      margin-bottom: 20px;
    }
  }
}