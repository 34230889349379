@media(max-width: 991px){
  .injuries-list>.columns-names-injuries{
    display: none !important;
  }

 .injuries-history-container .injuries-history-content .injury-row.columns-names-injuries{
    display: flex !important;
    flex-direction: column;
    width: 40%;
  }

  .injuries-history-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .injuries-history-container .injuries-history-content{
    display: flex;
    flex-direction: row;
  }

  .injuries-history-container .injuries-history-content .injury-row{
    flex-direction: column;
    width: 60%;
    height: 100% !important;
      .injury-estimated-recovery{
        display: flex;
        align-content: center;
        align-items: center;
        height: 48px;
      }
  }

  .injuries-history-container{
    .injury-player-name{
      width: 100% !important;
      // height: 40px;
      padding-left: 0px !important;
    }

    .injury-type, .injury-date, .injury-estimated-recovery{
      width: 100% !important;
      text-align: left !important;
    }

    .injury-options{
      height: 48px !important;
      width: 100% !important;
      justify-content: flex-end !important;
      margin-right: 20px;
    }
  }

  .injuries-list{
    .injuries-history-container{
      border-top: 1px solid #d2d2d2;
      // border-bottom: 1px solid #d2d2d2;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      // margin-bottom: 20px;
    }

    //.injury-row{
      //padding-left: 0px !important;
      //padding: 15px 5px 5px 10px !important;
    //}

    .injury-row, .columns-names-injuries{
      padding: 10px 10px;
    }
  }
}

@media(max-width: 767px){
  .injuries-container-h{
    padding: 0px !important;
  }

  .injuries-container-h .injuries-header{
    flex-direction: column;
    padding: 10px;
    margin-bottom: 0px !important;
    align-items: flex-start !important;
  }
}

.injuries-history-container .columns-names-injuries{
  display: flex;
}

.injuries-history-container .columns-names-injuries{
  display: none !important;
}

.injuries-container-h {
  padding: 45px;
  .injuries-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: 50px;

    .injuries-title {
      font-size: 20px;
      font-weight: 700;
    }
    .add-injury-header-button {
      height: 50px;
      white-space: nowrap;
    }
  }

  .injuries-list {
    .columns-names-injuries {
      color: #b2b2b2 !important;
    }
    .injury-row {
      display: flex;
      align-items: center;
      height: 55px;
      font-weight: 700;
      color: #666666;
      .injury-player-name {
        width: 25%;
        padding-left: 10px;
      }
      .injury-type {
        width: 25%;
        text-align: center;
      }
      .injury-date {
        width: 20%;
        text-align: center;
      }
      .injury-estimated-recovery {
        width: 20%;
        text-align: center;
      }
      .injury-options {
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;

        .injury-see-more-button {
          background-color: #666666;
          width: 35px;
          height: 35px;
          padding: 7px;
          border-radius: 5px;
          cursor: pointer;
        }

        .end-injury-icon {
          color: black;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
