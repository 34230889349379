.fan-profile-page-title {
    display: flex;
    font-weight: 700;
    font-size: 25px;
    color: black;
    margin-bottom: 25px;
}

.fan-profile-container {
    padding: 50px;

    .fan-profile-title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 25px;
        color: black;
    }

    .fan-general-detail-row {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        .detail-label {
            width: 40%;
            color: #b2b2b2;
        }

        .detail-value {
            color: #666666;
            width: 50%;
        }
        .detail-options {
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: center;

            .fan-profile-icon {
                color: #666666;
                cursor: pointer;
                opacity: 1 !important;
                margin-left: 5px;
                font-size: 13px;
            }
        }
    }

    .fan-profile-activity-list {
        .activity-row {
            height: 50px;
            width: 100%;
            font-size: 14px;
            color: #666666;
            font-weight: 700;
            display: flex;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;

            .name {
                width: 60%;
                display: flex;
                align-items: center;

                .email {
                    color: #b2b2b2;
                    margin-left: 5px;
                }
            }
            .date {
                width: 40%;
                text-align: center;
            }
        }

        .month-subtitle {
            font-size: 16px;
            font-weight: 700;
            color: #b2b2b2;
            margin-bottom: 10px;
            padding-left: 20px;
        }
    }
}

.fan-profile-edit-input {
    width: 100%;
    height: 100%;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    padding-left: 15px;
    background-color: #fafafa;
    color: #666666;
    border: solid 1px;
    border-radius: 3px;
}

.fan-datepicker-row {
    position: relative;
    display: flex;

    .fan-datepicker-input {
        width: 100%;
        height: 50px;
        background-color: #fafafa;

        div {
            color: #b2b2b2;
            font-weight: 700;
            padding-left: 20px;
            height: 100%;
        }
        ::before {
            border-bottom: 0 !important;
        }

        ::after {
            border-bottom: 0;
        }

        fieldset {
            border-color: #fafafa;
        }
    }

    .calendar-icon {
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
    }
}
