.add-product-container {

  .product-description-row {
    margin-bottom: 15px;
    .ck-editor__editable_inline {
      min-height: 100px;
  }
  }

  .product-categories-row {
    margin-bottom: 15px;

    .product-categories-checkbox-list
    {
      .product-categories-checkbox-list-item {
        
      }
    }
  }

  .product-sizes-row {
    margin-bottom: 15px;

    .product-sizes-checkbox-list
    {
      display: flex;
      .product-sizes-checkbox-list-item {
        
      }
    }
  }
  .add-file-form-row {
    .add-product-input {
      width: 100%;
      height: 50px;
    }
  }
}
