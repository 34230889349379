.edit-injury-form-container {
  margin-bottom: 15px;
  padding: 15px;
  background-color: #fafafa;

  .edit-injury-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 50px;
    margin-bottom: 15px;

    .edit-injury-input {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: white;
      color: #666666;
    }
  }

  .edit-injury-date-group {
    width: 47%;
    position: relative;
    height: 50px;

    .injury-date-input {
      width: 100%;
      height: 50px;
      background-color: white;

      div {
        color: #666666;
        font-weight: 700;
        padding-left: 5px;
      }

      fieldset {
        border-color: white;
      }
    }

    .calendar-icon {
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .text-area-edit-injury {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding: 10px;
    padding-left: 20px;
    border: 0;
    background-color: white;
  }

  .edit-injury-buttons-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }
}
