.stepTitle {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 16px;
}

.editorContainer {
    margin-bottom: 20px;
    margin-top: 20px;
}

.teamsContainer {
    margin-bottom: 30px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .teamContainer {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}

.btnsContainer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.documentTypeContainer {
    margin: 20px 0;
    .title {
        font-weight: 700;
        font-size: 13px;
        color: #b2b2b2;
        margin-bottom: 8px;
    }
    .documentTypeList {
        display: flex;
        gap: 20px;
    }
}

.documentActiveLabel {
    background-color: rgb(0 219 73 / 60%);
    border-radius: 20px;
}

.documentArchivedLabel {
    background-color: rgb(0 170 255 / 60%);
    border-radius: 20px;
}

.relationship {
    font-size: 12px;
    color: #b2b2b2;
}

.customCell {
    border-bottom: 1px solid #e0e0e0 !important;
}

.addPlayerToDocument {
    .teamsContainer {
        margin-bottom: 30px;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        gap: 30px;
        .teamContainer {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
    }
}

.searchDiv {
    display: flex;
    align-items: flex-end;
    gap: 30px;

    .searchVideo {
        width: 540px;
    }
}

.suspensionTableContainer {
    :global .rdt_Table {
        .rdt_TableHead {
            position: sticky;
            top: 0px;
            margin: 0 0 0 0;
            z-index: 2 !important;

            .rdt_TableHeadRow {
                .rdt_TableCol:first-child {
                    position: sticky !important;
                    left: 0 !important;
                    z-index: 1 !important;
                }
            }
        }

        .rdt_TableBody {
            overflow: initial !important;
            .rdt_TableRow {
                .rdt_TableCell:first-child {
                    position: sticky !important;
                    left: 0 !important;
                    z-index: 1 !important;
                    background-color: white;
                }
            }
        }
    }
}

.suspensionTableContainer > div:first-of-type {
    max-height: 60vh;
    overflow-y: auto;
}
