@media (max-width: 767px) {
  .calendar-header {
    align-items: flex-start;
    padding: 10px;
    margin-bottom: 0px !important;
    .calendar-header-title {
      padding-bottom: 10px;
    }

    .calendar-header-buttons {
      flex-direction: column;
      width: 100%;
      .calendar-dropdown {
        margin-right: 0px;
        margin-bottom: 10px;
        // justify-content: center;
        // .dropdown{
        //   right: 80px !important;
        // }

        .menu {
          max-height: 263px !important;
        }
      }
    }
  }

  .add-event-form-container {
    min-width: 100% !important;
    .container-buttons-add-event {
      .add-button {
        width: 100%;
      }
    }
  }

  .fc-view-harness-active {
    height: 500px !important;
  }

  .fc-media-screen {
    margin-bottom: 30px;
  }
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  .calendar-header-title {
    font-size: 20px;
    font-weight: 700;
  }

  .calendar-header-buttons {
    display: flex;
  }

  .calendar-dropdown {
    background-color: #666666 !important;
    border: 0 !important;
    display: flex !important;
    align-items: center;
    padding-left: 20px !important;
    font-weight: 700;
    height: 50px;
    margin-right: 20px;

    div {
      color: white !important;
      font-size: 13px !important;
    }

    i {
      margin: 0 !important;
    }
  }

  .calendar-button {
    white-space: nowrap;
    background-color: var(--primaryColor) !important;
    color: white !important;
    font-weight: 700 !important;
    font-family: "Poppins", sans-serif !important;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;

    .plus-icon-button {
      padding-left: 15px;
      color: white;
      opacity: 1 !important;
    }
  }
}

.fc-toolbar-chunk {
  text-transform: capitalize;

  div {
    display: flex;
    align-items: center;

    button {
      border: 0 !important;
      color: black !important;
      background-color: transparent !important;
      padding-left: 5px !important;
      padding-right: 5px !important;
    }

    h2 {
      font-size: 25px !important;
      font-weight: 800 !important;
    }
  }
}

//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ADD EVENT FORM

.add-event-form-container {
  min-width: 450px;
  .add-event-form-row {
    display: flex;
    margin-bottom: 15px;
    height: 50px;
    width: 100%;
    position: relative;

    .buttons-color-container {
      display: flex;
    }

    .calendar-icon {
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      cursor: pointer;
    }

    .add-event-input {
      background-color: #fafafa;
      height: 100%;
      border: 0;
      font-size: 14px !important;
      font-weight: 700;
      // color: #b2b2b2 !important;
      padding-left: 20px;
      width: 100%;
      border-radius: 3px;
    }

    .color-button {
      width: 150px;
      font-size: 13px;
      font-weight: 700;
      font-family: "Poppins", sans-serif !important;
      white-space: nowrap;
    }

    .add-event-date-input {
      width: 100%;
      height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-weight: 700;
        padding-left: 20px;
        height: 100%;
        cursor: pointer;
      }
      ::before {
        border-bottom: 0 !important;
      }

      ::after {
        border-bottom: 0;
      }

      fieldset {
        border-color: #fafafa;
      }

      input {
        cursor: pointer;
        color: #000000de !important;
      }
    }

    .add-event-dropdown {
      background-color: #fafafa !important;
      border: 0 !important;
      display: flex !important;
      align-items: center;
      padding-left: 20px !important;
      font-weight: 700;
      height: 50px;
      width: calc(100% - 60px);

      div {
        color: #b2b2b2 !important;
        font-size: 14px !important;
      }

      i {
        margin: 0 !important;
      }
    }

    .dropdown-full-width {
      width: 100% !important;
    }

    .add-event-form-button {
      height: 100%;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      margin-left: 15px;
      cursor: pointer;
    }

    .add-event-plus-icon {
      color: white !important;
      margin: 0 !important;
    }

    .text-area-add-event {
      width: 100%;
      font-size: 13px;
      font-weight: 700;
      color: #b2b2b2 !important;
      padding-left: 20px;
      padding-top: 10px;
      background-color: #fafafa !important;
    }
  }
}

.container-buttons-add-event {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;

  .cancel-button {
    background-color: #666666 !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700 !important;
    width: 47%;
    height: 50px;
    color: white !important;

    .plus-icon-button {
      color: white !important;
    }
  }

  .add-button {
    background-color: var(--primaryColor) !important;
    color: white !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700 !important;
    width: 47%;
    height: 50px;
    display: flex !important;
    gap: 10px;
    align-items: center;
    justify-content: center;

    .plus-icon-button {
      color: white;
    }
  }
}

.add-event-color-row {
  display: flex;
  justify-content: space-between;

  .icons {
    margin-left: auto;
    margin-right: auto;
  }
}

.date-label-field {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  justify-content: space-between;
}

.date-label {
  margin-right: 10px;
}

#kt_content
  > div.d-flex.flex-column-fluid
  > div
  > div
  > div.row.d-flex.align-items-end.flex-column
  > div
  > button
  > span.MuiButton-label {
  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;
  width: 90px;
}

.event-detail-content {
  padding-top: 25px !important;
  padding-bottom: 25px !important;

  .event-content-row {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 15px;
    align-items: center;

    .event-date {
      color: var(--primaryColor) !important;
      margin-left: 5px;
    }

    .event-hour {
      color: var(--primaryColor) !important;
      margin-left: 10px;
    }

    .event-desc-content {
      margin-top: 10px;
    }
  }
}

.event-modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: 20px;
}

.event-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icons-container {
    display: flex;
    margin-left: 20px;
  }

  .header-text {
  }
}

.event-modal-header-container {
  .content {
    width: 100%;
    padding-bottom: 0;
  }
}
