@charset "UTF-8";
.wrapper {
  background-color: #fff;
  z-index: 1000;
  padding: 20px 20px;
  flex-grow: 1;
  width: 100%;
  position: absolute;
  top: -12px;
  left: 0;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* Hover Gri Deschis */
  box-shadow: 0px 7.5px 7.5px 0px rgba(212, 212, 212, 0.55);
}
.wrapper .aiChatContainer {
  display: flex;
  gap: 16px;
  border-radius: 100px;
  background-color: #fafafa;
  padding: 9px 16px;
  display: flex;
  align-items: center;
}
.wrapper .aiChatContainer .aiChatIcon {
  height: 32px;
}
.wrapper .aiChatContainer .aiChatInput {
  border: none;
  background-color: transparent;
  width: 100%;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  resize: none;
  min-height: 24px;
  overflow: hidden;
  transition: height 0.2s ease;
}
.wrapper .aiChatGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
.wrapper .aiChatGrid .aiChatGridItem {
  background-color: #fafafa;
  padding: 12px 16px;
  border-radius: 100px;
  border-radius: 30px;
  border: 2px solid #e6e6e6;
  background: #fff;
  color: #666;
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  /* 150% */
  /* Shadow Casetă */
  box-shadow: 0px 10px 20px 0px rgba(102, 102, 102, 0.02);
}
.wrapper .aiChatGrid .aiChatGridItem:hover {
  cursor: pointer;
  background-color: #fafafa;
}
@media (max-width: 1024px) {
  .wrapper {
    display: none;
  }
}