.suspensionTableContainer {
    :global .rdt_Table {
        .rdt_TableHead {
            position: sticky;
            top: 0px;
            margin: 0 0 0 0;
            z-index: 2 !important;

            .rdt_TableHeadRow {
                .rdt_TableCol:first-child {
                    position: sticky !important;
                    left: 0 !important;
                    z-index: 1 !important;
                }
            }
        }

        .rdt_TableBody {
            .rdt_TableRow {
                .rdt_TableCell:first-child {
                    position: sticky !important;
                    left: 0 !important;
                    z-index: 1 !important;
                    background-color: white;
                }
            }
        }
    }
}

.suspensionTableContainer > div:first-of-type {
    max-height: 50vh;
    overflow-y: auto;
}

.unmappedPlayer {
    font-size: 12px;
    color: #ff9500;
}
