.view-users-notifications-container {
  .sub-header {
    margin-top: 20px;
  }

  .message-text {
    margin-top: 40px;
    color: #666666;
  }

  .audience {
    margin-top: 50px;
  }

  .users-table {
    margin-top: 40px;

    .header-checkbox {
      display: flex;
      align-items: center;
    }

    .row-checkbox-player {
      display: flex;
      align-items: center;

      .user-details {
        display: flex;
        flex-direction: column;

        .relationship {
          color: #b2b2b2;
        }
      }
    }
  }

  .message {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 575px) {
  .action-buttons-add-notification {
    margin-top: 40px;
    .tfb-button-custom {
      height: 50px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .add-users-notifications-container {
    .tfb-button-custom {
      height: inherit !important;
    }
  }
}

@media only screen and (max-width: 991px) {
  .view-users-notifications-container {
    .sub-header {
      margin-top: 20px;
    }
  }
}
