#onMouseDown {
  display: none;
  position: relative;
  .delete-field-icon {
    position: relative;
    left: 12px;
    top: 13px;
  }
}

.tactic-board-container {
  width: 85vw;
  padding: 10px;

  .tactic-board-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 25px;
    .tactic-board-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .cone-icon {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 33333;
    margin: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../../assets/tactic-board/big-cone.png");
  }

  .small-cone-icon {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 33333;
    margin: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../../assets/tactic-board/small-cone.png");
  }

  .ball-icon {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 33333;
    margin: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../../assets/tactic-board/ball.png");
  }

  .inflatable-icon {
    height: 15px;
    width: 11px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 33333;
    margin: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../../assets/tactic-board/inflatable.png");
  }

  .goal-icon {
    width: 25px;
    height: 25px;
    background-size: contain;
    background-image: url("../../../assets/tactic-board/goal.png");
  }

  .tactic-board-area {
    display: flex;
    margin-bottom: 100px;

    .left-side-container {
      display: flex;
    }

    .tactic-board-elements {
      width: 50px;

      .element-container {
        width: 50px;
        height: 50px;
        border-radius: 3px;
        background-color: #666666;
        margin-bottom: 10px;
        position: relative;
      }

      .element-container-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .element-container-border {
        height: 1px;
        background-color: #f2f2f2;
        width: 50px;
        margin-bottom: 30px;
      }
    }

    .tactic-board-pitch {
      width: 875px;
      margin-left: 30px;
      margin-right: 30px;
      position: relative;

      .pitch-image {
        width: 100%;
      }
    }
  }

  .player-blue {
    width: 25px;
    height: 25px;
    background-image: url("../../../assets/tactic-board/shirt-blue.png");
    background-size: contain;
  }

  .player-red {
    width: 25px;
    height: 25px;
    background-image: url("../../../assets/tactic-board/shirt-red.png");
    background-size: contain;
  }

  .player-yellow {
    width: 25px;
    height: 25px;
    background-image: url("../../../assets/tactic-board/shirt-yellow.png");
    background-size: contain;
  }

  .right-side-container {
    width: calc(100% - 985px);
  }

  .tactic-board-details {
    display: flex;
    height: fit-content;
    margin-bottom: 50px;

    .tactic-board-form-set {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 80px;
      margin-bottom: 30px;
      padding-left: 25px;

      .tactic-board-input-label {
        font-size: 13px;
        font-weight: 700;
        color: #b2b2b2;
        padding-bottom: 15px;
      }

      .tactic-board-input-group {
        display: flex;
        height: 50px !important;
        width: 155px;
      }
    }

    .tactic-board-input {
      width: 55px;
      height: 100%;
      border: 0;
      font-size: 14px;
      font-weight: 700;
      padding-left: 25px;
      background-color: #fafafa;
      color: #666;
    }

    .tactic-board-input-button {
      width: 50px;
      height: 100%;
      border-radius: 3px;
      background-color: #fafafa;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .tactic-board-button {
      width: 100%;
      background-color: var(--primaryColor);
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 13px;
      color: #ffffff !important;
      height: 50px;
      font-family: Poppins, sans-serif !important;
    }
  }

  .tactic-board-description {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding: 10px;
    padding-left: 20px;
    border: 0;
    background-color: #fafafa;
    margin-bottom: 15px;
  }

  .tactic-board-final-button {
    width: 100%;
    background-color: var(--primaryColor) !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    color: #ffffff !important;
    height: 50px;
    font-family: Poppins, sans-serif !important;
  }

  .tactic-board-button-icon {
    color: #666666;
    opacity: 1 !important;
    margin: 0 !important;
    cursor: pointer;
    font-size: 11px !important;
  }
}

.slidecontainer {
  width: 100%;

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--primaryColor);
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--primaryColor);
    cursor: pointer;
  }
}

@media (max-width: 1640px) {
  .tactic-board-area {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;

    .right-side-container {
      margin-top: 20px;
      width: 100%;

      .tactic-board-details {
        justify-content: center;
      }
    }

    .left-side-container {
      display: flex;
      justify-content: center;
    }

    .tactic-board-elements {
      width: 50px;

      .element-container {
        width: 50px;
        height: 50px;
        border-radius: 3px;
        background-color: #666666;
        margin-bottom: 10px;
        position: relative;
      }

      .element-container-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .element-container-border {
        height: 1px;
        background-color: #f2f2f2;
        width: 50px;
        margin-bottom: 30px;
      }
    }

    .tactic-board-pitch {
      width: 875px;
      margin-left: 30px;
      margin-right: 30px;
      position: relative;

      .pitch-image {
        width: 100%;
      }
    }
  }
}
