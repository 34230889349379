@media only screen and (max-width: 991px) {
  .container-fluid {
    .mv-container {
      margin-top: 20px;
      padding: 50px;

      .row {
        flex-direction: row;
      }

      .mv-increase-title {
        padding-left: 30px;
        margin-top: 15px;
        margin-bottom: 30px;
        .mv-title-text {
          font-weight: 700;
          font-size: 20px;
        }
      }
      .player-name,
      .difference,
      .current-mv {
        margin-bottom: 0px;
      }
    }
  }
}

.mv-row {
  color: #666666;
  text-align: center;
  font-size: 14px;
  min-height: 70px;
  font-weight: 700;

  .mv-title-column {
    color: #b3b3b3;
  }

  .player-name {
    width: 40%;
    display: flex;
    align-items: center;
    padding-left: 10px;

    .name {
      margin-left: 8px;
      text-align: left;
    }
  }

  .difference {
    width: 40%;
    align-self: center;
    align-items: center;
    display: flex;
    justify-content: center;

    .number {
      margin-right: 10px;
    }
  }

  .current-mv {
    width: 20%;
    align-self: center;
    font-weight: 600;
  }
}

@media only screen and (min-width: 991px) {
  .container-fluid {
    .mv-container {
      margin-top: 20px;
      padding: 50px;

      .row {
        flex-direction: row;
      }

      .mv-increase-title {
        margin-bottom: 30px;
        .mv-title-text {
          font-weight: 700;
          font-size: 20px;
        }
      }
      .player-name,
      .difference,
      .current-mv {
        margin-bottom: 0px;
      }
    }
  }
  .mv-row {
    color: #666666;
    text-align: center;
    font-size: 14px;
    min-height: 70px;
    font-weight: 700;

    .mv-title-column {
      color: #b3b3b3;
    }

    .player-name {
      width: 40%;
      display: flex;
      align-items: center;
      padding-left: 10px;

      .name {
        margin-left: 8px;
        text-align: left;
      }
    }

    .difference {
      width: 40%;
      align-self: center;
      align-items: center;
      display: flex;
      justify-content: center;

      .number {
        margin-right: 10px;
      }
    }

    .current-mv {
      width: 20%;
      align-self: center;
      font-weight: 600;
    }
  }
}
