.sizes-container {
    padding: 50px;
  
    .sizes-header {
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
  
      .sizes-title {
        font-size: 20px;
        font-weight: 700;
      }
  
      .sizes-interactive {
        .add-sizes-button {
          background-color: var(--primaryColor) !important;
          color: white !important;
          font-family: "Poppins", sans-serif !important;
          font-weight: 700;
          height: 50px;
        }
      }
    }
  
    .sizes-list {
      .no-sizes-call-up-message {
        text-align: center;
        .no-sizes-call-up-message-text {
          font-size: 15px;
          font-weight: 600;
          padding-top: 5px;
        }
      }
  
      .desktop-header {
        display: flex;
      }
  
      .sizes-row-table-header {
        display: flex;
        justify-content: space-between;
        color: #b3b3b3;
        font-weight: 600;
        font-size: 13px;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 15px;
      }
  
      .sizes-row {
        height: 55px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        color: #666666;
        padding-left: 25px;
        padding-right: 25px;
  
        .content-row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
  
          .actions-column {
            display: flex;
            gap: 10px;
          }
        }
      }
    }
  
  }
  
  @media only screen and (max-width: 992px) {
    .sizes-container {
      padding: 10px;
  
      .desktop-header {
        display: none !important;
      }
      .sizes-list {
        .sizes-row {
          height: auto;
  
          .content-row {
            display: none;
          }
        }
      }
    }
  }
  
  .mobile-listing {
    display: none;
  
    @media only screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px;
      gap: 20px;
  
      .mobile-list-item {
        display: flex;
  
        div {
          text-align: left;
          width: 100%;
          img {
            max-width: 50px;
            max-height: 50px;
          }
  
          button {
            margin-right: 10px;
          }
        }
      }
    }
  }
  