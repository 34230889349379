.create-account-container-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  padding: 50px;
}

.create-account-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: max-content;
  width: 39%;
  padding: 43px;
}

.language-container {
  width: 61%;
  display: flex;
  justify-content: end;
}

#video-create-account {
  position: fixed;
  z-index: -1;
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .create-account-container-wrapper {
    background-size: 100% auto;
    flex-direction: column;
    .language-container {
      position: absolute;
      right: 0;
      padding-right: 50px;
    }
  }

  .create-account-container {
    width: 100%;
    position: relative;
    top: 25%;
  }
}

@media only screen and (max-width: 550px) {
  .create-account-container-wrapper {
    width: 100%;
    padding: 20px;
    .language-container {
      padding-right: 20px;
    }
  }

  .create-account-container {
    padding: 20px;

    .ds-headline {
      font-size: 25px;
    }
  }
}
