.costs-container {
  // padding: 30px;

  .costs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    .costs-title {
      font-size: 24px;
      font-weight: 700;
    }
    .costs-header-buttons {
      display: flex;
      gap: 20px;
      .calendar-button {
        white-space: nowrap;
        color: white !important;
        font-weight: 700 !important;
        font-family: "Poppins", sans-serif !important;
        height: 50px;
        padding-left: 20px;
        padding-right: 20px;

        .plus-icon-button {
          padding-left: 15px;
          color: white;
          opacity: 1 !important;
        }
      }
    }
  }
  .costs-filters-row {
    .filters-row-header {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      font-size: 13px;
      color: #b3b3b3;
      font-weight: 700;
      font-family: "Poppins";
      margin-bottom: 15px;
      .filter-department {
        width: 12%;
        min-width: 160px;
      }
      .filter-subdepartment {
        width: 12%;
        min-width: 160px;
      }
      .filter-amount {
        width: 18%;
        min-width: 200px;
      }
      .filter-period-of-time {
        width: 22%;
        min-width: 300px;
      }
      .filter-labels {
        width: 26%;
        min-width: 300px;
      }
      .reset-filters {
        width: 10%;
      }
    }
    .filters-row-content {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      .filter-label {
        color: #b2b2b2;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
      }
      .filter-department {
        width: 12%;
        min-width: 160px;
      }
      .filter-subdepartment {
        width: 12%;
        min-width: 160px;
      }
      .filter-amount {
        width: 18%;
        min-width: 200px;
        .amount-content {
          display: flex;
          align-items: center;
          input[name="min_amount"],
          input[name="max_amount"] {
            width: 44%;
            height: 50px;
            border: 2px solid #d4d4d4;
            border-radius: 3px;
            font-size: 14px;
            color: #666666;
            font-weight: 700;
            font-family: "Poppins";
            padding-left: 15px;
          }
          .amount-separator {
            color: #666666;
            margin: 0 1px;
            font-size: 14px;
            font-weight: 700;
            font-family: "Poppins";
          }
        }
      }
      .filter-period-of-time {
        width: 22%;
        min-width: 300px;
        .time-filters {
          display: flex;
          align-items: center;
          .start-time,
          .end-time {
            cursor: pointer;
            height: 50px;
            border: 2px solid #d4d4d4;
            border-radius: 3px;
            background-color: white;
            // padding: 0;
            // font-size: 14px;
            // color: #666666;
            // font-weight: 700;
            // font-family: "Poppins";
            // padding-left: 15px;
          }
          input[name="start_date"],
          input[name="end_date"] {
            cursor: pointer;
            height: 46px;
            border: 2px solid #d4d4d4;
            border-radius: 3px;
            padding: 0;
            font-size: 14px;
            color: #666666;
            font-weight: 700;
            font-family: "Poppins";
            padding-left: 15px;
          }
          .amount-separator {
            color: #666666;
            // margin: 0 3px 0 0;
            font-size: 14px;
            font-weight: 700;
            font-family: "Poppins";
          }
        }
      }
      .filter-labels {
        width: 23%;
        min-width: 300px;
        .filter-list {
          display: flex;
          gap: 5px;
          .filter-button-container {
            max-width: 25%;

            .filter-button {
              white-space: nowrap;
              // color: white !important;
              font-weight: 700 !important;
              font-family: "Poppins", sans-serif !important;
              height: 50px;
              // padding-left: 20px;
              // padding-right: 20px;
              background-color: white;
              // border: 2px solid #d4d4d4;
              margin: 0px !important;
              max-width: 100%;
              padding: 12px;
            }
          }
        }
      }
      .reset-filters {
        width: 13%;
        text-align: right;
        .reset-button {
          font-weight: 700 !important;
          font-family: "Poppins", sans-serif !important;
          height: 50px;
          background-color: #666666;
          border: 2px solid #666666;
          margin: 0px !important;
          color: white;
          padding: 12px;
          .plus-icon-button {
            margin: 0 15px 0 0 !important;
            color: white;
          }
        }
      }
    }
  }
  .second-filters-row {
    margin-top: 10px;
    .filter-btn {
      .reset-button {
        font-weight: 700 !important;
        font-family: "Poppins", sans-serif !important;
        height: 50px;
        background-color: #666666;
        border: 2px solid #666666;
        margin: 0px !important;
        color: white;
        padding: 12px;
        width: 100%;
        .plus-icon-button,
        #plus-icon-button {
          // margin: 0 15px 0 0 !important;
          color: white;
          margin: 0 12px 0 0 !important;
        }
      }

      .apply-button {
        font-weight: 700 !important;
        font-family: "Poppins", sans-serif !important;
        height: 50px;
        background-color: var(--primaryColor);
        border: 2px solid var(--primaryColor);
        margin: 0px !important;
        color: white;
        padding: 12px;
        width: 100%;
        .plus-icon-button,
        #plus-icon-button {
          // margin: 0 15px 0 0 !important;
          color: white;
          margin: 0 12px 0 0 !important;
        }
      }
    }
  }
  .costs-table {
    .costs-table-header {
      display: flex;
      gap: 15px;
      font-size: 13px;
      color: #b3b3b3;
      font-weight: 700;
      font-family: "Poppins";
      margin: 50px 0 15px 0px;
      padding: 0 15px;
      text-align: center;
      .description {
        width: calc(50% - 85px);
        text-align: left;
      }
      .amount {
        width: 10%;
      }
      .currency {
        width: 10%;
      }
      .department {
        width: 10%;
      }
      .subdepartment {
        width: 10%;
      }
      .payment-date {
        width: 10%;
      }
      .cost-options {
        width: 85px;
      }
    }
    .costs-table-content {
      .cost-row-content {
        // display: flex;
        // gap: 15px;
        // padding: 15px;
        .description {
          // width: calc(50% - 90px);
          display: flex;
          gap: 10px;
          align-items: center;
          .player-img {
            .player-avatar {
              width: 50px;
              height: 50px;
            }
          }
          .cost-info {
            .cost-name {
              font-size: 14px;
              color: #666666;
              font-weight: 700;
              font-family: "Poppins";
            }
            .cost-tags {
              display: flex;
              gap: 5px;
              margin-top: 5px;
              flex-wrap: wrap;
              .tag-container {
                min-height: 25px;
                border-radius: 3px;
                width: fit-content;
                padding: 0 5px;
                display: flex;
                align-items: center;
                .tag-text {
                  font-size: 10px;
                  color: #ffffff;
                  font-weight: 600;
                  font-family: "Poppins";
                }
              }
            }
          }
        }
        .amount {
          width: 10%;
        }
        .currency {
          width: 10%;
          .icon {
            height: initial !important;
          }
        }
        .department {
          width: 10%;
        }
        .subdepartment {
          width: 10%;
        }
        .payment-date {
          width: 10%;
        }
        .cost-options {
          width: 90px;
          display: flex;
          gap: 15px;
          align-items: center;
          .edit-cost {
            background-color: #666666;
            width: 35px;
            height: 35px;
            padding: 7px;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            .edit-contract-icon {
              color: white !important;
              margin: 0 !important;
            }
          }

          .delete-cost {
            width: 35px;
            height: 35px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #b2b2b2;
            cursor: pointer;
            .delete-contract-icon {
              color: #fafafa;
              margin: 0 !important;
              height: initial !important;
            }
            .delete-contract-icon:hover {
              color: #d91200;
            }
          }
        }
        .col1 {
          font-size: 14px;
          color: #666666;
          font-weight: 700;
          font-family: "Poppins";

          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .costs-table-total-row {
      background-color: #666666;
      display: flex;
      gap: 15px;
      // justify-content: space-between;
      font-weight: 700;
      height: 55px;
      padding-left: 15px;
      padding-right: 15px;
      align-items: center;
      margin-top: 25px;

      .total-text {
        color: #fff;
        width: calc(50% - 90px);
      }

      .total-amount {
        color: var(--primaryColor);
        width: 10%;
        text-align: center;
      }
      .total-currency {
        width: 10%;
        text-align: center;
      }
      .total-department {
        width: 10%;
      }
      .total-subdepartment {
        width: 10%;
      }
      .total-payment-date {
        width: 10%;
      }
      .total-cost-options {
        width: 90px;
      }
    }
  }
  .no-costs-message {
    text-align: center;
    margin-top: 50px;
    .no-costs-message-text {
      font-size: 15px;
      font-weight: 600;
      padding-top: 5px;
    }
  }
}

.add-cost-container {
  .add-contract-row {
    .add-contract-form-input {
      width: 100%;
    }

    .contract-date-input {
      input[name="contract_signing_date"] {
        cursor: pointer;
        color: #666666 !important;
      }
    }
  }
  .add-label-button {
    background-color: "#d4d4d4";
    width: 47%;
    font-family: "Poppins", sans-serif !important;
    color: #666666 !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 700;
    height: 100%;

    .add-file-button-icon {
      opacity: 1 !important;
      color: #666666;
    }
  }

  .add-label-button:hover,
  .add-label-button:active {
    background-color: "#d4d4d4" !important;
  }

  .additional-label {
    display: flex;
    gap: 10px;
    align-items: center;

    .delete-additionale-label {
      width: 25px;
      cursor: pointer;
    }
    .additional-label-rows {
      width: calc(100% - 35px);
    }
  }
}

@media only screen and (max-width: 768px) {
  .costs-container {
    .costs-filters-row {
      .filters-row-content {
        flex-direction: column;

        .filter-department {
          width: 100%;
        }
        .filter-subdepartment {
          width: 100%;
        }
        .filter-amount {
          width: 100%;
        }
        .filter-period-of-time {
          width: 100%;
        }
        .filter-labels {
          width: 100%;
        }
        .reset-filters {
          width: 100%;
        }
      }
    }
  }
}
