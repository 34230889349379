.match-player-stats-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 70px;
  padding-bottom: 60px;

  .match-player-stats-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 65px;

    .player-stats-title {
      font-size: 20px;
      font-weight: 700;
      color: black;
    }

    .player-stats-header-button {
      background-color: #666666;
      font-family: Poppins, sans-serif !important;
      font-size: 13px !important;
      color: #ffffff !important;
      height: 50px;
    }
  }

  .player-stats-team-menu {
    display: flex;
    align-items: center;
    height: 50px;

    .team-menu-option {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;
      padding-left: 30px;
      padding-right: 30px;
      cursor: pointer;
    }
  }

  .match-player-stats-list {
    background-color: #fafafa;
    padding-top: 50px;

    .player-name {
      width: 30%;
      display: flex;
      align-items: center;

      .player-avatar {
        margin-right: 15px;
      }
    }
    .player-param {
      text-align: center;
    }

    .player-stat-row-header {
      display: flex;
      color: #b3b3b3;
      font-weight: 600;
      font-size: 13px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 15px;
    }

    .player-stat-row {
      height: 80px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 14px;
      color: #666666;
      font-weight: 700;
      display: flex;
      align-items: center;
    }
  }
}

.parameters-list-container {
  .parameters-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    .parameters-list-title {
      font-size: 20px;
      font-weight: 700;
      color: black;
    }
  }

  .parameters-list {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 25px;
    width: 28vw;
    .parameters-list-row {
      height: 50px;
      display: flex;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 14px;
      font-weight: 700;
      color: #666666;

      .param-list-checkbox {
        margin-right: 15px;
      }

      .param-name {
        margin-right: 10px;
      }

      .info-icon {
        color: #666666 !important;
      }
    }
  }

  .parameters-list-text {
    font-size: 14px;
    font-weight: 700;
    color: #b2b2b2;
    padding-left: 15px;
    padding-top: 15px;
    margin-bottom: 15px;
  }

  .parameters-list-button-row {
    margin-bottom: 25px;
    .parameters-list-save-button {
      background-color: var(--primaryColor) !important;
      font-family: Poppins, sans-serif !important;
      font-size: 13px !important;
      color: #fff !important;
      height: 50px;
      width: 100%;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
    }

    .param-list-button-icon {
      opacity: 1 !important;
      color: white;
    }
  }
}

.events-scatter-chart-container {
  padding: 50px;
  svg {
    background: url("../../../../assets/tactic-board/pitch.jpg") no-repeat 13px
      0px !important;
  }
}
