@media (min-width: 1322px) {
  .search-filters-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 25px;

    .searchable-players {
      width: 20%;
      margin-right: 50px;
    }

    .dates-filters {
      width: 40%;
      display: flex;
      align-items: center;
      width: 32%;
      margin-right: 25px;
      gap: 10px;

      .players-date-pickers {
        display: flex;
        position: relative;
      }
    }

    .filter-buttons {
      display: flex;
      width: 48%;
      gap: 25px;
    }
  }
}

@media (max-width: 404px) {
  .sub-header-wrapper {
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media (max-width: 1322px) {
  .search-filters-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 50px;

    .searchable-players {
      width: 100%;
      margin-right: 50px;
    }

    .dates-filters {
      width: 100%;
      display: flex;
      align-items: center;

      margin-right: 25px;
      gap: 10px;

      .players-date-pickers {
        width: 100%;
        display: flex;
        position: relative;
      }
    }

    .filter-buttons {
      display: flex;
      width: 100%;
      gap: 25px;
    }
  }
}
