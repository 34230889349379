.progressBarContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    .progressBar {
        width: 200px;
        height: 16px;
        border-radius: 3px;
    }

    .progressBarLabel {
        display: flex;
        align-items: center;
        font-family: "Poppins";
        font-weight: 700;
        font-size: 13px;
        white-space: nowrap;

        .available {
            color: var(--primaryColor);
        }

        .percentAvailable {
            color: #b2b2b2;
        }
    }
}

.searchDiv {
    margin: 50px 0;
    display: flex;
    gap: 50px;

    .searchVideo {
        width: 540px;
    }

    .selectDiv {
        display: flex;
        align-items: flex-end;
        gap: 25px;

        :global .custom-select-wrapper {
            width: initial;
        }

        .btnDiv {
            display: flex;
            gap: 25px;
            height: fit-content;
        }
    }
}

@media only screen and (max-width: 1490px) {
    .searchDiv {
        gap: 25px;

        .searchVideo {
            width: 340px;
        }
    }
}

@media only screen and (max-width: 1262px) {
    .searchDiv {
        gap: 25px;
        flex-direction: column;

        .searchVideo {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 570px) {
    .progressBarContainer {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        flex-direction: column;

        .progressBar {
            width: 100%;
        }
    }

    .searchDiv {
        gap: 25px;
        flex-direction: column;
        margin: 20px 0;

        .searchVideo {
            width: 100%;
        }

        .selectDiv {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
