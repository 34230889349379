.inputLabel {
  color: #b2b2b2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
}

.inputGroup {
  width: 100%;
}

.tfbInputFileContainer:hover {
  cursor: pointer;
}

.tfbInputFileContainer {
  display: flex;

  min-height: 50px;
  align-items: center;
  background-color: #fafafa;
  justify-content: space-between;
  padding: 12.5px 14px 12.5px 20px;
  gap: 20px;
  width: 100%;
  word-break: break-word;
  border: 2px solid #f2f2f2;
  border-radius: 3px;

  input[class="tfbInputFile"] {
    padding-left: 0px;
    display: none;
  }

  label[class="tfbInputFile"] {
    font-weight: 700;
    font-size: 14px;
    color: #b2b2b2;
    cursor: pointer;
    margin-bottom: 0 !important;
    white-space: break-spaces;
  }
}
