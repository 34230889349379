.site-photos-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 70px;
  padding-bottom: 60px;

  .site-photos-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    .site-photos-title {
      font-weight: 700;
      font-size: 20px;
    }

    .file-add-images-button {
      background-color: var(--primaryColor) !important;
      height: 50px;
      padding-left: 20px;
      padding-right: 20px;
      font-family: "Poppins", sans-serif !important;
      font-size: 13px !important;
      color: white !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
    }

    .file-add-images-icon {
      color: white;
      opacity: 1 !important;
      padding-left: 20px;
    }
  }

  .site-photos-list-container {
    min-height: 590px;
    background-color: #fafafa;
    position: relative;

    .placeholder-photos {
      font-size: 14px;
      font-weight: 600;
      color: #666666;
      width: fit-content;
      left: 0;
      height: fit-content;
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      margin: auto;
    }

    .site-photos-list {
      padding: 50px;
      display: grid;
      justify-content: center;
      grid-gap: 25px;
      grid-template-columns: repeat(auto-fill, 185px);
    }

    .default-photo-add {
      width: 185px;
      height: 200px;
      position: relative;
      input[type="file"] {
        display: none;
      }
      i {
        height: 185px;
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 24px;
        background-color: #f0f0f0;
        border-radius: 3px;
        &:hover {
          color:#3F4254;
          background-color: #c0c0c0;
          cursor: pointer;
        }
      }
    }

    .image-element-container{
      width: 185px;
      height: 200px;
      position: relative;

      .image-container {
        height: 185px;
        width: 100%;
        .photo {
          width: 100%;
          height: 100%;
          object-fit: cover;
          margin-bottom: 5px;
          border-radius: 3px;
        }

        &:hover .expand-button {
          display: block;
        }

        &:hover {
          filter: brightness(60%);
          cursor: pointer;
        }

        .expand-button {
          width: 20px;
          height: 20px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          display: none;

          img {
            height: 20px;
            width: 20px;
            right: 0;
            top: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            margin: auto;
          }
        }
      }

      .image-name {
        font-size: 13px;
        color: #b2b2b2;
        font-weight: 700;
      }

      .delete-button {
        width: 35px;
        height: 35px;
        background-color: white !important;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        bottom: 25px;
        border-radius: 3px;
        cursor: pointer;

        .delete-icon-image {
          height: 12px;
          width: 12px;
        }
      }
    }
  }
}

.photos-delete-popup-container {
  width: 575px;
}

.photos-delete-popup {
  padding-left: 26px;
  padding-right: 26px;
}

.photos-delete-popup {
  .header {
    display: flex;
    justify-content: space-between;
    height: 65px;
    margin-bottom: 30px;

    .header-title {
      font-size: 20px;
      font-weight: 700;
      align-self: flex-end;
    }

    .header-photos-delete-icon {
      font-size: 15px;
      cursor: pointer;
    }
  }

  .delete-message {
    color: #b2b2b2;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 35px;
  }

  .delete-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    .news-options-button {
      background-color: #00d948 !important;
      height: 50px;
      font-family: "Poppins", sans-serif !important;
      font-size: 12px !important;
      color: white !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      width: 47%;

      .news-options-button-icon {
        color: white;
        padding-left: 20px;
        opacity: 1 !important;
      }
    }
    .dark-background {
      background-color: #666666 !important;
    }
  }
}

.file-upload-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;

  .file-upload-submit-button {
    background-color: #00d948 !important;
    height: 50px;
    width: 47%;
    font-family: "Poppins", sans-serif !important;
    font-size: 13px !important;
    color: white !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }

  .cancel-button {
    background-color: #666666 !important;
  }

  .file-upload-button-icon {
    color: white;
    opacity: 1 !important;
  }
}

.image-input-container-photos {
  display: flex;
  min-height: 50px;
  align-items: center;
  background-color: #fafafa;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  width: 100%;
  word-break: break-word;

  #customFile{
    padding-left: 0px;
  }

  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    overflow: visible;
  }

  label {
    font-weight: 700;
    font-size: 14px;
    color: #b2b2b2;
    cursor: pointer;
    margin-bottom: 0 !important;
  }
}

//EXPANDED IMAGE

.expanded-image-container {
  position: absolute;

  width: 100%;
  margin-left: -100px;
}

.dark-background-popup {
  position: fixed;
  top: 0;
  z-index: 3333;
  width: 100%;
  height: 100%;
  margin-left: -125px;
  background-color: rgba(0, 0, 0, 0.7);

  .image-container {
    // width: calc(100vw - 200px);
    width: calc(100% - 350px);
    height: 879px;
    position: absolute;
    left: 0;
    top: 0;
    // right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;

    .expanded-image {
      // max-height: 100%;
      // max-width: 100%;
      max-height: 95%;
      max-width: 95%;
    }

    .close-button {
      width: 50px;
      height: 50px;
      background-color: #ffffff;
      margin-left: 15px;
      cursor: pointer;
      position: relative;
      border-radius: 3px;

      img {
        height: 10px;
        width: 10px;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
      }
    }
  }
}
