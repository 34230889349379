.tfbbutton {
  border-radius: 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  border: 0;
  transition: 0.2s;
  cursor: pointer;

  .tfbbuttonContent {
    display: flex;
    align-items: center;
    gap: 19px;
    justify-content: center;
  }

  .badgeContainer {
    width: 0;
    position: absolute;
    top: -12px;
    right: 18px;

    .badgeCircle {
      background-color: white;
      border-radius: 25px;
      height: 29px;
      width: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: normal;
      box-shadow: 0 0 2px #666666;

      .badgeNumber {
        color: black;
        font-size: 11px;
      }
    }
  }
}

.disabledBtn {
  opacity: 0.45 !important;
  box-shadow: none !important;
  cursor: initial;
}

.sm {
  font-size: 13px;
  padding: 11px;
}
.lg {
  font-size: 14px;
  padding: 14px 20px;
}
.green {
  background: var(--primaryColor);
  color: #ffffff;

  .loader {
    color: #ffffff;
  }

  &:hover {
    box-shadow: 0px 7.5px 15px var(--primaryColor);
  }
}

.darkGray {
  background: #666666;
  color: #ffffff;

  .loader {
    color: #ffffff;
  }

  &:hover {
    box-shadow: 0px 7.5px 15px rgba(102, 102, 102, 0.25);
  }
}
.lightGray {
  background: #d4d4d4;
  color: #666666;

  .loader {
    color: #666666;
  }

  &:hover {
    box-shadow: 0px 7.5px 15px rgba(212, 212, 212, 0.55);
  }
}
.red {
  background: #d91200;
  color: #ffffff;

  .loader {
    color: #ffffff;
  }

  &:hover {
    box-shadow: 0px 7.5px 15px rgba(217, 18, 0, 0.25);
  }
}
.blue {
  background: #00aaff;
  color: #ffffff;

  .loader {
    color: #ffffff;
  }

  &:hover {
    box-shadow: 0px 7.5px 15px rgba(0, 170, 255, 0.25);
  }
}

.white {
  background: #ffffff;
  color: #666666;

  .loader {
    color: #666666;
  }

  &:hover {
    box-shadow: 0px 7.5px 15px rgba(0, 0, 0, 0.25);
  }
}
