.param-column-title {
  padding: 8px;
  font-weight: 700;
}

.param-columns-container {
  margin-left: 8px;
  margin-right: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 220px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 15vw;
}
