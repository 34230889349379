.necessity-reports-list-container {
  padding: 30px;

  .necessity-reports-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    .necessity-reports-title {
      font-size: 24px;
      font-weight: 700;
    }

    .header-buttons {
      .necessity-reports-add-button {
        background-color: var(--primaryColor) !important;
        color: white !important;
        height: 50px;
        font-size: 13px !important;
        display: flex !important;
        font-family: "Poppins", sans-serif !important;
        align-items: center;
        justify-content: space-between;
        min-width: 200px;
      }

      .necessity-reports-add-button .add-necessity-reports-button-icon {
        color: white;
        opacity: 1 !important;
      }
    }
  }

  .necessity-reports-table {
    .necessity-reports-table-header {
      display: flex;
      gap: 15px;
      font-size: 13px;
      color: #b3b3b3;
      font-weight: 700;
      font-family: "Poppins";
      margin: 50px 0 15px 0px;
      padding: 0 15px;
      text-align: center;
      .create-date {
        width: 15%;
      }

      .category {
        width: 15%;
      }

      .created-by {
        width: 15%;
      }
      .number-order {
        width: 5%;
        text-align: center;
      }
      .amount {
        width: 12%;
      }
      .status {
        width: 15%;
      }
      .options {
        width: 8%;
      }
    }
    .necessity-reports-table-content {
      .necessity-reports-row-content {
        display: flex;
        gap: 15px;
        padding: 15px;
        .col1 {
          font-size: 14px;
          color: #666666;
          font-weight: 700;
          font-family: "Poppins";

          display: flex;
          justify-content: center;
          align-items: center;
        }
        .create-date {
          width: 15%;
        }
        .category {
          width: 15%;
        }
        .created-by {
          width: 15%;
          cursor: pointer;
        }
        .number-order {
          width: 5%;

          justify-content: center;
          gap: 10px;
        }
        .amount {
          width: 12%;
        }
        .status {
          width: 15%;
        }
        // .options {
        //   width: 16%;
        // }

        .options-button {
          width: 8%;
          display: flex;
          flex-direction: row;
          gap: 10px;

          .trash-hover:hover {
            background-color: #d91200;
            cursor: pointer;
          }

          .necessity-report-download {
            background-color: #666666;
            width: 35px;
            height: 35px;
            padding: 7px;
            border-radius: 5px;
            cursor: pointer;

            .download-icon {
              color: white;
              position: relative;
              left: 2px;
            }
          }

          .necessity-report-delete {
            background-color: #666666;
            width: 35px;
            height: 35px;
            padding: 7px;
            border-radius: 5px;
            cursor: pointer;

            .delete-icon {
              color: white;
              position: relative;
              left: 2px;
            }
          }

          .necessity-report-edit {
            background-color: #666666;
            width: 35px;
            height: 35px;
            padding: 7px;
            border-radius: 5px;
            cursor: pointer;

            .edit-icon {
              color: white;
              position: relative;
              left: 3px;
            }

            .approve-icon {
              color: white;
              position: relative;
              left: -2px;
            }

            .disapprove-icon {
              color: white;
              position: relative;
              left: 5px;
            }
          }
        }
      }
    }
    .costs-table-total-row {
      background-color: #666666;
      display: flex;
      gap: 15px;
      // justify-content: space-between;
      font-weight: 700;
      height: 55px;
      padding-left: 15px;
      padding-right: 15px;
      align-items: center;
      margin-top: 25px;

      .total-text {
        color: #fff;
        width: calc(50% - 90px);
      }

      .total-amount {
        color: var(--primaryColor);
        width: 10%;
        text-align: center;
      }
      .total-currency {
        width: 10%;
        text-align: center;
      }
      .total-department {
        width: 10%;
      }
      .total-subdepartment {
        width: 10%;
      }
      .total-payment-date {
        width: 10%;
      }
      .total-cost-options {
        width: 90px;
      }
    }
  }
  .no-necessity-reports-message {
    text-align: center;
    margin-top: 50px;
    .no-necessity-reports-message-text {
      font-size: 15px;
      font-weight: 600;
      padding-top: 5px;
    }
  }
}

.approve-necessity-report-container {
  .approve-necessity-report-row {
    background-color: #fafafa;
    .label-approve-reason {
      font-size: 16px;
      color: black;
      font-weight: 700;
      font-family: "Poppins";
      padding: 5px;
      width: 100%;
      margin-bottom: 0px;
      background-color: white;
    }
  }
}

.gap-40 {
  gap: 40px;
}

.status-row {
  min-height: 50px;
  background-color: #fafafa;
  background-color: #fafafa;
  font-size: 14px;
  font-weight: 700;
  color: #666666;
  padding: 10px;
  // letter-spacing: 0.5px;

  .status-justification {
    color: #b3b3b3;
  }

  .status-icon {
    color: white;
    // background: red;
    border-radius: 50px;
    padding: 3px 10px;
  }

  .status-row-container{
    min-height: 30px;
    display: flex;
    align-items: center;
  }
  .status-label{
    text-transform: uppercase;
    margin-right: 5px;
  }
}
