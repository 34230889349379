.react-text-autocomplete-input {
  .react-autocomplete-input {
    width: calc(100% - 48px);
    left: initial !important;
    top: initial !important;
  }
}

.add-player-form {
  .add-player-row {
    .MuiInputBase-input.MuiInput-input {
      height: auto;
    }
  }
}

.view-evaluation-charts {
  .MuiDialog-paperScrollPaper {
    width: 80% !important;
  }
}

.add-player-professional-license {
  padding-top: 15px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  color: #666666;
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: var(--primaryColor) !important;
  }
}

.MuiDialog-container.MuiDialog-scrollPaper {
  .add-player-row {
    .add-player-row-input-half-width {
      width: 50%;
    }
    input {
      // background-color: #fafafa;
      height: 50px;
      border: 0;
      font-size: 14px !important;
      font-weight: 700;
      color: #666666 !important;
      padding-left: 20px;
      width: 100%;
      border-radius: 3px;
    }

    ul {
      // width: 100%;
      // left: 0 !important;
      // top: 50px !important;
      // background-color: #fafafa !important;
    }
  }
}

@media (min-width: 1120px) {
  .add-player-row {
    .add-player-add-frf-list {
      width: 50% !important;
    }
    .add-player-second-nationality-dropdown {
      width: 50% !important;
    }
  }

  .add-player-shirt-number-warning {
    padding-top: 15px;
    padding-left: 13px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    color: #666666;
  }
}

@media (max-width: 1520px) {
  .row {
    flex-direction: column;
  }

  .col-xxl-8,
  .col-xxl-4 {
    max-width: 100% !important;
  }
}

@media (max-width: 991px) {
  .add-player-form {
    .add-player-header {
      padding-top: 0px !important;
      padding-bottom: 15px !important;
    }

    .add-player-buttons {
      .add-button {
        width: 100%;
      }
    }
  }

  .club-players-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .club-players-container .club-players-content {
    display: flex;
    flex-direction: row;
  }

  .club-players-table-row {
    display: flex;
    flex-direction: column;
    width: 50%;

    .nationalities {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .club-players-container .club-players-table-header {
    display: flex !important;
    flex-direction: column;
  }

  .club-players-table > .club-players-table-header {
    display: none !important;
  }

  .club-players-container .club-players-table-row {
    height: 100% !important;
  }

  .club-players-content .club-players-table-row .player-column-two-rows > div:nth-child(2) {
    display: none;
  }

  .club-players-content .club-players-table-header .player-column-two-rows > div > div:nth-child(2) {
    display: none;
  }

  .club-players-container {
    border-top: 1px solid #d2d2d2;
    // border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    // margin-bottom: 20px;

    .club-players-table-row,
    .club-players-table-header {
      padding-left: 0px !important;
      padding: 15px 5px 5px 10px !important;
      .player-name {
        width: 100% !important;
        height: 60px;
      }

      .player-column,
      .player-nationality {
        width: 100% !important;
        display: flex;
        justify-content: flex-start;
      }

      .player-options {
        width: 100%;
        margin-right: 20px;
        height: 31px;
        margin-bottom: 15px;
        margin-top: 15px;
      }

      .player-column-two-rows {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
      }

      .player-column-two-rows > div > div:first-child {
        text-align: left !important;
      }
    }
  }

  .club-players-table {
    .club-players-table-header {
      justify-content: space-between;
      width: 40%;
    }

    .club-players-table-row {
      justify-content: space-between;
      width: 60%;
    }

    .player-minutes-played,
    .player-instat-index,
    .player-market-value,
    .player-vfm,
    .player-pta {
      display: none !important;
    }
  }

  .team-page-header {
    .team-page-header-right {
      height: 100% !important;
      flex-direction: column-reverse;
      width: 100% !important;
      padding: 0 10px;
      .add-player-button,
      .download-list-button {
        width: 100%;
        margin-bottom: 10px;
        margin-left: 0px !important;
        margin-right: 0px !important;
      }

      .players-season-dropdown {
        width: 100%;
        margin-left: 0 !important;
        margin-bottom: 10px;
      }
    }
  }

  .edit-team-name-popup {
    .edit-name-popup-header {
      margin-bottom: 15px !important;
    }

    .edit-team-buttons {
      .edit-team-button {
        width: 100% !important;
      }
    }
  }
}

@media (max-width: 690px) {
  .team-page-header {
    flex-direction: column;
    .team-page-header-right {
      .players-season-dropdown {
        margin-right: 10px;
      }
    }
  }

  .team-page-header .team-page-title {
    padding: 10px;
  }
}

.club-players-table .club-players-container .club-players-table-header {
  display: none;
}

.placeholder-text {
  font-size: 16px;
  margin-bottom: 20px;
}

.test-title {
  padding-left: 20px !important;
  font-size: 20px;
}

.reports-header {
  display: flex;
  justify-content: flex-end;
}

.table-header-players {
  font-size: 14px;
}

.players-table-name-image {
  display: flex;
  align-items: center;
}

.profile-name {
  text-align: center;
  font-size: 700;
  font-family: "Poppins", sans-serif !important;
  font-size: 19px;
  margin-bottom: 5px;
}

.profile-position {
  text-align: center;
  margin-bottom: 10px;
}

.basic-info-row {
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 1.5vw;
  font-family: "Poppins", sans-serif !important;

  .basic-info-value {
    padding-left: 5px;
    font-weight: 600;
    color: #666666;
  }

  .basic-info-title {
    color: #b2b2b2;
    font-weight: 600;
  }
}

//PLAYER REPORTS PROFILE

.evolution-chart-title {
  width: 100%;
  margin-left: 30px;
}

.test-dropdown-list {
  background-color: #666666 !important;
}

.test-dropdown-item {
  color: white !important;
  border: none;
}

.test-results-chart-menu {
  margin-top: 30px;
  margin-bottom: 0 !important;
}

.add-param-container {
  display: grid;
  padding-left: 30px;
}

.add-param-form-element {
  margin-top: 10px;
}

.add-param-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

// ADD PLAYER FORM >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.add-player-form {
  min-width: 400px;
  padding-left: 26px;
  padding-right: 26px;

  .add-player-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 30px;

    .add-player-title {
      font-size: 20px;
      line-height: 31.75px;
      font-weight: bold;
    }
  }

  .add-player-row {
    height: 50px;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    position: relative;

    .add-player-input {
      background-color: #fafafa;
      height: 100%;
      border: 0;
      font-size: 14px !important;
      font-weight: 700;
      color: #b2b2b2 !important;
      padding-left: 20px;
      width: 100%;
      border-radius: 3px;
    }

    .add-player-dropdown {
      background-color: #fafafa !important;
      border: 0 !important;
      display: flex !important;
      align-items: center;
      padding-left: 20px !important;
      font-weight: 700;
      height: 50px;
      width: 100%;

      div {
        color: #b2b2b2 !important;
        font-size: 14px !important;
      }

      input {
        display: flex;
        height: 50px;
        align-items: center;
        padding-left: 20px;
        color: #b2b2b2 !important;
        font-size: 14px !important;
      }

      .dropdown.icon {
        top: 17px !important;
      }
    }

    .add-player-date-input {
      // width: 100%;
      width: 50%;
      height: 50px;
      background-color: #fafafa;

      div {
        color: #b2b2b2;
        font-weight: 700;
        padding-left: 20px;
        height: 100%;
      }
      ::before {
        border-bottom: 0 !important;
      }

      ::after {
        border-bottom: 0;
      }

      fieldset {
        border-color: #fafafa;
      }
    }

    .calendar-icon {
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.add-player-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  height: 50px;

  .cancel-button {
    background-color: #d4d4d4 !important;
    color: #666666 !important;
    height: 100%;
  }

  .add-button {
    background-color: var(--primaryColor) !important;
    color: white !important;
    height: 100%;
  }
}

.no-access-placeholder {
  text-align: center;
  padding: 15px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
  font-weight: 700;
  .no-access-text {
    font-size: 17px;
    padding-top: 10px;
  }
}

/// =========================================

.team-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  .team-page-title {
    font-weight: 700;
    color: black;
    font-size: 20px;
    display: flex;
    align-items: center;

    .edit-team-name-icon {
      // color: black;
      opacity: 1 !important;
      margin-left: 15px;
      cursor: pointer;
    }
  }

  .team-page-header-right {
    display: flex;
    align-items: center;
    height: 50px;

    .add-player-button {
      background-color: var(--primaryColor) !important;
      color: white !important;
      font-family: "Poppins", sans-serif !important;
      font-weight: 700;
      height: 50px;
    }

    .team-performance-button {
      background-color: #666666 !important;
      color: white !important;
      font-family: "Poppins", sans-serif !important;
      font-weight: 700;
      margin-left: 10px !important;
      height: 50px;
    }
  }
}

.placeholder-icon-team-page {
  color: #666666;
}

// #kt_body
//   > div.MuiDialog-root
//   > div.MuiDialog-container.MuiDialog-scrollPaper
//   > div
//   > div
//   > div
//   > div
//   > div
//   > i {
//   margin: 0 !important;
// }

.club-players-table {
  .club-players-table-header {
    font-size: 13px;
    font-weight: 700;
    color: #b2b2b2;
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;

    .header-stat {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 13px;
      color: var(--primaryColor);

      .stat-value {
        margin-left: 5px;
      }

      .eur-icon-header {
        color: var(--primaryColor);
        opacity: 1 !important;
      }
    }
  }

  .club-players-table-row {
    display: flex;
    align-items: center;
    height: 80px;
    font-weight: 700;
    font-size: 14px;
    color: #666666;
    padding-left: 20px;
    padding-right: 20px;
  }

  .player-name {
    width: 20%;
    display: flex;
    align-items: center;

    .player-avatar {
      margin-right: 15px;
    }
  }

  .player-column {
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .player-minutes-played {
    width: 13%;
  }

  .player-column-two-rows {
    width: 8%;
    text-align: center;
  }

  .player-market-value {
    width: 14%;
  }

  .nationalities {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .player-nationality {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .player-options {
    width: 11%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;

    .eye-icon-container,
    .edit-icon-container {
      background-color: #666666;
      width: 35px;
      height: 35px;
      padding: 7px;
      border-radius: 5px;
      cursor: pointer;
    }

    .pencil-icon-container {
      cursor: pointer;
    }
  }
}

.edit-team-name-popup {
  // width: 25vw;
  width: 100%;
  .add-instat-id-row {
    margin-bottom: 25px;
  }
  .edit-name-popup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    .edit-name-popup-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .edit-team-name-input {
    background-color: #fafafa;
    height: 50px;
    border: 0;
    font-size: 14px !important;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding-left: 20px;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 20px;
  }
  .edit-team-buttons {
    margin-bottom: 25px;
    height: 50px;
    display: flex;
    justify-content: space-between;

    .edit-team-button {
      background-color: var(--primaryColor);
      font-family: "Poppins", sans-serif !important;
      color: white !important;
      height: 100%;
      width: 47%;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      font-weight: 700;
    }

    .edit-team-button-icon {
      color: white;
      opacity: 1 !important;
    }
  }
}

.players-season-dropdown {
  background-color: #666666 !important;
  display: flex !important;
  align-items: center;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
  height: 50px;
  min-width: 200px !important;
  margin-left: 10px;

  .divider.default.text {
    color: white;
  }

  i {
    top: 36% !important;
    color: white;
  }

  .divider.text {
    color: white;
  }
}

.MuiDialog-container.MuiDialog-scrollPaper {
  // width: 100%;

  input {
    // background-color: #fafafa;
    // height: 100%;
    // width: 100%;
    // height: 50px;
    border: 0;
    font-size: 14px !important;
    font-weight: 700;
    // color: #b2b2b2 !important;
    padding-left: 20px;
    // width: 100%;
    border-radius: 3px;
  }

  ul {
    // width: 100%;
    // left: 0 !important;
    // top: 50px !important;
    // background-color: #fafafa !important;
  }
}

#kt_body
  > div.MuiDialog-root
  > div.MuiDialog-container.MuiDialog-scrollPaper
  > div
  > div
  > div
  > div:nth-child(2)
  > span {
  width: 100%;
  height: inherit;

  input {
    background-color: #fafafa;
    height: 100%;
    border: 0;
    font-size: 14px !important;
    font-weight: 700;
    color: #b2b2b2 !important;
    padding-left: 20px;
    width: 100%;
    border-radius: 3px;
  }

  ul {
    // width: 100%;
    // left: 0 !important;
    // top: 50px !important;
    // background-color: #fafafa !important;
  }
}

.access-error-team-page {
  margin-top: 50px;
  font-size: 17px;
  font-weight: 700;
  color: #666666;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .access-error-icon {
    color: #666666;
    margin-bottom: 15px;
    font-size: 30px !important;
  }
}

.delete-team-popup-text {
  font-weight: 600;
  color: black;
  font-size: 14px;
  text-align: center;
}

.rm-border-radius {
  .col-lg-8 {
    box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  }
  .card.card-custom {
    &.bottom-radius {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      box-shadow: none;
    }
    &.top-radius {
      box-shadow: none;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.opacity-0-4 {
  filter: opacity(0.3);
}

.grayscale {
  filter: grayscale(1);
}

// ==============================
/// AFTER DESIGN SYSTEM IMPLEMENT

@media (min-width: 904px) {
  .add-player-form-data {
    display: flex;
    flex-direction: column;
    gap: 25px;

    .add-player-row-ds {
      display: flex;
      position: relative;
      width: 100%;
      justify-content: space-between;
      gap: 5px;
      align-items: center;

      .add-player-phone-input {
        .PhoneInputInput {
          height: 50px !important;
        }
      }

      .react-text-autocomplete-input {
        display: flex;
        flex-direction: column;
        width: 100%;

        .player-name {
          color: "#b2b2b2";
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
        }
      }

      .main-foot-select,
      .nationality-dropdown,
      .birth-date-datepicker {
        width: 50%;
      }

      .nationality-dropdown {
        .flag {
          margin-right: 10px !important;
        }
      }
    }
  }
}

@media (max-width: 904px) {
  .add-player-form-data {
    display: flex;
    flex-direction: column;
    gap: 25px;

    .add-player-row-ds {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      justify-content: space-between;
      gap: 25px;
      align-items: center;

      .react-text-autocomplete-input {
        display: flex;
        flex-direction: column;
        width: 100%;

        .player-name {
          color: "#b2b2b2";
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
        }
      }

      .main-foot-select,
      .nationality-dropdown,
      .birth-date-datepicker {
        width: 100%;
      }

      .nationality-dropdown {
        .flag {
          margin-right: 10px !important;
        }
      }
    }
  }
}
// }
