.table {
    border-collapse: separate;
    border-spacing: 0 10px;

    .totalRow {
        background-color: #666666 !important;
        color: white !important;

        .tableCellTotal {
            background-color: #666666 !important;
            color: white !important;
        }

        .tableCellTotalDuration {
            color: var(--primaryColor) !important;
        }
    }
}
