.container {
    height: 100px;
    width: 100px;
    background-color: var(--primaryColor);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;

    .hovered {
        position: absolute;
        height: inherit;
        width: inherit;
        background-color: inherit;
        background-image: inherit;
        border-radius: inherit;
    }

    .loading {
        position: absolute;
        height: inherit;
        width: inherit;
        background-color: inherit;
        background-image: inherit;
        border-radius: inherit;
        filter: brightness(0.5);
    }

    .changeImg {
        width: 20px;
        height: 20px;
        display: none;
        cursor: pointer;
    }
}

.container:hover {
    .hovered {
        filter: brightness(0.5);
    }

    .changeImg {
        display: block;
    }
}
