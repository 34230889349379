.filtersContainer {
    margin-bottom: 30px;
    .showFilters {
        display: flex;
        gap: 10px;
    }

    .filtersList {
        margin-top: 30px;

        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
