.datatable-header {
  display: flex;
  width: 100%;
  padding: 0 15px;

  .dt-header-item {
    color: #b3b3b3;
    font-weight: 600;
    font-size: 13px;
    padding-bottom: 15px;

    &:not(:first-child) {
      text-align: center;
    }
  }
}

.datatable-rows .data-row {
  display: flex;
  width: 100%;
  height: 55px;
  align-items: center;
  /* Fonts */
  font-size: 14px;
  color: #666666;
  font-weight: 700;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;

  &:nth-child(odd) {
    background: #fafafa;
  }

  &:nth-child(even) {
    background: white;
  }

  .data-row-item {
    &:not(:first-child) {
      text-align: center;
    }
    &:last-child {
      text-align: right;
    }
    .action-group {
      justify-content: flex-end;
    }
  }
}

.datatable-pagination {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;

  .dropdown-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .dropdown-label {
      margin-right: 20px;
      font-weight: 600;
      font-size: 14px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .desktop-header {
    display: flex !important;
  }

  .header-row {
    display: none;
  }

  .content-row {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 991px) {
  .desktop-header {
    display: none !important;
  }

  .data-row {
    height: 330px !important;
    padding: 15px;
    flex-direction: row;
  }

  .header-row {
    display: block;
    width: 50%;
    height: 100%;
  }

  .mobile-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    div {
      width: 100% !important;
      height: 37px !important;
      display: flex;
      justify-content: flex-start;
    }
  }

  .content-row {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    div:not(div:last-child, .weight-control-container div) {
      width: 100% !important;
      // height: 37px !important;
      padding-bottom: 15px;
      display: flex;
      justify-content: flex-start;
    }

    .data-row-item.controls {
      width: 120px !important;
      height: 35px !important;

      .action-button {
        width: 35px !important;
        height: 35px !important;
        padding-bottom: 7px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          margin: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .container-fluid {
    padding: 0 !important;
  }

  .campaigns-wrapper {
    padding: 20px;
  }
}
