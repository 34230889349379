.add-notes-popup-content {
  .notes-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // margin-bottom: 50px;

    .note-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      border: 2px solid #fafafa;
      padding: 25px;

      .creator-details {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;

        .user-post-name {
          display: flex;
        }

        .note-container-buttons {
          display: flex;
          align-self: baseline;
          gap: 15px;
        }

        .details {
          display: flex;
          flex-direction: column;

          .ds-main-text-auxiliary {
            color: #b2b2b2;
          }
        }
      }

      .remark-description {
        display: flex;
        overflow-wrap: anywhere;
      }
    }
  }

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 20.5px;

    .MuiFormControl-root {
      .MuiInputBase-root,
      .MuiOutlinedInput-root {
        background-color: #fafafa !important;
        height: 100px !important;
        align-items: baseline;

        textarea {
          font-weight: bold;
        }
      }
    }

    .mark-critic-point {
      display: flex;
      gap: 10px;
    }
  }

  // Stilurile specifice pentru .add-note-form
  .add-note-form {
    @extend .form-container;
    margin-top: 50px;
  }

  // Stilurile specifice pentru .edit-note-form
  .edit-note-form {
    @extend .form-container;
  }

  .tfb-custom-dialog-actions {
    position: sticky;
    bottom: 0;
    z-index: 999;
  }
}
