///REPORT BUBBLE CHART

.bubble-chart {
  padding: 50px;
  .bubble-chart-title {
    font-weight: 700;
    font-size: 20px;
  }
}

.popup-bubble-chart {
  padding: 10px;
  display: flex;

  .popup-player-name {
    color: #666666 !important;
    font-weight: 800;
    padding-right: 2px;
  }

  .popup-player-grade {
    padding-left: 2px;
    color: var(--primaryColor) !important;
    font-weight: 800;
  }
}

// Technical test parameters list

.grades-list {
  max-height: 300px;
  overflow-y: auto;
  margin-top: 15px;

  .grade-row {
    display: flex;
    height: 40px;
  }

  .param-name {
    font-weight: 700;
    width: 60%;
  }

  .param-foot {
    width: 20%;
    text-align: center;
  }

  .param-grade {
    width: 20%;
    text-align: center;
    font-weight: 700;
    color: var(--primaryColor);
  }
}
