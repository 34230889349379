.online-payments-container {
  padding: 50px;

  .online-payments-title {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 50px;
  }

  .online-payment-setting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .setting-name {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.payment-settings-container {
  display: flex;
  flex-direction: column;
  .payment-setting-switch {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    i {
      font-family: "Poppins" !important;
      font-weight: 700 !important;
      font-size: 14px !important;
    }
  }
}
