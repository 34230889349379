.exportPlayersPopupContent {
  display: flex;
  flex-direction: column;

  .subheaderSpan {
    font-family: "Poppins";
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 40px;
    margin-top: 26px;
  }

  .typesList {
    display: flex;
    gap: 20px;
    flex-flow: wrap;
    margin-bottom: 34px;

    &:first-child {
      label {
        color: #00d948 !important;
      }
    }
  }

  .newDataTypeDiv {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    width: 50%;
  }
}
