.check-code-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;

  label {
    font-weight: bold;
    margin-bottom: 8px;
  }

  .input-container {
    position: relative;
    display: flex;
    gap: 8px;

    input,
    .input-digit {
      width: 46px;
      height: 46px;
      font-size: 18px;
      text-align: center;
      border: 1px solid #f2f2f2;
      border-radius: 4px;
      outline: none;
      background-color: #fafafa;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .input-digit-selected {
      border-color: var(--primaryColor);
      border-width: 3px;
      position: absolute;
    }
  }
}

@media only screen and (max-width: 397px) {
  .check-code-container {
    .input-container {
      input {
        width: 40px;
        height: 40px;
      }
    }
  }
}
