.online-payments-list-container {
    padding: 30px;

    .online-payments-header {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        .online-payments-title {
            font-size: 24px;
            font-weight: 700;
        }
    }

    .online-payments-table {
        .online-payments-table-header {
            display: flex;
            gap: 15px;
            font-size: 13px;
            color: #b3b3b3;
            font-weight: 700;
            font-family: "Poppins";
            margin: 50px 0 15px 0px;
            padding: 0 15px;
            text-align: center;
            .customer {
                width: 20%;
            }
            .team {
                width: 20%;
            }
            .player {
                width: 20%;
                text-align: left;
            }
            .amount {
                width: 10%;
            }
            .payment-type {
                width: 10%;
            }
            .payment-month-year {
                width: 10%;
            }
            .payment-date {
                width: 10%;
            }
        }
        .online-payments-table-content {
            .online-payments-row-content {
                display: flex;
                gap: 15px;
                padding: 15px;
                .col1 {
                    font-size: 14px;
                    color: #666666;
                    font-weight: 700;
                    font-family: "Poppins";

                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .customer {
                    width: 20%;
                }
                .team {
                    width: 20%;
                    cursor: pointer;
                    text-align: center;
                }
                .player {
                    width: 20%;
                    // flex-direction: column;
                    justify-content: start;
                    gap: 10px;
                    // .player-img {
                    //     .player-avatar {
                    //         cursor: pointer;
                    //     }
                    // }
                    // .player-name-text {
                    //     cursor: pointer;
                    // }
                }
                .amount {
                    width: 10%;
                }
                .payment-type {
                    width: 10%;
                }
                .payment-month-year {
                    width: 10%;
                }
                .payment-date {
                    width: 10%;
                }
            }
        }
        .costs-table-total-row {
            background-color: #666666;
            display: flex;
            gap: 15px;
            // justify-content: space-between;
            font-weight: 700;
            height: 55px;
            padding-left: 15px;
            padding-right: 15px;
            align-items: center;
            margin-top: 25px;

            .total-text {
                color: #fff;
                width: calc(50% - 90px);
            }

            .total-amount {
                color: var(--primaryColor);
                width: 10%;
                text-align: center;
            }
            .total-currency {
                width: 10%;
                text-align: center;
            }
            .total-department {
                width: 10%;
            }
            .total-subdepartment {
                width: 10%;
            }
            .total-payment-date {
                width: 10%;
            }
            .total-cost-options {
                width: 90px;
            }
        }
    }
    .no-online-payments-message {
        text-align: center;
        margin-top: 50px;
        .no-online-payments-message-text {
            font-size: 15px;
            font-weight: 600;
            padding-top: 5px;
        }
    }
}
