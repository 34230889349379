.add_competition_manual_options {
  height: 50px;
  display: flex;

  .add_competition_option {
    width: 50%;
    justify-content: center;
    align-items: center;
    display: flex;

    div {
      font-size: 14px;
      font-weight: 700;
      color: #666;
      cursor: pointer;
    }
  }
  .add_competition_option:hover {
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  .add_competition_manual_options {
    height: 40px;
    max-width: 300px;
  }
}
